import React from 'react';
import {
  logLinkClick,
  webSectionName,
} from '../../../../shared/models/Analytics';
import styles from './EventDetail.module.css';

const WebLinkHandler = ({link, i, analyticsBase}) => {
  const handleClick = () => {
    let analyticsObj = {
      ...analyticsBase,
      click_text: link.text,
      link_type: link.url.toLowerCase().includes('teamrwb.org')
        ? 'internal'
        : 'external',
      section_name: webSectionName(),
    };
    logLinkClick(analyticsObj);
  };

  return (
    <div className={styles.eventLinks} key={'linkContainer' + i}>
      <a
        onClick={handleClick}
        rel="noopener noreferrer"
        target="_blank"
        href={link.url}
        className="link"
        key={'link' + i}>
        {link.text}
      </a>
    </div>
  );
};

export default WebLinkHandler;
