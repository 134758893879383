import React, {Component} from 'react';
import TextInput from '../TextInput';
import RWBButton from '../RWBButton';
import styles from './Login.module.css';
import {TEAMRWB} from '../../../../shared/constants/URLs';

// SVGs
import RWBLogoVertical from '../svgs/RWBLogoVertical';

import {isNullOrEmpty, validateEmail} from '../../../../shared/utils/Helpers';
import {rwbApi} from '../../../../shared/apis/api';
import Loading from '../Loading';
import {logLogin} from '../../../../shared/models/Analytics';

import {INVALID_LOGIN_ERROR} from '../../../../shared/constants/ErrorMessages';
import ApiInfo from '../ApiInfo';

export default class Login extends Component {
  state = {
    value: 0,
    email: '',
    password: '',
    email_error_text: null,
    password_error_text: null,
    isLoading: false,
  };

  valueChangeHandler = (event, field) => {
    this.setState({[field]: event.target.value});
  };

  clearErrors() {
    this.setState({
      email_error_text: '',
      password_error_text: '',
    });
  }

  loginHandler = () => {
    let {email, password} = this.state;
    const {history} = this.props;

    this.clearErrors();
    let hasError = false;

    if (isNullOrEmpty(email) || !validateEmail(email)) {
      this.setState({email_error_text: 'PLEASE ENTER A VALID EMAIL'});
      hasError = true;
    }
    if (!password || password === null || password === '') {
      this.setState({password_error_text: 'ENTER YOUR PASSWORD'});
      hasError = true;
    }

    if (hasError) {
      return;
    }

    this.setState({
      email: email.trim(),
      isLoading: true,
    });
    rwbApi
      .loginUser(email, password)
      .then((json) => {
        this.setState({isLoading: false});
        const {email_verified, profile_completed} = json;
        if (!email_verified) {
          history.push(`/registration/confirm_email`);
        } else if (email_verified && !profile_completed) {
          history.push('/registration/personal_info');
        } else {
          let referrer = this.props.location?.state?.referrer;
          // if a user was on the registration flow and then goes back to log in and logs in again, the referrer will be the registration page
          // this should only come up if a user logs into an incomplete account and switches to a complete account
          if (referrer && !referrer.includes('/registration/personal_info')) {
            referrer = referrer.replace(window.location.origin, '');
            referrer = referrer.replace(window.port, '');
            history.replace(referrer);
          } else history.replace('/feeds');
        }
        logLogin();
      })
      .catch((error) => {
        console.warn(error);
        this.setState({
          password_error_text: INVALID_LOGIN_ERROR.toUpperCase(),
          isLoading: false,
        });
      });
  };

  render() {
    const {
      email,
      password,
      email_error_text,
      password_error_text,
      isLoading,
    } = this.state;
    return (
      <>
        <Loading size={100} color={'var(--white)'} loading={isLoading} />
        <div className="fullScreenContainer">
          {/* Added fullWidth prop to avoid error of Invalid prop `width` of type `string`  */}
          <RWBLogoVertical className={styles.logo} fullWidth height={250} />
          <TextInput
            label={'Email'}
            type={'text'}
            value={email}
            onValueChange={(event) => this.valueChangeHandler(event, 'email')}
            error={email_error_text}
            onEnter={this.loginHandler}
          />
          <TextInput
            label={'Password'}
            type={'password'}
            value={password}
            onValueChange={(event) =>
              this.valueChangeHandler(event, 'password')
            }
            error={password_error_text}
            onEnter={this.loginHandler}
          />

          <p className={styles.forgotPasswordText}>
            Forgot your password?{' '}
            <a className="link" href={TEAMRWB.lostpassword}>
              Click Here
            </a>
          </p>
          <RWBButton
            label="Log In"
            buttonStyle="primary"
            onClick={this.loginHandler}
          />
          <RWBButton
            link={true}
            to="/registration"
            state={{from: 'Login'}}
            label="Create Account"
            buttonStyle="secondary"
          />
          <ApiInfo />
        </div>
      </>
    );
  }
}
