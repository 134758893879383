import React from 'react';
import { IconButton } from '@material-ui/core';
import ChevronDownIcon from '../svgs/ChevronDownIcon';
import styles from './GroupLozenge.module.css';
import {Tune as FilterIcon} from '@material-ui/icons';

const Lozenge = ({ slug, display, onClick, dropDown, filter, filtersCount, selected }) => (
    <IconButton
        className={styles.filterLozenges}
        size={'small'}
        onClick={onClick}>
        <div
            className={[
            styles.singleContainer,
            selected && styles.selected,
            ].join(' ')}>
            {filter && !selected && <FilterIcon />} {filter && selected && <>{filtersCount}</>} {display} {dropDown && <ChevronDownIcon tintColor={'var(--grey80)'} />}
        </div>
    </IconButton>
);
  

export default Lozenge;
