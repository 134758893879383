import React from 'react';
import { useState } from 'react';
import { rwbApi } from '../../../../shared/apis/api';
import styles from './CheckInControlButton.module.css';

const CheckInControlButton = ({ eventId, userId, clickHandler, index, listSection, removeCheckIn }) => {
    const [loading, setLoading] = useState(false);
    const checkInUser = (e) => {
        setLoading(true);
        e.stopPropagation();
        rwbApi.updateUserEventStatus(eventId, userId, 'checked_in').then((result) => {
            clickHandler(index, listSection);
        }).catch((err) => {
            console.warn(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    const removeCheckInUser = (e) => {
        setLoading(true);
        e.stopPropagation();
        rwbApi.updateUserEventStatus(eventId, userId, 'none').then((result) => {
            clickHandler(index, listSection, false);
        }).catch((err) => {
            console.warn(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <p onClick={loading ? undefined : removeCheckIn ? removeCheckInUser : checkInUser} className={styles.checkInButton} style={{opacity: loading ? 0.5 : 1}}>{removeCheckIn ? 'Remove Check-In' : 'Check In'}</p>
    )
}

export default CheckInControlButton;
