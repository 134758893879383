import { IconButton, Paper, Toolbar, makeStyles, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import styles from './ELManagement.module.css';
import CheckIcon from './svgs/CheckIcon';
import XIcon from './svgs/XIcon';
import DropdownInputPicker from './DropdownInputPicker.react.js';
import { months, days } from '../../../shared/constants/MonthsAndDays';
import { displayMilitaryInfo, generateYears } from '../../../shared/utils/Helpers';
import { useState } from 'react';
import { EL_ERRORS, EL_LABELS, EL_ROLES, getOffboardingMessage, offboardMessage, onboardMessage } from '../../../shared/constants/EagleLeaderManagementStrings';
import DefaultProfileImg from '../../../shared/images/DefaultProfileImg.png';
import RWBMark from './svgs/RWBMark';
import moment from 'moment';
import { rwbApi } from '../../../shared/apis/api';
import EagleLeaderInfo from '../../../shared/models/EagleLeaderInfo';

const years = generateYears();

const ELManagementModal = ({
    closeModalHandler,
    groupName,
    title,
    user,
    editableEL,
    handleUpdatedELInfo,
}) => {
    // TODO implement values from user
    const [role, setRole] = useState(null);
    const [month, setMonth] = useState(null);
    const [day, setDay] = useState(null);
    const [year, setYear] = useState(null);
    const [dateError, setDateError] = useState(null);
    const TITLES = {
        ONBOARD: 'Onboard Eagle Leader',
        OFFBOARD: 'Offboard Eagle Leader',
        LEADER: 'Eagle Leader',
        SAVE_CHANGES: 'Save Changes',
      };
    let managementText;

    // handleSubmit sets user.eagle_leader_info but then closes modal right
    // away, so no need to assign to a state-variable.
    let eagleLeaderInfo = new EagleLeaderInfo(user.eagle_leader_info);

    const validateData = () => {
        const inputDate = moment(`${year}-${month}-${day}`, 'YYYY-MMMM-DD');
        const today = new moment();
        if (inputDate.isAfter(today)) {
            setDateError(title === TITLES.ONBOARD ? EL_ERRORS.futureStartDate : EL_ERRORS.futureEndDate);
            return false;
        }
        return true;
    };

    const isButtonDisabled = () => {
        if (title === TITLES.ONBOARD) {
          return month === null || day === null || year === null || role === null;
        } else if (title === TITLES.LEADER) {
          return role === null;
        } else if (title === TITLES.OFFBOARD) {
          return month === null || day === null || year === null;
        }
        return false;
      };

    // TODO put this for onboard and offboard with appropriate validation
    // TODO maybe move this into own component to be used for registration
    // NOTE: registration does not have the error handling design has, currently not implemented
    const dateDropdowns = () => {
        return (
            <div>
                {/* TODO have way to differentiate if start/end, most likely change title  */}
                <p className="formLabel" style={{ color: dateError ? "var(--magenta)" : "#63666A"}}>{title === TITLES.ONBOARD ? EL_LABELS.startDate : EL_LABELS.endDate}</p>
                <div style={{display: 'flex', marginBottom: '10', justifyContent: 'space-between'}}>
                <div style={{width: '30%'}}>
                    <DropdownInputPicker storeValue={setMonth} options={months} placeholder={'Month'} fullWidth={false} error={dateError}/>
                </div>
                <div style={{width: '30%'}}>
                    <DropdownInputPicker label="" storeValue={setDay} options={days.slice(1, days.length)} placeholder={'Day'} fullWidth={false} error={dateError}/>
                </div>
                <div style={{width: '30%'}}>
                    <DropdownInputPicker label="" storeValue={setYear} options={years} placeholder={'Year'} fullWidth={false} error={dateError}/>
                </div>
                </div>
                {dateError && <p className={`formLabel`} style={{ color: 'var(--magenta)'}} >{dateError}</p>}
            </div>
        )
    }

    const StatusCard = (startDate, status, offboardingInfo, eagleLeaderRole) => {
        return (
        <div className={styles.statusCard}>
            <div className={styles.statusSection}>
                <p className="formLabel">{EL_LABELS.startDate}</p>
                <p className={styles.statusValue}>{startDate}</p>
            </div>
            <div className={styles.statusSection}>
                <p className="formLabel">{EL_LABELS.onboardingStatus}</p>
                <p className={`${styles.statusValue} ${status ? styles.completed : styles.pending}`}>
                    {status
                        ? `Completed ${eagleLeaderInfo.trainingCompletionDateDisplay()}`
                        : `Pending`}
                </p>
            </div>
            {eagleLeaderRole && !editableEL ? (
            <div className={styles.statusSection}>
                <p className="formLabel">{EL_LABELS.role}</p>
                <p className={styles.statusValue}>{eagleLeaderInfo.role}</p>
            </div>
            ) : null}
            {offboardingInfo && (
                <div className={styles.statusSection}>
                    <p className="formLabel">{EL_LABELS.offboardingNotification}</p>
                    <p className={styles.alertTitle} style={{ color: offboardingInfo.alert === 'Warning - 45 Day External' ? 'var(--magenta)' : 'var(--orange)' }}>{offboardingInfo.alert}</p>
                    <p>{offboardingInfo.message}</p>
                </div>
            )}
        </div>
        );
      }

    const roleDropdown = () => {
        return (
            <div>
                <p className="formLabel">{'EAGLE LEADER ROLE'}</p>
                <DropdownInputPicker storeValue={setRole} placeholder={eagleLeaderInfo.role ?? 'Select Role'} options={EL_ROLES} fullWidth={true}/>
            </div>
        )
    }

    useEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow;  
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = originalStyle;
    }, []);

    if (title === 'Onboard Eagle Leader') {
        managementText = (<p>{onboardMessage(user)}</p>);
    } 
    else if (title === 'Offboard Eagle Leader') {
        managementText = (<p>{offboardMessage(user)}</p>);
    } 
    else {
        managementText = null;
    }

    const handleSubmit = () => {
        if (title === TITLES.ONBOARD || title === TITLES.OFFBOARD) {
            if (validateData()) {
                const date = moment(`${year}-${month}-${day}`, 'YYYY-MMMM-DD');
                const dateString = date.format('YYYY-MM-DD')
                const data = {
                    role: title === TITLES.ONBOARD ? role : eagleLeaderInfo.role,
                    start_date: title === TITLES.ONBOARD ? dateString : eagleLeaderInfo.startDate,
                    end_date: title === TITLES.OFFBOARD ? dateString : null,
                };
                rwbApi.updateEagleLeaderInfo(user.id, data)
                .then((result) => {
                    user.eagle_leader_info = result;
                    if (handleUpdatedELInfo) handleUpdatedELInfo(user);
                    closeModalHandler();
                })
                .catch((err) => {
                    window.alert('Team RWB', err.message);
                })
            }
        }
        else if (title === TITLES.LEADER) {
            const data = {
                role: role,
                start_date: eagleLeaderInfo.startDate,
                end_date: eagleLeaderInfo.endDate
            };
            rwbApi.updateEagleLeaderInfo(user.id, data)
            .then((result) => {
                user.eagle_leader_info = result;
                if (handleUpdatedELInfo) handleUpdatedELInfo(user);
                closeModalHandler();
            })
            .catch((err) => {
                window.alert('Team RWB', err.message);
            })
        }
    };
    const isDisabled = isButtonDisabled();
    return (
        <div className={styles.container}>
            <Paper className={styles.root}>
                <Toolbar className={styles.toolbar}>
                    <IconButton
                        onClick={closeModalHandler}
                        color="inherit">
                        <XIcon tintColor={'var(--white)'} />
                    </IconButton>
                    <div>
                        <p className={styles.title}>
                            {title}
                        </p>
                        <p className={styles.subTitle}>
                            {groupName}
                        </p>
                    </div>
                </Toolbar>
            </Paper>
        <div className={styles.ELContainer}>
            <div className={styles.profileSection}>
                <img className={styles.profilePicture}   src={!user.profile_photo_url ? DefaultProfileImg : user.profile_photo_url} />
                <div className={styles.labelContainer}>
                    <div className={styles.name}>
                    <h3>
                        {`${user.first_name} ${user.last_name}`}
                    </h3>
                    {eagleLeaderInfo.active && <RWBMark width={40} height={15} />}
                    </div>
                    {eagleLeaderInfo.active ? <h5>Eagle Leader</h5> : null}
                    <p>{user.anchor_chapter?.name} </p>
                    <p>{displayMilitaryInfo(user.military_status, user.military_branch)}</p>
                </div>
            </div>

            <div className={styles.formSection}>
                {managementText}
                {title === TITLES.ONBOARD || title === TITLES.OFFBOARD ? dateDropdowns() : null}
                {/* TODO: 
                    1. Fill in acutal startDate insted of place holder
                    2. Replace onboarding objects completed and date with actual completion status and date
                    3. Replace the number put in getOffboardingMessage with actual day its been since user checked into a event
                */}
                {
                    title === TITLES.LEADER 
                        ? StatusCard(
                            moment(eagleLeaderInfo.startDate).format('MM/DD/YYYY'),
                            eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE,
                            getOffboardingMessage(eagleLeaderInfo.offboardingNotification, user),
                            eagleLeaderInfo.role)
                        : null}
                {title === TITLES.ONBOARD || title === TITLES.LEADER && editableEL ? roleDropdown() : null}
            </div>

            {title !== TITLES.LEADER || editableEL ? (
                <div className={styles.buttonSection}>
                <button 
                    className={styles.submitButton} 
                    onClick={handleSubmit} 
                    disabled={isDisabled} 
                    style={{ 
                    cursor: isDisabled ? 'default' : 'pointer', 
                    opacity: isDisabled ? 0.5 : 1 
                }}>
                    {title !== TITLES.LEADER ? title : TITLES.SAVE_CHANGES}
                </button>   
                </div>
            ) : null}
        </div>
        </div>
    );
}

export default ELManagementModal;
