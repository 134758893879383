import React, { useEffect, useState } from 'react';
import styles from './UsersList.module.css';
import Loading from '../Loading';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import RWBMark from '../svgs/RWBMark';
import { logSearchForPeople } from '../../../../shared/models/Analytics';
import { NO_USERS_FOUND } from '../../../../shared/constants/ErrorMessages';
import {
  displayMilitaryInfo,
  isNullOrEmpty,
} from '../../../../shared/utils/Helpers';
import { FEED_TAGS } from '../../../../shared/constants/Labels';
import CheckInControlButton from '../groups/CheckInControlButton.react';
import ReportAndDeleteOverlay from '../ReportAndDeleteOverlay';
import { userProfile } from '../../../../shared/models/UserProfile';
import FollowButton from '../FollowButton';
import EagleLeaderInfo from '../../../../shared/models/EagleLeaderInfo';

const NON_USER_TAGS = [FEED_TAGS.EVERYONE];
//TODO: might be worth seperating some of this into its own group members searched list or adding serched users to group members list.
const UsersList = ({ usersSuggestions, loadingUsers, onSelect, search, minifiedCard, eventId, onCheckIn, isGroupList, privilegedIds, groupName, isGroupAdmin, handleUpdatedELInfo }) => {
  return(
  <div className={styles.container}>
    {loadingUsers ? (
      <div className={styles.loadingContainer}>
        <Loading size={50} color={'var(--grey40)'} loading={true} transparent />
      </div>
    ) : (
      <>
        {usersSuggestions.length > 0 ? (
          usersSuggestions.map((user, i) => {
            if (NON_USER_TAGS.includes(user._type)) {
              return (
                <div
                  className={styles.suggestionContainer}
                  onClick={() => {
                    onSelect({
                      id: null,
                      name: `${user.name}`,
                    });
                  }}
                  key={i}>
                  <div className={styles.textContainer}>
                    <div className={styles.inlineContainer}>
                      <h2>{`${user.name}`}</h2>
                    </div>
                    <div>
                      <p>Notify everyone in this group</p>
                    </div>
                  </div>
                </div>
              );
            }
            let eagleLeaderInfo = new EagleLeaderInfo(user.eagle_leader_info);
            return (
              <div className={styles.suggestionContainer}>
                <div
                  style={{display: 'flex', flex: 1, alignItems: 'center'}}
                  onClick={() => {
                    logSearchForPeople();
                    user.name = `${user.first_name} ${user.last_name}`;
                    onSelect(user);
                  }}
                  key={i}>
                  <div>
                    <img
                      className={minifiedCard ? 'minifiedImg' : styles.profileImg}
                      src={user?.profile_photo_url || DefaultProfileImg}
                      alt={'User Profile'}
                    />
                  </div>
                  <div className={styles.textContainer}>
                    <div className={styles.inlineContainer}>
                      <h3>{`${user?.first_name} ${user?.last_name}`}</h3>
                      {user?.eagle_leader && <RWBMark width={40} height={15} />}
                    </div>
                    {user?.eagle_leader && !isGroupList && (
                      <h5 className={styles.eagleLeaderText}>{'Eagle Leader'}</h5>
                    )}
                    {isGroupList && eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE && (
                      <h5 className={styles.eagleLeaderText}>{eagleLeaderInfo.role}</h5>
                    )}
                    {!minifiedCard && <p>{user?.preferred_chapter?.name}</p>}
                    <p>
                      {displayMilitaryInfo(
                        user.military_status,
                        user.military_branch,
                      )}
                    </p>
                  </div>
                  <div className={styles.buttonContainer}>
                      { !minifiedCard && (!user?.anonymous_profile && userProfile.getUserProfile().id !== user.id) && (
                        <FollowButton
                          user={user}
                          type={'userCard'}
                        />
                      )}
                      {/* Checks if the user is the current user, if they are we will hide the ReportAndDeleteOverlay unless they already have a eagle_leader_info so they can view their info. */}
                      { !minifiedCard && (user.id !== userProfile.getUserProfile().id || eagleLeaderInfo.eagleLeaderInfo) ? <ReportAndDeleteOverlay
                        type="member"
                        isGroupList={isGroupList}
                        eagle_leader={user.eagle_leader}
                        groupName={groupName}
                        isGroupAdmin={isGroupAdmin}
                        user={user}
                        posterID={user.id}
                        hasPermission={privilegedIds?.has(user.id)}
                        handleUpdatedELInfo={handleUpdatedELInfo}
                      /> : null}
                    </div>
                </div>
                {minifiedCard && <CheckInControlButton eventId={eventId} userId={user.id} clickHandler={onCheckIn} index={i} />}
              </div>
            );
          })
        ) : (
          <>
            {(search === undefined) && (
              <div className={styles.loadingContainer}>
                <p>{NO_USERS_FOUND}</p>
              </div>
            )}
          </>
        )}
      </>
    )}
  </div>
  )
};

export default UsersList;
