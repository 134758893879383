import moment from 'moment';
import React from 'react';
import { EL_LABELS, getOffboardingMessage } from '../../../../shared/constants/EagleLeaderManagementStrings';
import EagleLeaderInfo from '../../../../shared/models/EagleLeaderInfo';
import { formatPhoneNumber } from '../../../../shared/utils/Helpers';
import styles from './MembersInfo.module.css';

const MemberInfo = ({ user }) => {
    const {
        phone,
        location,
        do_not_contact,
        do_not_call,
        email,
        last_check_in_date,
        date_joined,
        shirt_transaction_datetime,
        eagle_leader_info
    } = user;

    let formattedPhoneNumber = formatPhoneNumber(phone);
    let eagleLeaderInfo = new EagleLeaderInfo(eagle_leader_info);
    const offboardingInfo = getOffboardingMessage(eagleLeaderInfo.offboardingNotification, user);
    return (
    <div>
        {offboardingInfo && <div className={styles.alertBox}>
            <p className={styles.alertText} style={{ color: offboardingInfo.alert === 'Warning - 45 Day External' ? 'var(--magenta)' : 'var(--orange)' }}>
                <strong>{offboardingInfo.alert}</strong></p>
            <p className={styles.alertMessage}>{offboardingInfo.message}</p>
        </div>}
        <div className={styles.memberInfoContainer}>
            <div className={styles.memberDetails}>
                <div>
                    <p className="formLabel">MEMBER CONTACT</p>
                    {location && <p className={styles.infoText}>{`${location.city}, ${location.address_state} ${location.zip}`}</p>}
                    {formattedPhoneNumber && !do_not_call && <p className={styles.infoText}>{formattedPhoneNumber}</p>}
                    {!do_not_contact && <p className={styles.infoText}>{email}</p>}
                </div>
                <div>
                    <p className="formLabel">MEMBER ACTIVITY</p>
                    <div className={styles.infoContainer}>
                    <p className={styles.infoTitle}>Latest Check-in:</p>
                    {/* NOTE: The format should be 'MM/DD/YYYY' else where in the app */}
                    <p className={styles.infoText}>{last_check_in_date ? moment(last_check_in_date).format('MM/DD/YYYY') : 'NA'}</p>
                    </div>
                    <div className={styles.infoContainer}>
                    <p className={styles.infoText}>Join Date:</p>
                    <p className={styles.infoText}>{moment(date_joined).format('MM/DD/YYYY')}</p>
                    </div>
                    <div className={styles.infoContainer}>
                    <p className={styles.infoTitle}>Red Shirt Ordered:</p>
                    <p className={styles.infoText}>{shirt_transaction_datetime ? moment(shirt_transaction_datetime).format('MM/DD/YYYY') : 'NA'}</p>
                    </div>
                </div>
            </div>
            {eagle_leader_info && <div className={styles.eagleLeaderInfo}>
                <p className="formLabel">EAGLE LEADER INFO</p>
                <div className={styles.infoContainer}>
                    <p className={styles.infoTitle}>Start Date:</p>
                    <p className={styles.infoText}>{moment(eagleLeaderInfo.startDate).format('MM/DD/YYYY')}</p>
                </div>
                <div className={styles.infoContainer}>
                    <p className={styles.infoTitle}>Onboarding Status:</p>
                    <p className={`${styles.statusValue} ${eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE ? styles.completed : styles.pending}`}>
                        {eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE
                            ? `Completed ${eagleLeaderInfo.trainingCompletionDateDisplay()}`
                            : `Pending`}
                    </p>
                </div>
            </div>}
        </div>
    </div>
)};

export default MemberInfo;
