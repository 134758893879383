import React from 'react';
import ELSChart from './ELSChart.react';
import ELSLineGraph from './ELSLineGraph.react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import Header from '../Header';
import StyledText from './SyledText.react';
import { formatTitle, heatlhMessagePicker, recommendationPicker } from '../../../../shared/utils/ELSHelper';
import { userProfile } from '../../../../shared/models/UserProfile';
import { MILITARY_STATUSES } from '../../../../shared/constants/MilitaryStatusSlugs';

const DetailedHealthView = () => {
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    let { title, score, average, lineGraphData } = location || {};
    /// prevent crash on refresh, however score will be undefined. TODO determine if we pass values in url or expect no refreshes
    if (!title) title = params.healthView;
    const displayTitle = formatTitle(title);
    const recommendations = recommendationPicker(title);
    const isVGRAD = userProfile.getUserProfile().military_status !== MILITARY_STATUSES.civilian;
    return (
        <div>
            <Header title={displayTitle} onBack={history.goBack} />
            <div style={{ padding: 20 }}>
                <div>
                    <ELSChart value={score} average={average} pending={false} />
                    {lineGraphData && lineGraphData.length > 1 && <ELSLineGraph data={lineGraphData} />}
                </div>
                <div>
                    <h2>{displayTitle}: {score}</h2>
                    <br />
                    <StyledText rawText={heatlhMessagePicker(title, score, average)}/>
                </div>
                <br />
                <br />
                <div>
                    <h2>Recommendations</h2>
                    <br />
                    <p>{recommendations.improveMessage}</p>
                    {recommendations.recommendations.map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                <p>{'\u2022'}&nbsp;</p>
                                <StyledText rawText={item} />
                            </div>
                        )
                    })}
                    {isVGRAD && recommendations?.vgradRecommendations?.length > 0 && recommendations?.vgradRecommendations.map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                <p>{'\u2022'}&nbsp;</p>
                                <StyledText rawText={item} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default DetailedHealthView;
