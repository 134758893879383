/**
 * Basic error alerting function for raising messages to end users, using a
 * basic browser alert.
 *
 * @param {string} message - The message to show the end user.
 */
export default function errorAlert(message) {
  // eslint-disable-next-line no-alert
  alert(`Team RWB: ${message}`);
}
