import moment from 'moment';

export const progressChart = (value, horizontal, average) => {
    const radius = horizontal ? 120 : 180;
    const width = 2 * radius;
    const height = radius;
    const strokeWidth = horizontal ? 20 : 30;
    const circumference = Math.PI * (radius - strokeWidth / 2);
    const offset = circumference - (circumference * value / 100) + (strokeWidth / 2) - 15;

    const markerValue = average + 3; //average (add 3 to make up for the rounded end)
    const markerX = width / 2 + (radius - strokeWidth / 2) * Math.cos(Math.PI * (1 - markerValue / 100));
    const markerY = height - (radius - strokeWidth / 2) * Math.sin(Math.PI * (1 - markerValue / 100));

    // Average specific styling
    const lineLength = 40;  // adjust this to control the length of the line
    const lineModifier = .8;
    const lineX1 = width / 2 + (radius - strokeWidth / lineModifier) * Math.cos(Math.PI * (1 - markerValue / 100));
    const lineY1 = height - (radius - strokeWidth / lineModifier) * Math.sin(Math.PI * (1 - markerValue / 100));
    const lineX2 = width / 2 + (radius - strokeWidth / lineModifier + lineLength) * Math.cos(Math.PI * (1 - markerValue / 100));
    const lineY2 = height - (radius - strokeWidth / lineModifier + lineLength) * Math.sin(Math.PI * (1 - markerValue / 100));

    const viewBoxDimeions = !horizontal ? "-50 0 460 180" : "-150 0 400 220";

    let averageRotation;
    let base = Math.floor((average + 3) / 2);
    const padding = Math.floor(average);
    if (average < 50)
        averageRotation = base - (70 - padding);
    else if (average > 50) {
        averageRotation = base + (Math.floor(padding/10));
    }

    return {
        radius,
        width,
        height,
        strokeWidth,
        circumference,
        offset,
        markerValue,
        markerX,
        markerY,
        lineX1,
        lineY1,
        lineX2,
        lineY2,
        viewBoxDimeions,
        averageRotation,
    }
}

const generateTickRange = (minY, scale) => {
    let tickArray = [minY];
    if (scale < 10) scale = 10;
    for (let i = 0; i < 4; i++) {
      let newTick = tickArray[i] + scale;
      tickArray.push(newTick);
    }
    return tickArray;
  }

const generateYAxis = (data, scale) => {
    const scores = data.map(d => d.total_score);
    let max = Math.max(...scores);
    let min = Math.min(...scores);
    const minRemainder = min % scale;
    min = min - (minRemainder === 0 ? scale : minRemainder);
    const maxRemainder = max % scale;
    const maxModifier = scale > maxRemainder ? scale - maxRemainder : maxRemainder - scale;
    max = max + (maxRemainder === 0 ? scale : maxModifier);;
    if (min < 0) min = 0;
    if (max > 100) max = 100;
    scale = (max - min) / 4;
    return {max, min, scale};
}

export const lineGraph = (data, graphWidth) => {
    const height = 300;
    const margin = { top: 20, right: 20, bottom: 20, left: 40 };
    const graphHeight = height - margin.top - margin.bottom;
    const modifiedData = data.map(d => {
        const date = new moment(d.survey_response_date);
        return { ...d, month: date.format('MMM').toUpperCase(), year: date.format('YYYY') };
    });


    const leftPadding = 0.05 * graphWidth;
    const rightPadding = 0.05 * graphWidth;

    const xScale = (x) => leftPadding + (x / (data.length - 1)) * (graphWidth - leftPadding - rightPadding);
    const yScale = (y) => graphHeight - (0.2 * graphHeight + (y / 100) * (0.7 * graphHeight));

    // Define the line
    const line = modifiedData.map((d, i) => `${xScale(i)},${yScale(d.total_score)}`).join(' ');

    let minY;
    let maxY;
    let scale = 10; // Ensure min and max are a 10s
    const yAxis = generateYAxis(data, scale);
    minY = yAxis.min;
    maxY = yAxis.max;
    scale = yAxis.scale;

    const tickRange = generateTickRange(minY, scale);

    return {
        height,
        margin,
        graphHeight,
        modifiedData,
        xScale,
        yScale,
        minY,
        maxY,
        scale,
        line,
        tickRange,
    }
}
