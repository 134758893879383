import React, {Component} from 'react';
import {DOMAIN, ENVIRONMENT} from '../../../shared/constants/URLs';

import { userProfile } from '../../../shared/models/UserProfile';

export default class ApiInfo extends Component {
  render() {
    const user_profile = userProfile.getUserProfile();
    return (
      <>
        {window.location.host !== 'members.teamrwb.org' && (
          <div>
            <p>Current API Server: {DOMAIN}</p>
            <p>Environment: {ENVIRONMENT}</p>
            <p>Profile ID: {user_profile.id}</p>
            <p>Salesforce Contact ID: {user_profile.salesforce_contact_id}</p>
            <p>Note: This info is not rendered on production.</p>
          </div>
        )}
      </>
    );
  }
}
