import React from 'react';
import Attendee from '../events/Attendee';
import CheckInControlButton from './CheckInControlButton.react';
import styles from './GroupMembersList.module.css';

const GroupMembersList = ({label, data, onSelect, isSponsor, minifiedCard, eventId, onCheckIn, isGroupList, groupName, isGroupAdmin, privilegedIds}) => (
  <>
    <p className={`${'titleSubheader'} ${styles.label}`}>{label}</p>
    {data.map((item, i) => (
      <div className={styles.attendeeContainer} key={`container-${i}`}>
        <Attendee
          key={i}
          item={item}
          onSelect={onSelect}
          sponsorAdmin={label === 'admins' && isSponsor}
          privilegedIds={privilegedIds}
          minifiedCard={minifiedCard}
          hideOverlay={minifiedCard}
          eventId={eventId}
          isGroupList={isGroupList}
          groupName={groupName}
          isGroupAdmin={isGroupAdmin}
        />
        {minifiedCard && <CheckInControlButton key={`check-in-${i}`} eventId={eventId} userId={item.id} clickHandler={onCheckIn} index={i} listSection={label}/>}
      </div>
    ))}
    </>
);

export default GroupMembersList;
