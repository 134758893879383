import React, { useState, useEffect } from 'react';
import { Paper, Toolbar, IconButton, Tabs, Tab, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Attendee from './Attendee';
import EventHost from './EventHost';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { rwbApi } from '../../../../shared/apis/api';
import ChevronBackIcon from '../svgs/ChevronBackIcon';
import Loading from '../Loading';
import moment from 'moment';
import CheckIcon from '../svgs/CheckIcon';
import InterestedIcon from '../svgs/InterestedIcon';
import TicketIcon from '../svgs/TicketIcon';
import { userProfile } from '../../../../shared/models/UserProfile';
import { ATTENDANCE_SLUGS } from '../../../../shared/constants/AttendanceSlugs';
import { ClipLoader } from 'react-spinners';
import { hasReachedBottom } from '../../BrowserUtil';
import { localizeDate } from '../../../../shared/utils/Helpers';
import Header from '../Header';
import AddIcon from '../svgs/AddIcon';
import CheckInControlButton from '../groups/CheckInControlButton.react';

const styles = {
  root: {
    flexGrow: 1,
    maxWidth: '100%',
    height: '100%',
    borderRadius: 0,
  },
  toolbar: {
    backgroundColor: 'var(--magenta)',
    paddingLeft: 40,
    paddingRight: 40,
  },
  menuButton: {
    marginRight: '15px',
    color: 'var(--white)',
  },
  title: {
    color: 'var(--white)',
  },
  iconLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: 'var(--grey)',
    fontFamily: 'OpenSans-Bold',
    fontSize: 12,
  },
  iconLabelWrapperSelected: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: 'var(--magenta)',
    fontFamily: 'OpenSans-Bold',
    fontSize: 12,
  },
  indicator: {
    backgroundColor: 'var(--magenta)',
  },
  addMemberContainer: {
    display: 'flex',
    paddingLeft: 40,
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: 'center',
    borderBottom: '1px solid var(--grey20)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
};

const EventDetailAttendees = ({ classes }) => {
  const isEventTodayOrAfter = () => {
    const today = moment();
    const eventDate = localizeDate(
      location.state.isVirtual,
      location.state.timeZone,
      location.state.date,
    );
    return today.isSameOrAfter(eventDate, 'day');
  };

  const GOING_TAB_VALUE = 0;
  const INTERESTED_TAB_VALUE = 1;
  const CHECKED_IN_TAB_VALUE = 2;

  const location = useLocation();
  const eventHost = location.state.host;
  const groupId = location.state.groupId;
  const isGroupAdminOrModerator = location?.state?.isGroupAdminOrModerator;
  const { eventId } = useParams();
  const [selectedValue, setselectedValue] = useState(
    isEventTodayOrAfter() ? CHECKED_IN_TAB_VALUE : GOING_TAB_VALUE,
  );
  const [goingAttendees, setGoingAttendees] = useState([]);
  const [interestedAttendees, setInterestedAttendees] = useState([]);
  const [checkedInAttendees, setCheckedInAttendees] = useState([]);
  const [filteredAttendees, setfilteredAttendees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [checkedInPage, setCheckedInPage] = useState(1);
  const [goingPage, setGoingPage] = useState(1);
  const [interestedPage, setInterestedPage] = useState(1);

  const [retrievedLastCheckedIn, setRetrievedLastCheckedIn] = useState(false);
  const [retrievedLastGoing, setRetrievedLastGoing] = useState(false);
  const [retrievedLastInterested, setRetrievedLastInterested] = useState(false);

  const history = useHistory();
  useEffect(() => {
    setIsLoading(true);
    rwbApi.getAllMobileAttendees(eventId).then((data) => {
      if (data) {
        const { going, checked_in, interested } = data;
        setGoingAttendees(going.attendees);
        setInterestedAttendees(interested.attendees);
        setCheckedInAttendees(checked_in.attendees);
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    filterAttendees(selectedValue);
  }, [isLoading]);

  const filterAttendees = (index) => {
    setselectedValue(index);
    switch (index) {
      case CHECKED_IN_TAB_VALUE:
        setfilteredAttendees(checkedInAttendees);
        break;
      case GOING_TAB_VALUE:
        setfilteredAttendees(goingAttendees);
        break;
      case INTERESTED_TAB_VALUE:
        setfilteredAttendees(interestedAttendees);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', trackScrolling);
    return () => {
      window.removeEventListener('scroll', trackScrolling);
    };
  }, [filteredAttendees, selectedValue, retrievedLastCheckedIn, retrievedLastGoing, retrievedLastInterested, isLoadingMore]);

  const trackScrolling = (event) => {
    event.preventDefault();
    const wrappedElement = document.getElementById('root');
    if (hasReachedBottom(wrappedElement) && !isLoadingMore) {
      switch (selectedValue) {
        case CHECKED_IN_TAB_VALUE:
          if (!retrievedLastCheckedIn && checkedInAttendees.length >= 10) loadMore(ATTENDANCE_SLUGS.checkedin);
          break;
        case GOING_TAB_VALUE:
          if (!retrievedLastGoing && goingAttendees.length >= 10) loadMore(ATTENDANCE_SLUGS.going);
          break;
        case INTERESTED_TAB_VALUE:
          if (!retrievedLastInterested && interestedAttendees.length >= 10) loadMore(ATTENDANCE_SLUGS.interested);
          break;
        default:
          break;
      }
    }
  };

  const loadMore = (status) => {
    setIsLoadingMore(true);
    let page;
    let list;
    if (status === ATTENDANCE_SLUGS.interested) {
      page = interestedPage;
      list = interestedAttendees;
    } else if (status === ATTENDANCE_SLUGS.going) {
      page = goingPage;
      list = goingAttendees;
    } else if (status === ATTENDANCE_SLUGS.checkedin) {
      page = checkedInPage;
      list = checkedInAttendees;
    } else {
      throw new Error('No status to query attendees for.');
    }
    rwbApi
      .getMobileAttendees(eventId, status, page)
      .then((responseJson) => {
        let attendees;
        let userCount;
        if (responseJson.going) {
          attendees = responseJson.going.attendees;
          userCount = responseJson.going.total_count;
        } else if (responseJson.interested) {
          attendees = responseJson.interested.attendees;
          userCount = responseJson.interested.total_count;
        } else if (responseJson.checked_in) {
          attendees = responseJson.checked_in.attendees;
          userCount = responseJson.checked_in.total_count;
        }
        setIsLoadingMore(false);
        // set the total page to by user count divided by 10, always rounding up
        const totalPages = Math.ceil(userCount / 10);
        let doneLoading = totalPages === page || (isNaN(totalPages) || !attendees);
        switch (status) {
          case ATTENDANCE_SLUGS.interested:
            setInterestedAttendees([...list, ...attendees]);
            // prevent page from increasing while loading.
            setInterestedPage((prevState) =>
              prevState === page ? prevState + 1 : prevState,
            );
            if (doneLoading) setRetrievedLastInterested(true);
            break;
          case ATTENDANCE_SLUGS.going:
            setGoingAttendees([...list, ...attendees]);
            // prevent page from increasing while loading.
            setGoingPage((prevState) =>
              prevState === page ? prevState + 1 : prevState,
            );
            if (doneLoading) setRetrievedLastGoing(true);
            break;
          case ATTENDANCE_SLUGS.checkedin:
            setCheckedInAttendees([...list, ...attendees]);
            // prevent page from increasing while loading.
            setCheckedInPage((prevState) =>
              prevState === page ? prevState + 1 : prevState,
            );
            if (doneLoading) setRetrievedLastCheckedIn(true);
            break;
          default:
            break;
        }

        setfilteredAttendees([...list, ...attendees]);
      })
      .catch((error) => {
        setIsLoadingMore(false);
        if (error.message !== 'attendees is not iterable') {
          alert('There was an error fetching the rest of the event attendees.');
        }
        switch (status) {
          case ATTENDANCE_SLUGS.interested:
            setRetrievedLastInterested(true);
            break;
          case ATTENDANCE_SLUGS.going:
            setRetrievedLastGoing(true);
            break;
          case ATTENDANCE_SLUGS.checkedin:
            setRetrievedLastCheckedIn(true);
            break;
          default:
            break;
        }
      });
  };

  // list section is only used in group members
  const hideMember = (index, listSection, isCheckIn) => {
    let list = filteredAttendees;
    const removedUser = list.splice(index, 1);
    setfilteredAttendees([...list]);
    if (isCheckIn !== false) setCheckedInAttendees([...removedUser, ...checkedInAttendees]);
  }

  return (
    <div id={'root'}>
      {isLoading ? (
        <Loading size={100} color={'var(--white)'} loading={isLoading} right />
      ) : (
        <>
          <Paper className={classes.root}>
            <Header
              title={'Attendees'}
              onBack={() => history.goBack()}
              lessHeight
            />
            <Tabs
              value={selectedValue}
              onChange={(_, i) => filterAttendees(i)}
              variant="fullWidth"
              classes={{
                indicator: classes.indicator,
              }}>
              <Tab
                value={GOING_TAB_VALUE}
                label={'going'}
                icon={
                  <CheckIcon
                    tintColor={
                      selectedValue === GOING_TAB_VALUE ? 'var(--magenta)' : 'var(--grey)'
                    }
                    height={20}
                  />
                }
                classes={{
                  wrapper:
                    selectedValue === GOING_TAB_VALUE
                      ? classes.iconLabelWrapperSelected
                      : classes.iconLabelWrapper,
                }}
              />
              <Tab
                value={INTERESTED_TAB_VALUE}
                label="interested"
                icon={
                  <InterestedIcon
                    tintColor={
                      selectedValue === INTERESTED_TAB_VALUE ? 'var(--magenta)' : 'var(--grey)'
                    }
                    height={20}
                  />
                }
                classes={{
                  wrapper:
                    selectedValue === INTERESTED_TAB_VALUE
                      ? classes.iconLabelWrapperSelected
                      : classes.iconLabelWrapper,
                }}
              />
              {isEventTodayOrAfter() && (
                <Tab
                  value={CHECKED_IN_TAB_VALUE}
                  label={'checked in'}
                  icon={
                    <TicketIcon
                      tintColor={
                        selectedValue === CHECKED_IN_TAB_VALUE ? 'var(--magenta)' : 'var(--grey)'
                      }
                      height={20}
                    />
                  }
                  classes={{
                    wrapper:
                      selectedValue === CHECKED_IN_TAB_VALUE
                        ? classes.iconLabelWrapperSelected
                        : classes.iconLabelWrapper,
                  }}
                />
              )}
            </Tabs>
            <EventHost
              key={0}
              item={eventHost}
              host={true}
              onSelect={() =>
                userProfile.eagle_leader || eventHost.public_profile
                  ? history.push(`/profile/${eventHost.id}`)
                  : null
              }
            />
            {selectedValue === CHECKED_IN_TAB_VALUE && groupId && (isGroupAdminOrModerator || eventHost.id === userProfile.getUserProfile().id) &&
              <Link
                to={{
                  pathname: `/events/${eventId}/attendees/${groupId}`,
                  state: { groupId, minifiedCard: true, eventId },
                }}>
                <div className={classes.addMemberContainer}>
                  <AddIcon tintColor={'var(--magenta)'} height={24} width={24} />
                  <h3 style={{ color: 'var(--magenta)', marginLeft: 20 }}>Add Member to Checked In</h3>
                </div>
              </Link>
            }
            {filteredAttendees.map((item, i) =>
              item.id !== eventHost.id ? (
                <div style={{ display: 'flex', alignItems: 'center' }} key={`attendee-${i}`}>
                  <Attendee
                    minifiedCard={true}
                    key={i}
                    item={item}
                    hideOverlay={item.id === userProfile.getUserProfile().id || false}
                    canCheckUserIn={CHECKED_IN_TAB_VALUE && (isGroupAdminOrModerator || userProfile.getUserProfile().id === eventHost.id) && isEventTodayOrAfter()}
                    onSelect={(item) =>
                      userProfile.eagle_leader || item.public_profile
                        ? history.push(`/profile/${item.id}`)
                        : null
                    }
                    checkInButton={<CheckInControlButton eventId={eventId} userId={item.id} clickHandler={hideMember} index={i} removeCheckIn={selectedValue === CHECKED_IN_TAB_VALUE} />}
                  /> {/* only visit the profile if the user is an eagle leader (bypassing anonymous users) or the profile is public */}
                </div>
              ) : null,
            )}

            {isLoadingMore && (
              <Grid container justify={'center'}>
                <ClipLoader
                  size={60}
                  color={'var(--grey20)'}
                  loading={isLoadingMore}
                />
              </Grid>
            )}
          </Paper>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(EventDetailAttendees);
