import React, {Component} from 'react';
import {TEAMRWB} from '../../../../shared/constants/URLs';
import RWBButton from '../RWBButton';
import styles from './Registration.module.css';
import VerifyEmailIcon from '../svgs/VerifyEmailIcon';
import {authentication} from '../../../../shared/models/Authentication';
import {logVerifyEmail} from '../../../../shared/models/Analytics';
import {withRouter} from 'react-router-dom';
import {rwbApi} from '../../../../shared/apis/api';
import Loading from '../Loading';
import {INVALID_CONFIRMATION_CODE} from '../../../../shared/constants/ErrorMessages';

const verificationCodeLength = 6;
class RegisterVerifyEmail extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      confirmationCode: new Array(verificationCodeLength).fill(''),
      errorMessage: '',
    };
  }

  logout = () => {
    authentication.deleteAuthentication();
    this.props.history.replace('/login');
  };

  componentDidMount() {
    this.checkVerification();
  }

  checkVerification = () => {
    const {history} = this.props;
    this.setState({
      isLoading: true,
    });
    rwbApi
      .getUser()
      .then((response) => {
        const {email_verified, profile_completed} = response;
        if (email_verified === true && profile_completed === false) {
          history.push({
            pathname: '/registration/personal_info',
            state: {from: 'Verify Email'},
          });
        } else if (email_verified === true && profile_completed === true) {
          this.props.history.replace('/feed');
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
        alert('There was an error contacting the server. Please try again.');
      });
  };

  verifyEmail = () => {
    let analyticsObj = {};
    if (this.props.location.state && this.props.location.state.from)
      analyticsObj.previous_view = this.props.location.state.from;
    // logVerifyEmail(analyticsObj);
    this.setState({errorMessage: ''});
    const code = this.state.confirmationCode.join('');
    rwbApi
      .verifyEmail(code)
      .then(() => {
        this.props.history.push('/registration/personal_info');
      })
      .catch((err) => {
        if (err.toString() === 'Error: Unable to verify email.') {
          this.setState({
            errorMessage: INVALID_CONFIRMATION_CODE.toUpperCase(),
          });
        } else {
          alert('Team RWB Error: Please try again later.');
        }
      });
  };

  updateCode = (index, event) => {
    let code = this.state.confirmationCode;
    let value = event.target.value;
    // the user can press a new number after backspacing, keep the original number and prevent getting double digits
    if (value.length > 1) {
      value = value.charAt(0);
    }
    const currentValue = code[index];
    code[index] = value;
    // focus into next or previous input
    const newIndex = value !== '' ? index + 1 : index - 1;
    if (newIndex >= 0 && newIndex < verificationCodeLength) {
      if (
        (currentValue === '' && value !== '') ||
        (currentValue === '' && value === '')
      )
        document.getElementById(`codeInput-${newIndex}`).focus();
    }
    // prevent manual input of more numbers
    if (currentValue === '' && parseInt(value) <= 9) {
      this.setState({confirmationCode: code});
    }
  };

  handleBack = (index, event) => {
    // this function is called on key down and picks up all key events
    // if the key down is a "." prevent it from being added
    if (event.keyCode === 190) event.preventDefault();
    // backbutton
    if (event.keyCode === 8) {
      this.setState({errorMessage: ''});
      let code = this.state.confirmationCode;
      const inputValue = this.state.confirmationCode[index];
      if (inputValue === '' && index > 0) {
        document.getElementById(`codeInput-${index - 1}`).focus();
      }
      code[index] = '';
      this.setState({confirmationCode: code});
    }
  };

  render() {
    const {isLoading} = this.state;

    return (
      <div className={styles.container}>
        <Loading size={100} color={'var(--white)'} loading={isLoading} />
        <div className={styles.headerContainer}>
          <h3 className="title">Confirmation Code</h3>
          <p className="titleSubheader">Step 2 of 6</p>
        </div>
        <div className={styles.contentContainer}>
          <div>
            <VerifyEmailIcon width="100%" height={65} />
            <div className={styles.verifyEmailContent}>
              <p>
                To keep your information safe and secure, <br />a confirmation
                code has been sent to the email you have provided.
              </p>
              <br />
              <p>Please enter confirmation code to continue registration.</p>
            </div>
          </div>
          <div>
            {new Array(verificationCodeLength).fill(0).map((value, index) => {
              return (
                <input
                  value={this.state.confirmationCode[index]}
                  autoFocus={index === 0}
                  id={`codeInput-${index}`}
                  key={`codeInput-${index}`}
                  type="number"
                  className={styles.codeInput}
                  max={9}
                  min={0}
                  maxLength={1}
                  onChange={(event) => this.updateCode(index, event)}
                  onKeyDown={(event) => this.handleBack(index, event)}
                />
              );
            })}
            <p className="errorMessage" style={{marginLeft: 5, marginTop: 5}}>
              {this.state.errorMessage}
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <p className={styles.noEmail}>
              Didn&apos;t receive the email? <br />
              Check your spam folder or{' '}
              <a className="link" href={TEAMRWB.contact}>
                contact us
              </a>{' '}
              for help.
            </p>
            <RWBButton
              onClick={this.verifyEmail}
              label={'Next'}
              disabled={this.state.confirmationCode.indexOf('') !== -1}
              buttonStyle={'primary'}
              customStyles={
                this.state.confirmationCode.indexOf('') !== -1
                  ? {opacity: '50%', cursor: 'initial'}
                  : {}
              }
            />
            <RWBButton
              onClick={this.logout}
              label={'Not you? Back to login.'}
              buttonStyle={'secondary'}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RegisterVerifyEmail);
