import React from 'react';
import styles from './CreatePost.module.css';
import GalleryIcon from '../svgs/GalleryIcon';
import { MAX_IMAGE_HEIGHTS, WEB_MIMES } from '../../../../shared/constants/ImageConstants';
import { MAX_POST_IMAGES } from '../../../../shared/constants/Restrictions';

/**
 * 
 * @param {array[string]} postImages - array of image uris to display and update
 * @param {function} updatePostImages - updates the parents list of post images to display
 * @param {string} activityType - a "post", "comment", or "reply" to display 
 * @returns 
 */

const PostImageAdder = ({postImages, updatePostImages, activityType}) => {

    const addImageHandler = (e) => {
        const files = Array.from(e.target.files);
        const BASE_ALERT_MESSAGE = 'Maximum numbers of images for post has been reached.';
        if (files?.length > MAX_POST_IMAGES) {
          window.alert(`${BASE_ALERT_MESSAGE} Please ensure you have no more than ${MAX_POST_IMAGES} images selected.`);
          return false;
        } else if (files?.length + (postImages?.length || 0) > MAX_POST_IMAGES) {
          window.alert(BASE_ALERT_MESSAGE);
          return false;
        }
        let images = [];
        let imagesProcessed = 0;
        Array.from(files).forEach((file, index) => {
          const reader = new FileReader();
          if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
              images[index] = { image: reader.result, name: file.name, file };
              imagesProcessed++;
              if (imagesProcessed === files.length) updatePostImages([...postImages, ...images]);
            };
            // reader.onloadstart = () => setIsLoadingImage(true);
            // reader.onloadend = () => {
            //   setIsLoadingImage(false);
            // };
            reader.onerror = (e) => {
              imagesProcessed++;
              if (imagesProcessed === files.length) updatePostImages([...postImages, ...images]);
            }
          }
        });
      };
    
     

    return (
        <div className={styles.attachPhotoContainer}>
          <label className={styles.attachPhotoButton} style={{ cursor: postImages.length >= MAX_POST_IMAGES ? 'default' : 'pointer' }}>
            {postImages.length >= MAX_POST_IMAGES ? <GalleryIcon tintColor="var(--grey40)" /> : <GalleryIcon tintColor="var(--white)" /> }
            <span className={`formLabel ${styles.attachPhotoText}`} style={{ color: postImages.length >= MAX_POST_IMAGES ? 'var(--grey40)' : 'var(--white)' }}>
              {`Add a photo to your ${activityType}`}
            </span>

            <input
              type="file"
              multiple="multiple" // this adds support for control/command click to add more
              files={postImages?.map(img => img.file) || []}
              disabled={postImages.length >= MAX_POST_IMAGES}
              accept={WEB_MIMES}
              className={styles.hideDefaultUpload}
              onChange={addImageHandler}
              onClick={(event) => {
                event.target.value = null; // this allows the same image to be selected if the user clicks the "X"
              }}
            />
          </label>
        </div>
    )
}

export default PostImageAdder;
