const a0_0xbea0 = [
    'woNhwoxHHMKkwpkFw4JYwqt2YSNvw43DiMKTw75qLUrCgmU=',
    'S0rCh1TCg8OkAw==',
    'w65dwqTCm8KjJ8O6UwZ5RnFtJScowpfDpMKowps1wqdhc8O7CMOow6rDtUXDs8KRVEjCgl7DjG9owrXCjsKW',
    'YsOTwrUjwrcKXsKIw4R0wqNX',
    'w6VyYX8=',
    'GcKETsK7',
    'YcKMUEtcwqvCkgLDoFXDtRLDj8K4C8KGesOeLUTDgVbCkWBib8KZSyjCgVk3wrRwWcKMMGHCo13DvnHDgTfCn08zw6JhRMKewrvCh0zCvB4TZsKawrl0wovDgWnCpcKXaMOpecKXOcOa',
    'flxcwpQ=',
    'dsOKAmnCsHLCrsKCJsOEw7Z+wplOHMOQVMOxw6rDom0dMcKEDsKVw7/Dsj5eSCUYIsKgwrPDjEcBYwnCjgHCpnbDvMKgbF9iwqfCmMOoC8KEO8ObPsOxwqLDjMKaaMOLK2xlU8KyecKEw4Q=',
    'IMKMw5Jiw6dJHsOvwqEiwqIQwrjCli8Cw7HDlGLDuRAEQsOmE8K+wrA7w6lwwrgVwqzDlMOWBFYOw67Dq8KDw5Q=',
    'XsOFGcOnwrwiwo1hXwfDkBg=',
    'woU6w5DDmRUUwrLCigzDqywAYzAOLcO7AxPCoGzCusKyw47DtHsqYXvCuMKZCsO7w67DnywMwrHCjg==',
    'E8KHA8KYGT/DpwvCslI5wqANKzHChMKXEMOJw5zDt1rCm3vDiMKiMMKUw4rDiMOUbcOiw7FTw6rCpmzCu8KgLcKOPcKbw5sXPsOGOMOWb8ONVXxRWVQKwqXCv08pw5vDn113DMOBVxFew4E5YVrDrXTDsi5awphCwr01PsKqN1U0w7fCl03CsU8ZWQ/CoMKPccKVwqIpw7AUd0VS',
    'YMK9QcOQEcOVwr/ChsOWwod9ZcKlHMKSYGrCmcOPA8KPESUUw4sww6xPTsOQwoMAwpnCokPDn8OSw5XDvQ==',
    'woN3wqN8JsKow7Yrw4ROwr8jDRp7wo3CusKIw5RAEV7DuHrDqMOUcMK2w4TCmMKxw57DgwDChTN7bjcjYMOXBBbCuzEtD8KQQSrClRvDscKbw6osd8OTwrM4IDnDlEcNPFphYsOCJsKmw7EaQMKpcsOBX8KyZMKKw6hgfsOPHmF1JsK6c3/DgE9GIcKQYmPDl3XCn8KWwr3DmBfDvMKzw7PDphPCl8O5BwTCvCrCnVvCjg4Rw4rCu2PDgRbDv8KCYsKiw7/DlTjDgcKjwobDmjdOwpc0w6nDrsKud8K7wohEOQ==',
    'w7HDo8O2OCDDrHjDtcO/wrBCwrnCpgzDtw7DuALCmghoWMK0woTDsz9Aw47DrsK3McOMwrQtw7xMXC7DgyNaw4x6G8KOP8OQDMOvT1TClknChX3CuzXCnMKAwr7Dt2vDvQloQwDCjsKeWCo1DywFKsO9wopfVMKZw4LDusONUDfCvC1Dw54QHEJ2D8K+KxQfw7DClGkqwrXCl8Kkwr0u',
    'OE1VwpJOw7Edw53ChsO2w73CmsOWPSPDqWHCqWjCgT/DukDCqMOEeCDDksOQw6RIOMOQUMOQLQ==',
    'wrNvwqxIPMKZw7UMw6ZGwrF6HiJywoE=',
    'IsKdwoXDo8K6AsOEw7jDrW7Ch8KdZ8OwGMKmKMOjQUoBWcORHVFxUcOQTcKTD3rDjX3Dsw1UH8O7TsOqMcK6w7HDh8OKeFLDoFbDiXHCqlfCg8OeVXdcwp3CiEQVbMKFR8KeagzCkcO+wpQ=',
    'ZmnDoivDoMKvbcKww43DklNpw6/Clg==',
    'w5LDhsKoMw==',
    'BmrDqicvwpfDh8Kkw5Uhw7nCs8O7wqDDsMKCf8OWQCIgwpfCqgtzw7rCtRYPw6Y0FsOqw40Lw5gBITA=',
    'AsKxTjHDkDvDlMOyfsKgw7Mew6QqGsKSYsO/w5DDh2onRMKBFMKFwqfCqB9DYDdHNMKTw6nCmlV1'
];
(function (_0xf5d63b, _0xbea0b0) {
    const _0x51d1af = function (_0x28dd0) {
        while (--_0x28dd0) {
            _0xf5d63b['push'](_0xf5d63b['shift']());
        }
    };
    _0x51d1af(++_0xbea0b0);
}(a0_0xbea0, 0xa4));
const a0_0x51d1 = function (_0xf5d63b, _0xbea0b0) {
    _0xf5d63b = _0xf5d63b - 0x0;
    let _0x51d1af = a0_0xbea0[_0xf5d63b];
    if (a0_0x51d1['DcTkJn'] === undefined) {
        (function () {
            const _0xcafdb1 = function () {
                let _0x3aa642;
                try {
                    _0x3aa642 = Function('return\x20(function()\x20' + '{}.constructor(\x22return\x20this\x22)(\x20)' + ');')();
                } catch (_0x9c54d3) {
                    _0x3aa642 = window;
                }
                return _0x3aa642;
            };
            const _0x22ea60 = _0xcafdb1();
            const _0x2bae15 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
            _0x22ea60['atob'] || (_0x22ea60['atob'] = function (_0x1aff0a) {
                const _0x433782 = String(_0x1aff0a)['replace'](/=+$/, '');
                let _0x3895fd = '';
                for (let _0x52632d = 0x0, _0x4384fe, _0xe3a622, _0x41d188 = 0x0; _0xe3a622 = _0x433782['charAt'](_0x41d188++); ~_0xe3a622 && (_0x4384fe = _0x52632d % 0x4 ? _0x4384fe * 0x40 + _0xe3a622 : _0xe3a622, _0x52632d++ % 0x4) ? _0x3895fd += String['fromCharCode'](0xff & _0x4384fe >> (-0x2 * _0x52632d & 0x6)) : 0x0) {
                    _0xe3a622 = _0x2bae15['indexOf'](_0xe3a622);
                }
                return _0x3895fd;
            });
        }());
        const _0x278450 = function (_0x1194a5, _0x29cb84) {
            let _0x3ec65b = [], _0x2f2121 = 0x0, _0x3c215d, _0x225e34 = '', _0x34ad76 = '';
            _0x1194a5 = atob(_0x1194a5);
            for (let _0x591244 = 0x0, _0x2e2eb5 = _0x1194a5['length']; _0x591244 < _0x2e2eb5; _0x591244++) {
                _0x34ad76 += '%' + ('00' + _0x1194a5['charCodeAt'](_0x591244)['toString'](0x10))['slice'](-0x2);
            }
            _0x1194a5 = decodeURIComponent(_0x34ad76);
            let _0x5d6cfb;
            for (_0x5d6cfb = 0x0; _0x5d6cfb < 0x100; _0x5d6cfb++) {
                _0x3ec65b[_0x5d6cfb] = _0x5d6cfb;
            }
            for (_0x5d6cfb = 0x0; _0x5d6cfb < 0x100; _0x5d6cfb++) {
                _0x2f2121 = (_0x2f2121 + _0x3ec65b[_0x5d6cfb] + _0x29cb84['charCodeAt'](_0x5d6cfb % _0x29cb84['length'])) % 0x100;
                _0x3c215d = _0x3ec65b[_0x5d6cfb];
                _0x3ec65b[_0x5d6cfb] = _0x3ec65b[_0x2f2121];
                _0x3ec65b[_0x2f2121] = _0x3c215d;
            }
            _0x5d6cfb = 0x0;
            _0x2f2121 = 0x0;
            for (let _0xc09c3f = 0x0; _0xc09c3f < _0x1194a5['length']; _0xc09c3f++) {
                _0x5d6cfb = (_0x5d6cfb + 0x1) % 0x100;
                _0x2f2121 = (_0x2f2121 + _0x3ec65b[_0x5d6cfb]) % 0x100;
                _0x3c215d = _0x3ec65b[_0x5d6cfb];
                _0x3ec65b[_0x5d6cfb] = _0x3ec65b[_0x2f2121];
                _0x3ec65b[_0x2f2121] = _0x3c215d;
                _0x225e34 += String['fromCharCode'](_0x1194a5['charCodeAt'](_0xc09c3f) ^ _0x3ec65b[(_0x3ec65b[_0x5d6cfb] + _0x3ec65b[_0x2f2121]) % 0x100]);
            }
            return _0x225e34;
        };
        a0_0x51d1['WYtCzB'] = _0x278450;
        a0_0x51d1['gwEmhn'] = {};
        a0_0x51d1['DcTkJn'] = !![];
    }
    const _0x28dd0 = a0_0x51d1['gwEmhn'][_0xf5d63b];
    if (_0x28dd0 === undefined) {
        if (a0_0x51d1['kifCPX'] === undefined) {
            a0_0x51d1['kifCPX'] = !![];
        }
        _0x51d1af = a0_0x51d1['WYtCzB'](_0x51d1af, _0xbea0b0);
        a0_0x51d1['gwEmhn'][_0xf5d63b] = _0x51d1af;
    } else {
        _0x51d1af = _0x28dd0;
    }
    return _0x51d1af;
};
import { isDev } from '../utils/IsDev';
const env = isDev() ? a0_0x51d1('0x15', 'A0zv') : a0_0x51d1('0x4', 'Jqkq');
let basicToken;
if (env !== a0_0x51d1('0x1', 'D$Sp')) {
    basicToken = a0_0x51d1('0x9', 'sOz2');
} else {
    basicToken = a0_0x51d1('0xc', 'RMUh');
}
export const BASIC_TOKEN = basicToken;
let stripeKey;
if (env !== a0_0x51d1('0x2', 'Kfw!'))
    stripeKey = a0_0x51d1('0x6', '#lsQ');
else
    stripeKey = a0_0x51d1('0x16', '!Qe!');
export const STRIPE_KEY = stripeKey;
export const MELISSA_ID = a0_0x51d1('0x14', 'G901');
export const OPENGRAPH_API_KEY = a0_0x51d1('0xd', 'Jqkq');
export const LINKEDIN_API_KEY = {
    'id': a0_0x51d1('0x10', 'ouaG'),
    'secret': a0_0x51d1('0xe', 'G901')
};
export const GOOGLE_GEOCODING_API_KEY = a0_0x51d1('0x13', 'Y%6@');
export const GOOGLE_PLACES_API_KEY = a0_0x51d1('0x12', 'dKPu');
export const GOOGLE_TIMEZONE_API_KEY = a0_0x51d1('0x8', 'RC9s');
export const FCM_SERVER_KEY = a0_0x51d1('0xb', 'G901');
export const GCM_SENDER_ID = env !== a0_0x51d1('0x11', 'RMUh') ? a0_0x51d1('0x7', 'Kfw!') : a0_0x51d1('0x0', '#lsQ');
const ANDROID_WEBCLIENT_ID = a0_0x51d1('0xf', 'KJib');
const IOS_WEBCLIENT_ID = a0_0x51d1('0x3', 'udKu');
const IOS_CLIENT_ID = a0_0x51d1('0x5', 'Y%6@');
export const GOOGLE_CLIENT_IDS = {
    'android_webclient_id': ANDROID_WEBCLIENT_ID,
    'ios_webclient_id': IOS_WEBCLIENT_ID,
    'ios_client_id': IOS_CLIENT_ID
};
export const GOOGLE_REACT_API_KEY = a0_0x51d1('0xa', 'iJ7Z');