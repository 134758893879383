import moment from "moment";
import { DEFAULT_GROUP_MEMBER_FILTER_OPTIONS } from "../constants/DefaultFilters";

// make the set csv structured
const formatSet = (set) => {
  let arr = Array.from(set);
  return arr.join(',');
}

// get time from slug
const getDate = (slug) => {
  const today = moment();
  let days;
  if (slug === 'this-year') days = 365;
  else if (slug === 'today') days = 0;
  else {
    const match = slug.match(/\d+/); // Find the first sequence of digits in the string
    days = match ? parseInt(match[0], 10) : null; 
  }
  return today.subtract(days, 'days').format('YYYY-MM-DD');
}

const groupMemberSearchURLFormatter = (groupOptions, groupId) => {
  let {
    joinedTeamRWB,
    lastCheckIn,
    eventCheckInsYTD,
    memberStatus,
    militaryBranch,
    militaryStatus,
    isAnchorMember,
    isEagleLeader,
  } = groupOptions;
  let urlOptions = [];
  memberStatus = formatSet(memberStatus);
  militaryBranch = formatSet(militaryBranch);
  militaryStatus = formatSet(militaryStatus);
  if (joinedTeamRWB && joinedTeamRWB !== DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.joinedTeamRWB) urlOptions.push(`joined_date=${getDate(joinedTeamRWB)}`); // string, YYYY-MM-DD format
  if (lastCheckIn && lastCheckIn !== DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.lastCheckIn) urlOptions.push(`check_in_after_date=${getDate(lastCheckIn)}`); // string, YYYY-MM-DD format
  if (eventCheckInsYTD && eventCheckInsYTD !== DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.eventCheckInsYTD) urlOptions.push(`check_in_frequency=${parseInt(eventCheckInsYTD)}`); // integer
  if (memberStatus) urlOptions.push(`member_status=${memberStatus}`); // string (new, act, ina, leg), does not seem to be csv supported
  if (militaryBranch) urlOptions.push(`branch=${militaryBranch}`); // string (Army Marine Corps, Navy, Air Force, Coast Guard, Space Force), muti-select handled as csv
  if (militaryStatus) urlOptions.push(`military_status=${militaryStatus}`); // string (Civilian, Active Duty, Reserve, Veteran, Guard), multi-select handled as csv
  if (isAnchorMember) urlOptions.push(`default_group=${groupId}`); // integer (group id)
  if (isEagleLeader) urlOptions.push(`is_eagle_leader=${isEagleLeader}`); // string ("true" or "false")
  if (!urlOptions.length) return '';
  urlOptions = `&${urlOptions.join('&')}`;
  return urlOptions;
}

export default groupMemberSearchURLFormatter;
