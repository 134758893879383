import React from 'react';
import { progressChart } from '../../../../shared/utils/ELSCalculations';

function ELSChart({ value, average, pending, horizontal = false }) {
    const values = progressChart(value, horizontal, average);
    let {
        radius,
        width,
        height,
        strokeWidth,
        circumference,
        offset,
        markerValue,
        markerX,
        markerY,
        lineX1,
        lineY1,
        lineX2,
        lineY2,
        viewBoxDimeions,
        averageRotation,
    } = values;

    const containerStyle = !horizontal ? { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' } : {display: 'block'};

    const textTransformation = !horizontal ? '' : 'rotate(90, 100, 80)';

    const heightModifier = 40;
    const rotationCenterX = width / 3;
    const rotationCenterY = height + heightModifier;
    const rotation = !horizontal ? '' : `rotate(-90, ${rotationCenterX}, ${rotationCenterY})`;

    return (
        <div style={containerStyle}>
            <svg width={width} height={height + heightModifier} viewBox={viewBoxDimeions} >
                {/* added rotation to g to support svg 1.1 (safari) */}
                <g transform={rotation}>
                {/* Background/default */}
                <path
                    d={`
            M ${strokeWidth / 2}, ${radius}
            A ${radius - strokeWidth / 2}, ${radius - strokeWidth / 2} 0 0, 1 ${width - strokeWidth / 2}, ${radius}
          `}
                    stroke="var(--grey20)"
                    strokeWidth={strokeWidth}
                    fill="none"
                />
                {/* left circle cap */}
                <circle cx={strokeWidth / 2} cy={radius} r={strokeWidth / 2} fill={value > 0 ? "var(--magenta)" : "var(--grey20)"} />
                {/* right circle cap */}
                <circle cx={width - strokeWidth / 2} cy={radius} r={strokeWidth / 2} fill="var(--grey20)" />
                {/* progress circle cap */}
                {value !== undefined &&
                <path
                    d={`
                        M ${strokeWidth / 2}, ${radius}
                        A ${radius - strokeWidth / 2}, ${radius - strokeWidth / 2} 0 0, 1 ${width - strokeWidth / 2}, ${radius}
                    `}
                    stroke="var(--magenta)"
                    strokeWidth={strokeWidth}
                    fill="none"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    strokeLinecap="round"
                />}

                {/* Average line */}
                {!horizontal && <line
                    x1={lineX1}
                    y1={lineY1}
                    x2={lineX2}
                    y2={lineY2}
                    stroke="var(--navy)"
                    strokeWidth="3"
                />}
                {/* Average text and value */}
                {!horizontal && <text
                    x={markerX + 50 * Math.cos(Math.PI * (1 - markerValue / 100))}
                    y={markerY - ((average > 70 ? 70 : 50) * Math.sin(Math.PI * (1 - markerValue / 100)))}
                    fontSize="15"
                    transform={`rotate(${averageRotation}, ${markerX}, ${markerY})`}
                    
                    >
                    <tspan
                        x={markerX + 5 * Math.cos(Math.PI * (1 - markerValue / 100))}
                        dy="0"
                        fill="var(--navy)"
                        textAnchor="middle"
                    >
                        Average
                    </tspan>
                    <tspan
                        x={markerX + 5 * Math.cos(Math.PI * (1 - markerValue / 100))}
                        dy="15"
                        fontWeight="bold"
                        fill="var(--navy)"
                        textAnchor="middle"
                    >
                        {average}
                    </tspan>
                </text>}
                {/* Score/Value display */}
                <text x={width / 2} y={height - 50} fontSize="40" fill="var(--magenta)" transform={textTransformation} textAnchor="middle">
                    {
                        horizontal
                            ? <tspan
                            key="1"
                            fontWeight="bold"
                            x={width / 2}
                            
                        >
                            {value}
                        </tspan>
                    : value !== undefined ?
                        [
                            <tspan
                                key="1"
                                fontWeight="bold"
                                x={width / 2}
                            >
                                {value}
                            </tspan>,

                            <tspan
                                key="2"
                                fontWeight="bold"
                                x={width / 2}
                                dy="50"
                            >
                                100
                            </tspan>
                        ]
                        :
                        [
                            <tspan
                                key="3"
                                fontWeight="bold"
                                x={width / 2}
                            >
                                {pending ? 'ELS Score' : 'Take ELS'}
                            </tspan>,
                            <tspan
                                key="4"
                                fontWeight="bold"
                                x={width / 2}
                                dy="50"
                            >
                                {pending ? 'Pending' : 'Assessment'}
                            </tspan>
                        ]
                    }
                </text>
                {/* Divider for score */}
                {value !== undefined && !horizontal &&
                    <line
                        x1={width / 2 - 40}
                        y1={height / 2 + 50}
                        x2={width / 2 + 40}
                        y2={height / 2 + 50}
                        stroke="var(--magenta)"
                        strokeWidth="6"
                    />}

                </g>
            </svg>
        </div>
    );
}

export default ELSChart;
