import React, {useState} from 'react';


/**
* The toggleable component for month options
*    @component
*    @param {string} text - displayed text
*    @param {boolean} selected - if the option has been selected or not
*    @param {function} onPress - the parent function called when the touchable has been pressed
*/
const MonthToggle = ({text, selected, onPress}) => {
    return (
        <div style={{height: 40, paddingLeft: 20, paddingRight: 20, margin: 10, borderRadius: 20,
            backgroundColor: selected ? 'var(--magenta)' : 'var(--grey40)', display: 'flex',
            alignItems: 'center', justifyContent: 'center', flex: '.4 .4 40%', cursor: 'pointer'}}
            onClick={onPress}
            >
            <p style={{fontSize: 12, fontWeight: 'bold', color: selected ? 'var(--white)' : 'var(--grey80)'}}>{text}</p>
        </div>
    )
}

/**
    The component that lists all the month options
    Params are todo, expecting the options but they most likely will not be simple strings.
    @component
*/
const MonthSelectables = ({options, onOptionSelected, selectedOption}) => {
    const handlePressed = (text) => {
        if (selectedOption === text) onOptionSelected(null);
        else onOptionSelected(text);
    }

    return (
        <div style={{flexDirection: 'row', display: 'flex', flex: 1, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
            {options.map((option) => {
                return <MonthToggle key={option.slug}
                text={option.display}
                selected={selectedOption === option.slug}
                onPress={() => onOptionSelected(option.slug) }/>
            })}
        </div>
    )
}

export default MonthSelectables;
