import React from 'react';
import { segmentText } from '../../../../shared/utils/ELSHelper';

const StyledText = ({ rawText }) => {
  const segments = segmentText(rawText);
  return (
    <span style={{display: 'inline'}}>
      {segments.map((segment, index) => {
        if (segment.isLink) {
          return (
            <a
              key={index}
              href={segment.linkURL.includes('members.teamrwb.org') ? `${window.location.origin}/${segment.linkURL.split('.org/')[1]}` : segment.linkURL} // this allows functionality to work on more than just members-teamrwb.org if we only have prod links
              style={{ color: 'var(--blue)', textDecoration: 'underline' }}
              target={segment.linkURL.includes('members.teamrwb.org') ? null : "_blank"}
              rel="noopener noreferrer"
            >
              {segment.text}
            </a>
          );
        } else {
          return (
            <span
              key={index}
              style={segment.isStyled ? { color: 'var(--navy)', fontWeight: 'bold', display: 'inline' } : {display: 'inline'}}
            >
              {segment.text}
            </span>
          );
        }
      })}
    </span>
  );
};

export default StyledText;
