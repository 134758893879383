import React from 'react';
import ChevronRightIcon from '../svgs/ChevronRightIcon';
import ELSChart from './ELSChart.react';
import styles from './ELSScoreBreakdown.module.css';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { formatTitle, heatlhMessagePicker } from '../../../../shared/utils/ELSHelper';
import StyledText from './SyledText.react';
import { logViewELSCategory } from '../../../../shared/models/Analytics';

export const ELSScoreBreakdown = ({title, average, score, lineGraphScores}) => {
    const displayTitle = formatTitle(title);
    const match = useRouteMatch();
    const history = useHistory();
    let lineGraphData = [];
    for (let i = 0; i < lineGraphScores.length; i++) {
        lineGraphData.push({
            total_score: lineGraphScores[i][title],
            survey_response_date: lineGraphScores[i]['survey_response_date'],
        });
    }

    return (
        <div className={styles.container} onClick={() => {
            logViewELSCategory(title);
            history.push({
                pathname: `${match.path}/health/${title.toLowerCase().split(' ').join('-')}`,
                title, score, average, lineGraphData,
            });
        }}>
            <ELSChart value={score} horizontal={true} pending={false} />
            <div>
                <span className={styles.elsSpan}>
                    <h3>{displayTitle}</h3>
                    <ChevronRightIcon />
                </span>
                <br />
                <StyledText rawText={heatlhMessagePicker(title, score, average)}/>
            </div>
        </div>
    )
}