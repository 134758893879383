import { RRule } from 'rrule';

/**
 * Provides an easy-to-use, hard-to-misuse interface for using the rrule
 * library's RRule constructor.
 *
 * @example
 * const rRule = new RRuleBuilder({
 *   frequency: RRule.DAILY,
 *   interval: 5,
 *   count: 12,
 *   dtstart: datetime(2024, 2, 9, 15, 0),
 * }).build();
 * const weeklyrRule = new RRuleBuilder({
 *   frequency: RRule.WEEKLY,
 *   interval: 5,
 *   count: 12,
 *   dtstart: datetime(2024, 2, 9, 15, 0),
 * }).withWeekdays(
 *   new Set([RRule.MONDAY, RRule.WEDNESDAY])
 * ).build();
 * const monthlyrRule = new RRuleBuilder({
 *   frequency: RRule.MONTHLY,
 *   interval: 5,
 *   count: 12,
 *   dtstart: datetime(2024, 2, 9, 15, 0),
 * }).withDayOfMonth(
 *   9
 * ).build();
 */
export class RRuleBuilder {
  /**
   * Construct the RRuleBuilder with some universal parameters.
   *
   * @param {Object} options -- Frequency, interval, count, and dtstart options.
   * @param {string} options.frequency -- One of RRule.DAILY, RRule.WEEKLY,
   *   RRULE.MONTHLY
   * @param {number} options.interval -- Interval between options.frequency
   *   occurances. With RRule.MONTHLY and interval=3, for example, this means
   *   'every third month.'
   * @param {number} options.count -- How many total occurances to build.
   * @param {Date} option.dtstart -- The first datetime of the recurrence rule.
   * @returns {RRuleBuilder} 
   */
  constructor({frequency, interval, count, dtstart}) {
    if ([frequency, interval, count, dtstart].some((value) => value == null)) {
      throw new Error(
        "frequency, interval, count, and dtstart must all be set in the constructor."
      );
    }
    this.frequency = frequency;
    this.interval = interval;
    this.count = count;
    this.dtstart = dtstart;
    this.extra_options = {};
    this.week_extras_set = false;
    this.month_extras_set = false;
  }

  assertSingleExtras() {
    if (this.month_extras_set) {
      throw new Error("month extras already set.");
    }
    if (this.week_extras_set) {
      throw new Error("week extras already set.");
    }
  }

  /**
   * Add rule for recurring on set days-of-week.
   * @param {Set} weekdays - Days of the week for recurrence to happen on, such
   *   as new Set([RRule.MONDAY, RRule.TUESDAY]);
   */
  withWeekdays(weekdays) {
    this.assertSingleExtras();
    this.extra_options = {
      byweekday: Array.from(weekdays),
    };
    this.week_extras_set = true;
  }

  /**
   * Add rule for last day of month.
   * @param {number} day - Day of the month for the recurrence to happen on.
   */
  withDayOfMonth(day) {
    this.assertSingleExtras();
    this.extra_options = {
      bymonthday: day
    };
    this.month_extras_set = true;
  }

  /**
   * Add rule for last day of month.
   */
  withLastOfMonth() {
    this.withDayOfMonth(-1);
  }

  /**
   * Add rule for nth weekday of month.
   * @param {number} n - Which week of the month to set recurrence for.
   * @param {string} weekday - An RRule weekday instance, like RRule.FRIDAY.
   */
  withNthWeekdayOfMonth(n, weekday) {
    this.assertSingleExtras();
    this.extra_options = {
      bysetpos: n,
      byweekday: weekday, 
    };
    this.month_extras_set = true;
  }

  /**
   * Add rule for last weekday of month.
   * @param {string} weekday -- An RRule weekday instance, like RRule.FRIDAY.
   */
  withLastWeekdayOfMonth(weekday) {
    this.withNthWeekdayOfMonth(-1, weekday);
  }

  /**
   * Build the final RRule object.
   * @returns {RRule} the finished RRule object.
   */
  build() {
    
    if (this.frequency === RRule.WEEKLY && !this.week_extras_set) {
      throw new Error(
        "When using WEEKLY frequency, withWeekdays must be called."
      );
    }

    if (this.frequency === RRule.MONTHLY && !this.month_extras_set) {
      throw new Error(
        "When using MONTHLY frequency, you must build with exactly one extra month-rule."
      );
    }
    
    const base_options = {
      freq: this.frequency,
      interval: this.interval,
      count: this.count,
      dtstart: this.dtstart,
    };
    const options = {...base_options, ...this.extra_options};

    return new RRule(options);
  }
};

