const PROFILE_TAB_LABELS = {
  EVENT_AND_WORKOUT_STATS: 'EVENTS & WORKOUT STATS',
  CHALLENGE_WORKOUT_LOGS: 'Challenge Workout Logs',
  CHALLENGE_BADGES: 'Challenge Badges',
  JOIN_CHALLENGES_EARN_BADGES: 'Join challenges to earn badges',
  ALL_TIME_CHALLENGES: 'CHALLENGES',
  YTD_EVENTS: 'EVENTS (YTD)',
  MTD_EVENTS: 'EVENTS (MTD)',
};

const FEED_TYPE = {
  POST: 'post',
  EVENT: 'event',
};

const FEED_TAGS = {
  EVERYONE: 'everyone',
};

// TODO remove _POST/_User from delete, edit, block
const FEED_ACTION_LABELS = {
  DELETE_POST: 'Delete',
  EDIT_POST: 'Edit',
  BLOCK_USER: 'Block',
  REPORT_POST: 'Report',
  REMOVE_POST_AND_REPORT: 'Remove & Report',
  DELETE_USER_AND_REPORT: 'Remove & Report User',
  PIN_POST: 'Pin Post',
  UNPIN_POST: 'Remove Pin',
};

const EL_MANAGEMENT_ACTION_LABELS = {
  VIEW_EDIT: 'View/Edit Eagle Leader Info',
  VIEW: 'View Eagle Leader Info',
  OFFBOARD: 'Offboard Eagle Leader',
  ONBOARD: 'Onboard Eagle Leader',
  REPORT: 'Report Member',
}

export {PROFILE_TAB_LABELS, FEED_TYPE, FEED_TAGS, FEED_ACTION_LABELS, EL_MANAGEMENT_ACTION_LABELS};
