import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import styles from './CreateEvent.module.css';
import Attendee from './Attendee';
import AddHost from '../svgs/AddHost';

const ChapterEventUserCard = ({title, userDetails, setHost, error}) => {
  return (
    <div>
      <div className={styles.chapterEventUserType}>
        <PersonIcon className={styles.icon} /> <div>{title}</div>
      </div> 
      {userDetails === null ? (
        <>
          <div className={styles.addHostContainer}>
            <AddHost className={styles.addHostIcon} height={64} width={64} />
            <p>Pick a Host</p>
          </div>
          {error ? (
            <p className="errorMessage">{error}</p>
          ) : null}
        </>
      ) : (
        <div className={styles.chapterEventUserCard}>
          <Attendee
            item={{...userDetails, public_profile: true}}
            onSelect={() => null}
          />
        </div>
      )}
   </div>
  );
};

export default ChapterEventUserCard;
