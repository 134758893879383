import {OPENGRAPH_API_KEY} from '../constants/APIKeys-obfuscated';
import {OPENGRAPH_API} from '../constants/URLs';
import { openGraphFallbackPreview } from '../utils/Helpers';

export default {
  get: async (siteURL) => {
    try {
        const openGraphResponse = await fetch(
            `${OPENGRAPH_API}/${encodeURIComponent(
            siteURL,
            )}?app_id=${OPENGRAPH_API_KEY}`,
        );
        let openGraphData = await openGraphResponse.json();
        // Test data to avoid extra api calls
        // const openGraphData = {
        //   openGraph: {
        //     url: siteURL,
        //     description: 'Team RWB members website. Browse and create events.',
        //     title: 'Team RWB',
        //     image: 'https://3cbxzh2rx7n62al7t441d3je-wpengine.netdna-ssl.com/wp-content/uploads/2019/09/Default_Event_Image_9.12.19.jpg'
        //   }
        // }
        if (openGraphData.error) {
          openGraphData = openGraphFallbackPreview(siteURL);
        }
        return openGraphData;
    } catch (error) {
      throw error;
    }
  },
};