import React, { useState, useEffect } from 'react';
import { lineGraph } from '../../../../shared/utils/ELSCalculations';

const ELSLineGraph = ({ data }) => {
  const [graphWidth, setGraphWidth] = useState(340); // initial width - margin left - margin right
  
  const lineGraphData = lineGraph(data, graphWidth);

  const svgRef = React.createRef(null);
  
  useEffect(() => {
    const svg = svgRef.current;
    if (svg) {
      const rect = svg.getBoundingClientRect();
      setGraphWidth(rect.width - lineGraphData.margin.left - lineGraphData.margin.right);
    }
  }, [svgRef, lineGraphData]);

  const ticks = lineGraphData.tickRange.map(t => (
    <g key={t} transform={`translate(0,${lineGraphData.yScale(t)})`}>
      <text x="-10" y="5" stroke="var(--grey20)" fill="var(--grey20)" textAnchor="end">{t}</text>
    </g>
  ));

  return (
    <svg width={'100%'} height={lineGraphData.height - 25} ref={svgRef}>
      <g transform={`translate(${lineGraphData.margin.left + 10},${lineGraphData.margin.top})`}>
        <path d={`M${lineGraphData.line}`} stroke="var(--navy)" strokeWidth="4" fill="none" />
        {lineGraphData.modifiedData.map((d, i) => (
          <g key={i} transform={`translate(${lineGraphData.xScale(i)},${lineGraphData.yScale(d.total_score)})`}>
            <text x="-10" y="-15" fontSize="16" fontWeight="bold" stroke="var(--magenta)">{d.total_score}</text>
            <circle r="4" fill="var(--white)" strokeWidth="3" stroke="var(--navy)" />
            <text x="5" y="30" fontSize="14" stroke="var(--grey80)">
              <tspan x="1" style={{ textAnchor: "middle" }}>{d.month}</tspan>
              <tspan x="1" dy="1.2em" style={{ textAnchor: "middle" }}>{d.year}</tspan>
            </text>
          </g>
        ))}

      </g>
      <g transform={`translate(${lineGraphData.margin.left},${lineGraphData.margin.top})`}>
        {ticks}
      </g>
    </svg>
  );
};

export default ELSLineGraph;
