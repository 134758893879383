import React from 'react';
import styles from './FormattedPostText.module.css';
import {
  separatePunctuation,
  lastCharSymbolRegex,
  getFormattedPostElements,
} from '../../../../shared/utils/FeedHelpers';
import HyperLink from '../links/HyperLink';
import {FEED_TAGS} from '../../../../shared/constants/Labels';

const validPunctuation = [',', '.', '!', '?'];
const NON_USER_TAGS = [FEED_TAGS.EVERYONE];

const FormattedPostText = ({
  text,
  tagged,
  linkableUsers,
  links = [],
  history,
  clickable,
}) => {
  const nonUserTags = NON_USER_TAGS.filter(
    (tag) => text && text.indexOf(`@${tag}`) > -1,
  );
  if (!tagged?.length && !links?.length && !nonUserTags?.length) {
    return (
      <div className={styles.container}>
        <p className={`bodyCopy ${styles.postText} ${styles.overflowHider}`}>{text}</p>
      </div>
    );
  }
  let expectedNames = [];
  for (let i = 0; i < tagged?.length; i++) {
    expectedNames.push(`${tagged[i]?.first_name} ${tagged[i]?.last_name}`);
  }

  const renderNonUserTags = (word, ind) => {
    return (
      <h3
        key={ind}
        className={styles.fullName}
        style={{cursor: 'default'}}
        >
        {/* avoid adding a space if the value after the name is a punctuation mark */}
        {`${word}`}
      </h3>
    )
  }

  const renderFormattedUsername = (formattedName, namesAreLinks) => {
    const userId = tagged[expectedNames.indexOf(formattedName)].id;
    return (
      <h3
        key={userId}
        className={styles.fullName}
        onClick={() =>
          clickable && history.push(`/profile/${userId}`)
        }>
        {/* avoid adding a space if the value after the name is a punctuation mark */}
        {`${formattedName}`}
      </h3>
    )
  };

  const renderHyperLink = (link) => {
    return <HyperLink key={link} link={link}/>
  }

  return (
    <div className={`bodyCopy ${styles.container} ${styles.overflowHider}`}>
      {getFormattedPostElements(text, expectedNames, linkableUsers, renderFormattedUsername, links, renderHyperLink, nonUserTags, renderNonUserTags)}
    </div>
  );
};

export default FormattedPostText;
