import * as React from "react"
import PropTypes from 'prop-types';

const AddHost = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 42 42",
    }}
    viewBox="0 0 42 42"
    {...props}
  >
    <g>
      <circle
        cx={21}
        cy={21}
        r={21}
        style={{
          fill: "none",
        }}
      />
      <circle
        cx={21}
        cy={21}
        r={20}
        style={{
          fill: "none",
          stroke: props.tintColor,
          strokeWidth: 2,
        }}
      />
    </g>
    <g
      style={{
        enableBackground: "new",
      }}
    >
      <path
        d="M9569.7 17280.2c0 .6-.5 1.1-1.1 1.1h-4.6v4.6c0 .6-.5 1.1-1.1 1.1h-2.1c-.6 0-1.1-.5-1.1-1.1v-4.6h-4.6c-.6 0-1.1-.5-1.1-1.1v-2.1c0-.6.5-1.1 1.1-1.1h4.6v-4.6c0-.6.5-1.1 1.1-1.1h2.1c.6 0 1.1.5 1.1 1.1v4.6h4.6c.6 0 1.1.5 1.1 1.1v2.1z"
        style={{
          fill: props.tintColor,
        }}
        transform="translate(-9541 -17258)"
      />
    </g>
  </svg>
)

AddHost.defaultProps = {
  width: 24,
  height: 24,
  tintColor: "#bf0d3e",
};

AddHost.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tintColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default AddHost
