'use strict';

const status_radio_props = [
    { label: 'Veteran', value: 'Veteran' },
    { label: 'Active Duty', value: 'Active Duty' },
    { label: 'Guard', value: 'Guard' },
    { label: 'Reserve', value: 'Reserve' },
    { label: 'Civilian', value: 'Civilian' }
];

const branch_radio_props = [
    { label: 'Air Force', value: 'Air Force' },
    { label: 'Army', value: 'Army' },
    { label: 'Coast Guard', value: 'Coast Guard' },
    { label: 'Marine Corps', value: 'Marine Corps' },
    { label: 'Navy', value: 'Navy' },
    { label: 'Space Force', value: 'Space Force'},
];

const disability_radio_props = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

const zone_radio_props = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

const deployment_radio_props = [
    { label: 'World War II', value: 'World War II' },
    { label: 'Korean War', value: 'Korean War' },
    { label: 'Vietnam War', value: 'Vietnam War' },
    { label: 'Dominican Republic', value: 'Dominican Republic' },
    { label: 'Lebanon', value: 'Lebanon' },
    { label: 'Grenada', value: 'Grenada' },
    { label: 'Panama', value: 'Panama' },
    { label: 'Gulf War', value: 'Gulf War' },
    { label: 'Somalia', value: 'Somalia' },
    { label: 'Haiti', value: 'Haiti' },
    { label: 'Bosnia', value: 'Bosnia' },
    { label: 'Kosovo', value: 'Kosovo' },
    { label: 'Afghanistan', value: 'Afghanistan' },
    { label: 'Iraq', value: 'Iraq' },
    { label: 'Other', value: 'Other' }
];

const military_props = {
    status_radio_props,
    branch_radio_props,
    disability_radio_props,
    zone_radio_props,
    deployment_radio_props,
};


const address_type_radio_props = [
    { label: 'United States', value: 'domestic' },
    { label: 'International', value: 'international' }
];

const gender_radio_props = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Undisclosed', value: 'Undisclosed' }
];

const personal_info_props = {
    address_type_radio_props,
    gender_radio_props,
};


const anon_radio_props = [
    { label: "Public Profile: Embrace the power of community! By joining the ranks, you unlock a network of support, accountability, and inspiration. Share your name and social profile with fellow Eagles within the app and enjoy full access to all features. Together, we soar higher. Let's go!", value: false },
    { label: "Private Profile: Choosing to fly solo can clip your wings, limiting your access to essential social and event creation features. While it's your call, remember that going it alone means missing out on the full support and connection our community offers. Your name and social profile will not be visible to other Eagles and your ability to post, follow, and engage with others will be restricted.", value: true },
];

const anonymity_props = {
    anon_radio_props,
};

export { 
    military_props as MILITARY_PROPS,
    personal_info_props as PERSONAL_INFO_PROPS,
    anonymity_props as ANON_PROPS,
};
