import { IconButton, Paper, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DEFAULT_GROUP_MEMBER_FILTER_OPTIONS } from '../../../../shared/constants/DefaultFilters';
import { EVENT_CHECK_INS_YTD, JOINED_TEAM_RWB, LAST_CHECK_IN, MEMBER_FILTER_LABELS, MEMBER_STATUSES } from '../../../../shared/constants/GroupMemberFilterOptions';
import { MILITARY_PROPS } from '../../../../shared/constants/RadioProps';
import CustomPicker from '../events/CustomPicker';
import RWBButton from '../RWBButton';
import XIcon from '../svgs/XIcon';
import styles from './GroupFilters.module.css';
import Lozenge from './Lozenge';

const GroupFilters = ({hideFilters, groupMemberFilters, onSubmit, isAdminOrModerator}) => {
    const [joinedTeamRWBIsOpen, setJoinedTeamRWBIsOpen] = useState(false);
    const [lastCheckedInIsOpen, setLastCheckedInIsOpen] = useState(false);
    const [eventCheckInsYTDIsOpen, setEventCheckInsYTDIsOpen] = useState(false);
    const [groupMemberFilterOptions, setGroupMemberFilterOptions] = useState({
        joinedTeamRWB: groupMemberFilters.joinedTeamRWB,
        lastCheckIn: groupMemberFilters.lastCheckIn,
        eventCheckInsYTD: groupMemberFilters.eventCheckInsYTD,
        memberStatus: new Set(groupMemberFilters.memberStatus),
        militaryBranch: new Set(groupMemberFilters.militaryBranch),
        militaryStatus: new Set(groupMemberFilters.militaryStatus),
        isAnchorMember: groupMemberFilters.isAnchorMember,
        isEagleLeader: groupMemberFilters.isEagleLeader,
    });

    const handleToggleOption = (optionName) => {
        setGroupMemberFilterOptions(prevOptions => ({
            ...prevOptions,
            [optionName]: !prevOptions[optionName],
        }));
    };

    const handleSelectOption = (optionName, value) => {
        setGroupMemberFilterOptions(prevOptions => ({
            ...prevOptions,
            [optionName]: value,
        }));
    };

    const handleLozengeClick = (category, value) => {
        setGroupMemberFilterOptions(prevOptions => {
            const updatedSet = new Set(prevOptions[category]);
            if (updatedSet.has(value)) {
                updatedSet.delete(value);
            } else {
                updatedSet.add(value);
            }
            return { ...prevOptions, [category]: updatedSet };
        });
    };

    const handleSubmit = () => {
        onSubmit(groupMemberFilterOptions);
        hideFilters();
    };

    const resetFilter = () => {
        setGroupMemberFilterOptions({
            ...DEFAULT_GROUP_MEMBER_FILTER_OPTIONS,
        });
        setJoinedTeamRWBIsOpen(false);
        setLastCheckedInIsOpen(false);
        setEventCheckInsYTDIsOpen(false);
    };

    useEffect(() => {
        //This stops the page from scrolling when you open this modal. On web if you scroll the page might refresh when you hit the bottom of the scroll.
        //We might want this else where in the app or find a diffrent way to style the modals to stop the scrolling.
        const originalStyle = window.getComputedStyle(document.body).overflow;  
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = originalStyle;
    }, []);
  return (
    <div className={styles.container}>
        <Paper className={styles.root}>
            <Toolbar className={styles.toolbar}>
                <div onClick={() => hideFilters()}>
                    <IconButton className={styles.menuButton} color="inherit">
                        <XIcon tintColor={'var(--white)'} />
                    </IconButton>
                </div>
                <Typography variant="h6" className={styles.title}>
                    Event Filters
                </Typography>
                <IconButton
                    edge="end"
                    className={styles.menuButton}
                    color="inherit"
                    onClick={() => resetFilter()}>
                    <Typography variant="h6" className={styles.reset}>
                        Reset
                    </Typography>
                </IconButton>
            </Toolbar>
        </Paper>
        <div className={styles.contentScroll}>
        <div className={styles.contentContainer}>
            <CustomPicker
                title={MEMBER_FILTER_LABELS.JOINED_TEAM_RWB}
                isOpen={joinedTeamRWBIsOpen}
                items={JOINED_TEAM_RWB}
                selectedValue={groupMemberFilterOptions.joinedTeamRWB}
                pickHandler={() => setJoinedTeamRWBIsOpen(prev => !prev)}
                onSelect={value => handleSelectOption('joinedTeamRWB', value)}
            />
            {isAdminOrModerator && <CustomPicker
                title={MEMBER_FILTER_LABELS.LAST_CHECK_IN}
                isOpen={lastCheckedInIsOpen}
                items={LAST_CHECK_IN}
                selectedValue={groupMemberFilterOptions.lastCheckIn}
                pickHandler={() => setLastCheckedInIsOpen(prev => !prev)}
                onSelect={value => handleSelectOption('lastCheckIn', value)}
            />}
            {isAdminOrModerator && <CustomPicker
                title={MEMBER_FILTER_LABELS.EVENT_CHECK_INS_YTD}
                isOpen={eventCheckInsYTDIsOpen}
                items={EVENT_CHECK_INS_YTD}
                selectedValue={groupMemberFilterOptions.eventCheckInsYTD}
                pickHandler={() => setEventCheckInsYTDIsOpen(prev => !prev)}
                onSelect={value => handleSelectOption('eventCheckInsYTD', value)}
            />}
            {isAdminOrModerator && <>
                <p className={`formLabel ${styles.label}`}>{MEMBER_FILTER_LABELS.MEMBER_STATUS}</p>
                <div className={`formSection ${styles.memberStatus}`}>
                    {Object.entries(MEMBER_STATUSES).map(([slug, { display, value }]) => (
                        <Lozenge
                            key={value}
                            slug={slug}
                            display={display}
                            onClick={() => handleLozengeClick('memberStatus', value)}
                            selected={groupMemberFilterOptions.memberStatus.has(value)}
                        />
                    ))}
                </div>
            </>}
            <p className={`formLabel ${styles.label}`}>{MEMBER_FILTER_LABELS.MILITARY_BRANCH}</p>
            <div className={`formSection ${styles.militaryBranch}`}>
                {MILITARY_PROPS.branch_radio_props.map((branch) => (
                    <Lozenge
                        key={branch.value}
                        slug={branch.value}
                        display={branch.label}
                        onClick={() => handleLozengeClick('militaryBranch', branch.value)}
                        selected={groupMemberFilterOptions.militaryBranch.has(branch.value)}
                    />
                ))}
            </div>
            <p className={`formLabel ${styles.label}`}>{MEMBER_FILTER_LABELS.MILITARY_STATUS}</p>
            <div className={`formSection ${styles.militaryStatus}`}>
                {MILITARY_PROPS.status_radio_props.map((status) => (
                    <Lozenge
                        key={status.value}
                        slug={status.value}
                        display={status.label}
                        onClick={() => handleLozengeClick('militaryStatus', status.value)}
                        selected={groupMemberFilterOptions.militaryStatus.has(status.value)}
                    />
                ))}
            </div>
            {isAdminOrModerator && <>
                <p className={`formLabel ${styles.label}`}>{MEMBER_FILTER_LABELS.ANCHOR_MEMBERS}</p>
                <label className={styles.toggleSwitch} htmlFor="anchorMemberCheckbox"> {/* Add htmlFor here */}
                    <input
                        type="checkbox"
                        id="anchorMemberCheckbox" // This id should be unique to each checkbox
                        checked={groupMemberFilterOptions.isAnchorMember}
                        onChange={() => handleToggleOption('isAnchorMember')} />
                    <span className={styles.slider}></span>
                    <p className={`${styles.sliderLabel}`}>Member has this Chapter group set as their Anchor Chapter group.</p>
                </label>
                <p className={`formLabel ${styles.label}`}>{MEMBER_FILTER_LABELS.EAGLE_LEADER}</p>
                <label className={styles.toggleSwitch}>
                    <input type="checkbox"
                            checked={groupMemberFilterOptions.isEagleLeader}
                            onChange={() => handleToggleOption('isEagleLeader')} />
                    <span className={styles.slider}></span>
                    <p className={`${styles.sliderLabel}`}>Member is an Eagle Leader.</p>
                </label>
            </>}
            <div className={styles.buttonContainer} onClick={handleSubmit}>
                <RWBButton 
                    label={'Apply'} buttonStyle={'primary'} 
                />
            </div>
            </div>
        </div>
    </div>
  );
};

export default GroupFilters;
