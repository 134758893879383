'use strict';

const extensions = {
    jpeg: 'jpeg',
    jpg: 'jpg',
    png: 'png',
    gif: 'gif',
    heif: 'heif', //apple still photo
    heic: 'heic',
    plist: 'plist', //apple's way of saving edited photos on iOS 13+
};

const mimetypes = {
    jpg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    heic: 'image/heic',
    heicSequence: 'image/heic-sequence'
};

const MAX_HEIGHTS = {
    web: 180,
    mobile: 120
}

const MAX_WIDTHS = {
    web: 320,
    mobile: 213
}

const webMimes = 'image/png, image/jpeg, image/webp';

export { extensions as EXTENSIONS, mimetypes as MIMETYPES, webMimes as WEB_MIMES, MAX_HEIGHTS as MAX_IMAGE_HEIGHTS, MAX_WIDTHS as MAX_IMAGE_WIDTHS};
