'use strict';

export const HEADER = `LIABILITY WAIVER & ACKNOWLEDGEMENT`;
export const SUBHEADER = `(THIS DOCUMENT AFFECTS YOUR LEGAL RIGHTS. YOU MUST READ AND UNDERSTAND BEFORE SUBMITTING)`;
export const INTRODUCTION = `This document governs your participation in Team RWB Activities.`;

export const INCLUSIVITY = `Team RWB is an inclusive organization, and attempts to deliver varying events and activities, both at the local and national level, that accommodate all physical abilities and fitness levels. It is the responsibility of individual participants to work with appropriate medical professionals to determine which activities and events best align with their abilities and fitness levels.`;

export const MEDICAL = ` I certify that I am medically and physically capable to participate in Team RWB events, including any and all associated training and development activities during Team RWB Physical Fitness Events and/or Team RWB Social and Community Service Events (collectively, “Team RWB Activities”), and have taken into consideration any potential limitations including those communicated by medical personnel. My medical and/or physical condition will not endanger myself or others if I participate in Team RWB activities. As stated below, participation in Physical Fitness Activities presents an inherent risk of injury to person and property. I certify that, to the best of my knowledge, i will take into consideration any known medical or physical conditions before agreeing to participate in Team RWB activities. Additionally, I certify that I have primary medical insurance in place to cover any expenses related to any potential injury that may arise from my participation in Team RWB Activities.`;

export const INDEMNIFICATION = ` In consideration of the services provided by Team RWB or any of its affiliates and the right to engage in Team RWB Activities as a licensee of Team RWB, I acknowledge, agree, promise and covenant with Team RWB, its agents, employees, members, managers, officers, directors, volunteers, sponsors, organizers, independent contractors, affiliates, and any promoting organizations, medical providers, property owners, law enforcement agencies, public entities, and special districts (and their respective shareholders, agents, officials and employees) (collectively, the “RELEASEES”) through or by which the Physical Fitness Activities will be held, on behalf of myself, my heirs, assigns, legal representatives, executors, administrators, successors in interest, estate, and next of kin (hereinafter collectively my “Successors”) as follows:`;

export const INDEMN_CLAUSE_1 = `1. I HAVE AGREED TO PARTICIPATE IN A STRENUOUS PHYSICAL FITNESS OR COMMUNITY SERVICE EVENT. Team RWB Activities include athletic training and development in specific types of endurance events: long-distance swimming, cycling, hiking, and running, including, but not limited to, cross training such as calisthenics, free-body muscular development, weight training, and flexibility development/stretching. These activities also may include, but not limited to, export construction activities, loading and unloading of heavy equipment and materials, operation of machinery, and local transportation to and from event sites. I know and understand that there is risk of injury inherent in all Team RWB events, including the potential for physical or mental injury, illness, disease, permanent paralysis, death, and property damage, to others or myself, and while particular safety practices, equipment, and personal discipline may reduce this risk, such risk may not be eliminated;`;
export const INDEMN_CLAUSE_2 = `2. I KNOWINGLY AND FREELY ASSUME ALL SUCH RISKS FROM TEAM RWB ACTIVITIES, BOTH KNOWN OR UNKNOWN, IDENTIFIED OR UNIDENTIFIED, ANTICIPATED OR UNANTICIPATED, EVEN IF ARISING FROM THE NEGLIGENCE OF ANY RELEASEE OR OTHERS, AND I ASSUME FULL RESPONSIBILITY FOR MY PARTICIPATION;`;
export const INDEMN_CLAUSE_3 = `3. I consent to the release (by Team RWB and any third party) to RELEASEES and insurance carriers of my name and medical information that relate solely to any injury or death I may suffer arising from any of Team RWB Activities;`;
export const INDEMN_CLAUSE_4 = `4. I willingly agree to comply with the stated and customary terms and conditions for Team RWB Activities;`;
export const INDEMN_CLAUSE_5 = `5. At all times, I will exercise due caution and abide by the practices and principles of fitness safety;`;
export const INDEMN_CLAUSE_6 = `6. I hereby consent to receive medical treatment, which may be deemed advisable in the event of injury, accident, or illness during my participation in Team RWB Activities;`;
export const INDEMN_CLAUSE_7 = `7. I understand that still and video images of me may be taken in conjunction with Team RWB Activities and agree that they may be used to promote Team RWB;`;
export const INDEMN_CLAUSE_8 = `8. At all times, I will wear proper safety equipment and clothing based on customary practices. If I participate in a cycling event, I will wear a helmet certified by ANSI SNELL, CPSC, or DOT performance tests and that can protect me against serious head injury, and assume all responsibility and liability for the selection of such a helmet; and`;
export const INDEMN_CLAUSE_9 = `9. I accept responsibility for the condition and adequacy of my equipment.`;
export const INDEMN_CLAUSE_10 = `10. In consideration of my use of equipment owned by Team RWB, I expressly agree and contract, on behalf of myself and my heirs, that Team RWB shall not be liable for any damages arising from personal injuries (including death) sustained by me, as a result of the use of the equipment.`;

export const RESPONSABILITY = `RELEASE AND ACCEPTANCE OF RISK AND RESPONSIBILITY: I HAVE READ AND FULLY UNDERSTAND THE TERMS OF THIS LIABILITY WAIVER & ACKNOWLEDGEMENT. I, FOR MYSELF AND MY SUCCESSORS, RELEASE AND HOLD HARMLESS THE RELEASEES WITH RESPECT TO, AND VOLUNTARILY AGREE, COVENANT, AND PROMISE TO ACCEPT AND ASSUME ALL RESPONSIBILITIES FOR ANY AND ALL INJURY, DISABILITY, ILLNESS, DISEASE, DEATH, PARALYSIS, LOSS, OR DAMAGE TO PERSON OR PROPERTY, ARISING FROM MY PARTICIPATION IN TEAM RWB ACTIVITIES, WHETHER OR NOT CAUSED BY THE SOLE, CONCURRENT, OR CONTRIBUTORY NEGLIGENCE OF THE RELEASEES OR OTHERWISE. I, FOR MYSELF AND MY SUCCESSORS, HEREBY INDEMNIFY AND HOLD HARMLESS THE RELEASEES FROM ANY DAMAGES, FEES, OR EXPENSES ARISING FROM ANY CLAIM ASSERTED IN CONTRAVENTION OF THIS LIABILITY WAIVER & ACKNOWLEDGEMENT, INCLUDING BUT NOT LIMITED TO ALL REASONABLE AND NECESSARY ATTORNEYS’ FEES. I UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL LEGAL RIGHTS BY SIGNING THIS LIABILITY WAIVER & ACKNOWLEDGEMENT, INCLUDING, BUT NOT LIMITED TO, RIGHTS ARISING FROM OR CLAIMS FOR THE ACTS, OMISSIONS, FAULT, OR NEGLIGENCE IN ANY DEGREE OF THE RELEASEES, AND I SIGN IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT OR ANYONE FORCING ME TO PARTICIPATE IN SPITE OF THE RISKS.`;

export const ENTIRETY = ` I understand that this is the entire agreement between Team RWB and me, that it is for the benefit of all RELEASEES, and that it cannot be modified or changed in any way by the representations of Team RWB or any employee or agent of Team RWB or any of the RELEASEES or by me. This Liability Waiver & Acknowledgment may not be modified orally.`;

export const NO_WAIVER = ` A waiver or modification of any provision shall not be export construed as a modification of any other provision herein or as a consent to any subsequent waiver or modification.`;

export const SEVERABILITY = ` Every term and provision of this Liability Waiver & Acknowledgement is severable. If any one or more of them is found to be unenforceable or invalid, that shall not affect the other terms and provisions which shall remain binding and enforceable. My signature below indicates that I have read this entire document, understand it completely, and agree to be bound by its terms.`;

export const MINORITY_CONSENT = `This is to certify that I, as parent/guardian with legal responsibility for this participant of minority age, do consent and agree to my child’s or ward’s participation in Team RWB Activities and further agree individually and on behalf of my child or ward to all of the terms of this Liability Waiver & Acknowledgment as provided above, and, for myself and my Successors, I release and agree to indemnify the RELEASEES from any and all liabilities incident to my minor child’s or ward’s involvement or participation in Team RWB Activities as provided above, EVEN IF ARISING FROM THE NEGLIGENCE OF RELEASEES OR OTHERWISE.`;
