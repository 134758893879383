/**
 * Exports a singleton containing getters and setters for Team RWB Event search filters
 */

import AsyncStorage from '@react-native-async-storage/async-storage';
import {
    DEFAULT_GROUP_MEMBER_FILTER_OPTIONS,
} from '../../shared/constants/DefaultFilters';
import { convertObjSetsToArrays } from '../../shared/utils/Helpers';
const STORED_KEY = 'group-member-filters';
class GroupMemberFilters {
    hasRetrieved = false;
    joinedTeamRWB = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.joinedTeamRWB;
    lastCheckIn = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.lastCheckIn;
    eventCheckInsYTD = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.eventCheckInsYTD;
    memberStatus = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.memberStatus;
    militaryBranch = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.militaryBranch;
    militaryStatus = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.militaryStatus;
    isAnchorMember = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.isAnchorMember;
    isEagleLeader = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.isEagleLeader;

    _formatFilters = (source) => {
        this.joinedTeamRWB =
            source.joinedTeamRWB || this.joinedTeamRWB;
        this.lastCheckIn = source.lastCheckIn || this.lastCheckIn;
        this.eventCheckInsYTD = source.eventCheckInsYTD || this.eventCheckInsYTD;
        this.memberStatus =
            new Set(source.memberStatus) || this.memberStatus;
        this.militaryBranch =
            new Set(source.militaryBranch) || this.militaryBranch;
        this.militaryStatus =
            new Set(source.militaryStatus) || this.militaryStatus;
        this.isAnchorMember = source.isAnchorMember;
        this.isEagleLeader = source.isEagleLeader;
    }

    _retrieveFilters = () => {
        return AsyncStorage.getItem(STORED_KEY)
            .then(JSON.parse)
            .then((storedFilters) => {
                if (storedFilters) {
                    this._formatFilters(storedFilters);
                }
                this.hasRetrieved = true;
            })
            .catch((error) => { });
    };

    /**
     * Sets a new filter onto the singleton, then saves to AsyncStorage
     *
     * @param {object} newFilters Object containing new filters.
     */
    setFilter = (newFilters) => {
        this.getFilters().then((previousFilters) => {
            let assignedFilters = Object.assign(previousFilters, newFilters);
            // Sets must be stored as arrays and then converted to a set 
            assignedFilters = convertObjSetsToArrays(assignedFilters);
            AsyncStorage.setItem(STORED_KEY, JSON.stringify(assignedFilters))
                .then(() => {
                    this._formatFilters(assignedFilters);
                })
                .catch((error) => { });
        });
    };

    /**
     * Returns all saved filters, retrieving them from AsyncStorage if needed.
     *
     * @returns {object} Object with all saved filters
     */
    getFilters = () => {
        if (this.hasRetrieved) {
            const currentFilters = {
                joinedTeamRWB: this.joinedTeamRWB,
                lastCheckIn: this.lastCheckIn,
                eventCheckInsYTD: this.eventCheckInsYTD,
                memberStatus: this.memberStatus,
                militaryBranch: this.militaryBranch,
                militaryStatus: this.militaryStatus,
                isAnchorMember: this.isAnchorMember,
                isEagleLeader: this.isEagleLeader,
            };
            return new Promise((resolve) => {
                resolve(currentFilters);
            });
        } else {
            return this._retrieveFilters().then(this.getFilters);
        }
    };

    /**
     * Resets all saved search filters to default.
     *
     * @returns {void}
     */
    deleteFilters = () => {
        this.hasRetrieved = false;
        this.resetFilters();
        AsyncStorage.removeItem(STORED_KEY).catch((error) => { });
    };

    resetFilters = () => {
        this.joinedTeamRWB = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.joinedTeamRWB;
        this.lastCheckIn = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.lastCheckIn;
        this.eventCheckInsYTD = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.eventCheckInsYTD;
        this.memberStatus = new Set();
        this.militaryBranch = new Set();
        this.militaryStatus = new Set();
        this.isAnchorMember = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.isAnchorMember;
        this.isEagleLeader = DEFAULT_GROUP_MEMBER_FILTER_OPTIONS.isEagleLeader;
    };
}

export let groupMemberFiltersStorage = new GroupMemberFilters();
