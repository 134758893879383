import moment from 'moment';
import {RRule, rrulestr} from 'rrule';
import { RRuleBuilder } from './recurrenceRule';

const DAY_OPTIONS = [
    { display: 'Sunday', slug: RRule.SU },
    { display: 'Monday', slug: RRule.MO },
    { display: 'Tuesday', slug: RRule.TU },
    { display: 'Wednesday', slug: RRule.WE },
    { display: 'Thursday', slug: RRule.TH },
    { display: 'Friday', slug: RRule.FR },
    { display: 'Saturday', slug: RRule.SA }
];

const DAY_NAMES_MAP = {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
};

// Probably some simpler way to do this but this is fine.
const INTERVAL_OPTIONS = [
    {display: 1, slug: "1"},
    {display: 2, slug: "2"},
    {display: 3, slug: "3"},
    {display: 4, slug: "4"},
    {display: 5, slug: "5"},
    {display: 6, slug: "6"},
    {display: 7, slug: "7"},
    {display: 8, slug: "8"},
    {display: 9, slug: "9"},
    {display: 10, slug: "10"},
]

// Probably some simpler way to do this but this is fine.
const COUNT_OPTIONS = [
    {display: 1, slug: "1"},
    {display: 2, slug: "2"},
    {display: 3, slug: "3"},
    {display: 4, slug: "4"},
    {display: 5, slug: "5"},
    {display: 6, slug: "6"},
    {display: 7, slug: "7"},
    {display: 8, slug: "8"},
    {display: 9, slug: "9"},
    {display: 10, slug: "10"},
    {display: 11, slug: "11"},
    {display: 12, slug: "12"},
    {display: 13, slug: "13"},
    {display: 14, slug: "14"},
    {display: 15, slug: "15"},
]

const FREQUENCY_OPTIONS = [
    { display: 'DAY', slug: RRule.DAILY },
    { display: 'WEEK', slug: RRule.WEEKLY },
    { display: 'MONTH', slug: RRule.MONTHLY },
]

/**
 * Takes a datetime and outputs which week of the month it encodes.
 * @example
 * const myDate = moment('2014-01-20 15:00:00');
 * const nthOfMonth = nthDayOfMonth(myDate);
 * nthOfMonth === 3; // true
 *
 * @param {moment} datetime
 * @return {Number} 
 */
const nthDayOfMonth = (datetime) => {
    return Math.ceil(datetime.date() / 7);
}

const SAME_DAY = 'same-day';
const LAST_DAY = 'last-day';
const NTH_WEEKDAY = 'nth-weekday';
const LAST_WEEKDAY = 'last-weekday';

const generateMonthlyOptions = (datetime) => {
		const ordinal = [
			"1ST", "2ND", "3RD", "4TH", "5TH",
		][nthDayOfMonth(datetime) - 1];
    // isoWeekday starts on Monday, being day 1.
		const weekday = [
			"MONDAY",
			"TUESDAY",
			"WEDNESDAY",
			"THURSDAY",
			"FRIDAY",
			"SATURDAY",
			"SUNDAY",
		][datetime.isoWeekday() - 1];
		return [
			{slug: SAME_DAY, display: 'ON THE SAME DATE'},
			{slug: LAST_DAY, display: 'ON THE LAST DAY'},
			{slug: NTH_WEEKDAY, display: `EVERY ${ordinal} ${weekday}`},
			{slug: LAST_WEEKDAY, display: `EVERY LAST ${weekday}`},
		]
	}

/**
 * Takes a complete RRULE string and converts it into a string of formatted dates.
 *
 * @example
 * const builder = RRuleBuilder({
 *   frequency: RRule.DAILY,
 *   interval: 2,
 *   count: 3,
 *   dtstart: moment("2024-02-15 17:01:34")
 * });
 * const rRuleString = builder.build().toString();
 * const dates = getRecurrenceDates(rRuleString);
 * 
 * dates === '02/15/2024, 02/17/2024, 02/19/2024'; //true
 * 
 * @param {string} rruleString - The RRule to convert into a list of dates.
 * @return string - The formatted list.
 */
const getRecurrenceDates = (rRuleString) => {
  const rrule = rrulestr(rRuleString);
  const rruleMoments = rrule.all().map((d) => {
    // We use .utc here because in the rrule builder we made the dtstart utc.
    return moment.utc(d)
  });
  const formattedMoments = rruleMoments.map((m) => {return m.format('MM/DD/YYYY')});
  return formattedMoments.join(', ');
}

/**
 * Injects a specific time zone as `TZID` (time zone identifier) into an RRule string.
 *
 * @param {string} rule - The original RRule string to be modified.
 * @param {string} timeZone - The time zone identifier (e.g., "America/New_York") to be injected into the RRule.
 * timeZone should be "America/New_York" for all day virtual events, pulled from google for local events, and pulled from the user's device for other virtual events.
 * @returns {string} A modified RRule string with the specified time zone included.
 *
 * @example
 * originalRRule = "DTSTART:20240314T234500ZRRULE:FREQ=WEEKLY;INTERVAL=1;COUNT=1;BYDAY=TH";
 * timeZone = "America/New_York";
 * Expected output: "DTSTART;TZID=America/New_York:20240314T234500RRULE:FREQ=WEEKLY;INTERVAL=1;COUNT=1;BYDAY=TH"
 *
 */
const injectTimeZoneIntoRRule = (rule, timeZone) => {
  if (!rule) {
    return rule;
  }
  const ruleSplit = rule.split('DTSTART')[1]
  const ruleFragment = `${ruleSplit.slice(0,16)}${ruleSplit.slice(17)}`
  const completeRRule = `DTSTART;TZID=${timeZone}${ruleFragment}`
  return completeRRule
}

/**
 * Returns the ordinal suffix for a given day number.
 * and appends the appropriate ordinal suffix ('st', 'nd', 'rd', 'th') to the number depending on the day.
 *
 * @param {number} day - The day of the month as a number.
 * @returns {string} The day number followed by its ordinal suffix.
 */
function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1: return `${day}st`;
    case 2: return `${day}nd`;
    case 3: return `${day}rd`;
    default: return `${day}th`;
  }
}

/**
 * Converts an array of abbreviated day names into a comma-separated string of full day names.
 * This function relies on a predefined DAY_NAMES_MAP object that maps abbreviations to full names.
 *
 * @param {string[]} abbreviatedDays - An array of day abbreviations (e.g., ['MO', 'TU', 'WE']).
 * @returns {string} A string containing the full names of the days, separated by commas (e.g., "Monday, Tuesday, Wednesday").
 */
function getFullDayNames(abbreviatedDays) {
  return abbreviatedDays.map(dayAbbrev => DAY_NAMES_MAP[dayAbbrev]).join(', ');
}

/**
 * returns a descriptive string for the day of the month an event occurs.
 * This function handles positive numbers to specify exact dates, -1 to indicate the last day of the month,
 * and uses `bysetpos` to describe events occurring on the nth or last week within a month.
 *
 * @param {number} bymonthday - The day of the month on which the event repeats. Positive values indicate a specific date; -1 indicates the last day of the month.
 * @param {number} bysetpos - The week the evnet occurs (e.g., 1 for the first week of the month, -1 for the last) used with `fullDaysOfWeek` to specify weekly occurrences within a month.
 * @param {string} fullDaysOfWeek - A descriptive string of the full names of days of the week (e.g., "Monday, Wednesday").
 * @returns {string} A descriptive string indicating when the event occurs within a month.
 */
function generateMonthDayDescription(bymonthday, bysetpos, fullDaysOfWeek) {
  if (bymonthday) {
    if (bymonthday > 0) {
      return `on the ${getOrdinalSuffix(bymonthday)} of the month`;
    } else if (bymonthday === -1) {
      return "on the last day of the month";
    }
  } else if (bysetpos) {
    return bysetpos > 0 ? `on the ${getOrdinalSuffix(bysetpos)} ${fullDaysOfWeek} of the month` : 
           `on the last ${fullDaysOfWeek} of the month`;
  }
}


/**
* Get Recurrence Text - Returns a descriptive string displaying the recurrence and frequency of an event.
* @param {string} recurrenceRule - The RRule that is used to determine the frequency of a event. Containes:
*  - `freq`: The frequency of the event (e.g., '1' for daily, '2' for weekly, '3' for monthly).
*  - `interval`: The interval at which the event repeats within the specified frequency period.
*  - `byweekday`: (Optional) For weekly recurrences, specifies the days of the week on which the event occurs. Expected values are abbreviations like 'MO', 'TU', etc.
*  - `bymonthday`: (Optional) For monthly recurrences, specifies the day of the month the event occurs. Positive values indicate a specific date, '-1' indicates the last day of the month, and other negative values are not supported.
* @returns {string} A string that describes the recurrence pattern, or 'Does Not Repeat' if the conditions are not met.
*/
export const getRecurrenceText = (recurrenceRule) => {
  if (!recurrenceRule) {
    return 'Does Not Repeat';
  }

  const {
    freq,
    interval,
    byweekday,
    bymonthday,
    bysetpos
  } = RRule.fromString(recurrenceRule).origOptions;
  const [MONTH_SELECTED,WEEKS_SELECTED,DAYS_SELECTED] = [1, 2, 3];
  const fullDaysOfWeek = byweekday ? getFullDayNames(byweekday) : '';
  const bymonthdayDescription = generateMonthDayDescription(bymonthday, bysetpos, fullDaysOfWeek);
  if ((freq === WEEKS_SELECTED && !byweekday) ||
      (freq === null || interval === null)) {
    return 'Does Not Repeat';
  }
  else if (interval === 1) {
    if (freq === DAYS_SELECTED) {
      return 'Repeats Every Day';
    }
    else if (freq === WEEKS_SELECTED) {
      return `Repeats Every Week on ${fullDaysOfWeek}`;
    }
    else if (freq === MONTH_SELECTED) {
      return `Repeats Every Month ${bymonthdayDescription}`;
    }
  }
  else if (interval !== 1) {
    if (freq === DAYS_SELECTED) {
      return `Repeats Every ${interval} Days`;
    }
    else if (freq === WEEKS_SELECTED) {
      return `Repeats Every ${interval} Weeks on ${fullDaysOfWeek}`;
    }
    else if (freq === MONTH_SELECTED) {
      return `Repeats Every ${interval} Months ${bymonthdayDescription}`;
    }
  }
};

/**
 * Builds and returns an RRULE string based on the provided parameters.
 * 
 * @param {RRule.FREQUENCY} frequency - The frequency of the recurrence, using RRule constants (e.g., RRule.DAILY, RRule.WEEKLY).
 * @param {string} interval - The interval between each recurrence.
 * @param {string} count - The number of occurrences or until a specific end date.
 * @param {moment.Moment} date - The start date of the recurrence as a Moment object.
 * @param {Set} daySet - A set of selected days for the recurrence (applicable for weekly recurrences).
 * @param {string} monthlyOption - The option for handling monthly recurrences (e.g., same day, last day).
 * @param {Function} onComplete - Callback function to execute with the RRULE string as its argument upon successful configuration.
 */
 export const buildAndCompleteRRule = ( frequency, interval, count, date, daySet, monthlyOption, onComplete) => {
  const baseOptions = {
      frequency,
      interval,
      count,
      // This forcibly sets the date's timezone to UTC without performing any other conversions or localizations. This step is necessary because rrule later modifies the time when using .toString() or .all(). For example, "Tue Mar 12 2024 20:00:00 GMT-0400 (Eastern Daylight Time)" is converted to "DTSTART:20240313T000000Z".
      dtstart: date.tz('Etc/UTC', true).toDate(),
  };

  const rRuleBuilder = new RRuleBuilder(baseOptions);
  let weekday;

  if (frequency === RRule.WEEKLY) {
      rRuleBuilder.withWeekdays([...daySet]); // Ensure daySet is iterable (e.g., an array)
  }

  if (frequency === RRule.MONTHLY) {
      if (monthlyOption === SAME_DAY) {
          rRuleBuilder.withDayOfMonth(date.date());
      } else if (monthlyOption === LAST_DAY) {
          rRuleBuilder.withLastOfMonth();
      } else if (monthlyOption === NTH_WEEKDAY) {
          weekday = DAY_OPTIONS[date.day()].slug;
          rRuleBuilder.withNthWeekdayOfMonth(nthDayOfMonth(date), weekday);
      } else if (monthlyOption === LAST_WEEKDAY) {
          weekday = DAY_OPTIONS[date.day()].slug;
          rRuleBuilder.withLastWeekdayOfMonth(weekday);
      }
  }

  const rRule = rRuleBuilder.build();
  onComplete(rRule.toString());
};

export {
    nthDayOfMonth,
    generateMonthlyOptions,
    getRecurrenceDates,
    injectTimeZoneIntoRRule,
    COUNT_OPTIONS,
    DAY_OPTIONS,
    INTERVAL_OPTIONS,
    FREQUENCY_OPTIONS,
    SAME_DAY,
    LAST_DAY,
    NTH_WEEKDAY,
    LAST_WEEKDAY,
};
