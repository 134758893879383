import React, { useState } from 'react';

import RWBModal from '../generic/RWBModal';
import RWBButton from '../RWBButton';
import moment from 'moment-timezone';
import CustomPicker from './CustomPicker';
import {
    nthDayOfMonth,
	generateMonthlyOptions,
	DAY_OPTIONS,
	COUNT_OPTIONS,
	INTERVAL_OPTIONS,
	FREQUENCY_OPTIONS,
	SAME_DAY,
	buildAndCompleteRRule} from '../../../../shared/utils/RecurrenceHelpers';
import DaySelectables from './RecurrenceDayPressables.react';
import MonthSelectables from './RecurrenceMonthSelectables';
import { RRule } from 'rrule';
import { RRuleBuilder } from '../../../../shared/utils/recurrenceRule';

/**
    Recurrence Rule Selector -  component for creating recurrence rules within a modal. It enables users to define 
    recurrence patterns for events, such as daily, weekly, monthly. Users can select 
    the start date, frequency, interval, and specific conditions like day of the week or month for the recurrence.

    Upon completion, the component executes a callback function, passing the generated RRULE string, which can then be
    saved or further processed by the parent component. If the operation is cancelled, another callback is invoked to
    handle cleanup.

    @component
    @param {moment.Moment} date - The Moment object representing the day-of-year to use as the start of the recurrence in local time.
    @param {function} onComplete - Function to be called after building the recurrence rule, with the RRULE string as its only parameter. Should be used for saving the RRULE in the parent component or removeing the recurrence rule from parent state.
    @param {moment.Moment} time - The moment object representing the event start time of the the event to use for recurrence in local time.
*/
const RecurrenceRuleSelector = ({ date , onComplete, time, isAllDayEvent }) => {
    // since both date and time are moment objects they have conflicting information we need
    // create a new moment object using the YYYY-MM-DD from `date` and hh:mm:ss from `time`
    // For all-day events, we default to using noon Eastern Time. Since all-day events dont have a start or end time.
    let newDate = null
    if (isAllDayEvent) {
        newDate = moment.tz(`${date.format('YYYY-MM-DD')} 12:00:00`, "America/New_York");
      } else {
        newDate = new moment()
            .year(date.year())
            .month(date.month())
            .date(date.date())
            .hour(time.hour())
            .minute(time.minute())
            .second(time.second());
    }
    const initial_weekday = DAY_OPTIONS[newDate.day()].slug
    const [frequency, setFrequency] = useState(RRule.DAILY);
    const [interval, setInterval] = useState('1');
    const [count, setCount] = useState('1');
    const [daySet, setDaySet] = useState(new Set([initial_weekday]));
    const [monthlyOption, setMonthlyOption] = useState(SAME_DAY);

    const toggleDay = (day) => {
        if (day === DAY_OPTIONS[newDate.day()].slug) {
            return;
        }
        const newDaySet = new Set([...daySet]);
        if (newDaySet.has(day)) newDaySet.delete(day);
        else newDaySet.add(day);
        setDaySet(newDaySet);
    };
    
    const handleCancel = () => {
        onComplete(null); 
    };

    const handlePress = () => {
        buildAndCompleteRRule(frequency, interval, count, newDate, daySet, monthlyOption, onComplete);
    };

    return (
        <div>
            <p className={'formLabel'}>SELECT FREQUENCY</p>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 20 }}>
                <CustomPicker items={INTERVAL_OPTIONS} isOpen={true} selectedValue={interval} onSelect={setInterval} hideOpener={true}/>
                <CustomPicker items={FREQUENCY_OPTIONS} isOpen={true} selectedValue={frequency} onSelect={setFrequency} hideOpener={true}/>
            </div>
            <p className='formLabel'>SELECT RECURRENCE</p>
            <div style={{marginTop: 10, marginBottom: 10}}>
                {frequency === RRule.WEEKLY && <DaySelectables days={DAY_OPTIONS} onDaySelected={toggleDay} selectedOption={daySet} />}
                {frequency === RRule.MONTHLY && <MonthSelectables options={generateMonthlyOptions(newDate)} onOptionSelected={setMonthlyOption} selectedOption={monthlyOption} />}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                <CustomPicker items={COUNT_OPTIONS} isOpen={true} selectedValue={count} onSelect={setCount} hideOpener={true}/>
            </div>
            <RWBButton
                label={"Set frequency and recurrences"}
                buttonStyle={'primary'}
                onClick={handlePress}
            />
            <RWBButton
                label={"Do Not Repeat Event"}
                buttonStyle={'tertiary'}
                onClick={handleCancel}
            />

        </div>
    )
}

export default RecurrenceRuleSelector;
