import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class RWBLogoHorizontal extends Component {
  render() {
    return (
      <svg
        className={this.props.className}
        viewBox="0 0 228 50.73"
        width={this.props.width}
        height={this.props.height}>
        <path
          fill={'#041e42'}
          d="M62.9,30.7l-9.43.46-4.21-8.52-.09-.23L0,7.76c.11,5.88,5.45,15.41,12.47,16.53l33.37,5.21L45.1,31,12,30.19c.44,6,6.92,9.95,11.49,9.76l18.87-.83.05.07L42.11,41,23.45,44.92c2.65,4.5,12,6.74,14,5.46l5.08-2.69,6.75-3.55,8.44,4.44-1.61-9.39Z"
        />
        <path
          fill={'#bf0d3e'}
          d="M51.48,21.76l3.43,6.94,4.1-.2,27-4.21c6.82-1,12.36-10.66,12.47-16.53Z"
        />
        <path
          fill={'#bf0d3e'}
          d="M64.75,32.19l-5.68,7.06,15.87.7c4.17.16,11.05-3.74,11.49-9.76l-20.49.52Z"
        />
        <path
          fill={'#bf0d3e'}
          d="M60,48.17,60.35,50l.67.35c2,1.28,11.33-1,14-5.46l-16.1-3.39Z"
        />
        <path
          fill={'#041e42'}
          d="M63.12,5.42A20.22,20.22,0,0,0,58.44,3S57.13.63,53.28.18C47.34-.5,44.1.72,40.89,4.59s-3.48,11.54-3.48,11.54l12,3.83L58,17.34A5.64,5.64,0,0,1,57.15,15c-.35-1.72-.49-3.39.26-4.45a8,8,0,0,1,1.41-1.58s1.11.25,1.57.31l1.56.21a3.45,3.45,0,0,1,.13,1.82c-.1.29.52.33,1-.39S65.33,7.06,63.12,5.42Zm-6.74.32a1.84,1.84,0,0,1-2.53,1c-1.32-.39-1.12-2.47-1.12-2.47l4.14.8A3.22,3.22,0,0,0,56.38,5.74Z"
        />
        <path
          fill={'#bf0d3e'}
          d="M77.39,48.45h-.81v2.25h-.31V48.45h-.84v-.27h2Zm1.79,1.74.79-2h.52v2.52h-.31V48.45h0l-.91,2.25h-.18l-.91-2.25h0v2.25h-.31V48.18h.52Z"
        />
        <path
          fill={'#041e42'}
          d="M131.09,40h2.07a7.7,7.7,0,0,0,5-1.36,4.38,4.38,0,0,0,1.31-1.89,7.62,7.62,0,0,0,.41-2.65V27.66A7.64,7.64,0,0,0,139.5,25a4.38,4.38,0,0,0-2.29-2.51,9.33,9.33,0,0,0-4.06-.73H109.57a.56.56,0,0,0-.56.56v23c.2,3.62,1.43,4.78,4.34,5.11h2.52a.56.56,0,0,0,.39-.16.56.56,0,0,0,.16-.39V27.63h13.86a3.57,3.57,0,0,1,1.87.32.85.85,0,0,1,.32.41,2.5,2.5,0,0,1,.13.9v3.31a2.47,2.47,0,0,1-.13.9.9.9,0,0,1-.6.55,5,5,0,0,1-1.59.18h-8.45v3.49c.27,2.16,1,3.34,3.09,5.21a7.94,7.94,0,0,1,1,1l6.79,6.37a.56.56,0,0,0,.38.15h9.18a.56.56,0,0,0,.35-1Z"
        />
        <path
          fill={'#041e42'}
          d="M191,21.76h-5.71a.56.56,0,0,0-.52.37l-6.18,17.1-6.93-17.12a.56.56,0,0,0-.52-.35h-4.48a.56.56,0,0,0-.52.35l-6.78,17.13-6.48-17.12a.56.56,0,0,0-.52-.36h-6.62a.56.56,0,0,0-.52.76l10.62,27.55a.56.56,0,0,0,.52.36h4.43a.56.56,0,0,0,.52-.35l7.17-17.77,7.07,17.76a.55.55,0,0,0,.52.35h4.32a.56.56,0,0,0,.52-.36l10.62-27.55a.56.56,0,0,0-.52-.76Z"
        />
        <path
          fill={'#041e42'}
          d="M226.57,37a5.61,5.61,0,0,0-1.88-1.3,5.31,5.31,0,0,0,1.69-1.08,4.81,4.81,0,0,0,1.4-3.58V27.66a7.67,7.67,0,0,0-.41-2.65,4.38,4.38,0,0,0-2.29-2.51,9.33,9.33,0,0,0-4.06-.73H197.44a.56.56,0,0,0-.56.56V49.86a.56.56,0,0,0,.56.56h21.89c2.75,0,4.88-.43,6.38-1.4a4.84,4.84,0,0,0,1.72-1.9,5.83,5.83,0,0,0,.57-2.64V40.95A5.64,5.64,0,0,0,226.57,37Zm-5.66,5.46a3.09,3.09,0,0,1-.14,1,1,1,0,0,1-.65.64,5.26,5.26,0,0,1-1.74.21H204.3v-17h14.07a4,4,0,0,1,1.92.31.8.8,0,0,1,.33.36,1.78,1.78,0,0,1,.13.75v2.61a1.78,1.78,0,0,1-.13.75.92.92,0,0,1-.63.49,5.52,5.52,0,0,1-1.62.18h-8.63v1.51c.3,2.9,1.48,3.84,3.91,4.14h4.73a7.47,7.47,0,0,1,1.31.09,1.37,1.37,0,0,1,.94.47,2.25,2.25,0,0,1,.29,1.34Z"
        />
        <path
          fill={'#bf0d3e'}
          d="M109.58,7.24,109,9.05h2.44v5.27c.08.9.47,1.51,2,1.51h.1V9.05h2.54V7.24Z"
        />
        <path
          fill={'#bf0d3e'}
          d="M124.18,12.26H127l-.53-1.71h-2.24V9h3.27V7.24h-5.37v6.83c0,1,.34,1.76,2.06,1.76,1.16,0,1.72,0,2,0h1.44V14.12h-3.38Z"
        />
        <path
          fill={'#bf0d3e'}
          d="M136.23,7.2l-3.1,8.66h2.09l.57-1.79h3l.61,1.79h2.21l-3-8.66Zm.07,5.2,1-3,1,3Z"
        />
        <path
          fill={'#bf0d3e'}
          d="M154.82,14.61c.14.74.6,1.22,2,1.22h.25l-.62-8.59h-2.68l-1.69,5.93-1.67-5.93h-2.76l-.48,6.67c0,1,.24,1.8,1.72,1.9l.37-5.68,1.63,5.72.52,0h1.48l1.64-5.74Z"
        />
      </svg>
    );
  }
}

RWBLogoHorizontal.defaultProps = {
  width: 50,
  height: 24,
};

RWBLogoHorizontal.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
