import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 16 16"
    {...props}
  >
    <g data-name="Layer 1">
      <g
        style={{
          isolation: "isolate",
        }}
      >
        <path
          d="M15.71 6.07c0 .35-.29.64-.64.64h-4.5c-.26 0-.49-.16-.59-.4-.1-.23-.05-.51.14-.69l1.39-1.38a5.17 5.17 0 0 0-3.5-1.38C5.18 2.86 2.87 5.17 2.87 8s2.31 5.14 5.14 5.14c1.6 0 3.07-.72 4.06-2 .05-.07.14-.11.23-.12.09 0 .18.03.25.09l1.38 1.39c.12.11.12.3.02.43a7.68 7.68 0 0 1-5.93 2.78C3.75 15.71.29 12.25.29 8S3.75.29 8 .29c1.98 0 3.9.79 5.31 2.13l1.31-1.3a.63.63 0 0 1 .7-.14c.23.1.39.33.39.59v4.5Z"
          style={{
            fill: props.tintColor || "#bf0d3e",
            strokeWidth: 0,
          }}
        />
      </g>
      <path
        d="M0 0h16v16H0z"
        style={{
          strokeWidth: 0,
          fill: "none",
        }}
      />
    </g>
  </svg>
)
export default SvgComponent
