import React, { useState, useEffect } from 'react';
import { MAX_IMAGE_HEIGHTS, MAX_IMAGE_WIDTHS } from '../../../../shared/constants/ImageConstants';

const ImageWithAspectRatio = ({ src, className , onClick }) => {
  const MAX_HEIGHT = MAX_IMAGE_HEIGHTS.web;
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height > MAX_HEIGHT ? MAX_HEIGHT : img.height });
    };
    img.src = src;
    const handleResize = () => {
      // Force a re-render on window resize
      setDimensions(current => ({ ...current }));
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    
  }, [src]);

  const getAspectRatioStyles = () => {
    if (dimensions.width && dimensions.height) {
      // Determine if the image is landscape and wider than 16:9
      if (dimensions.width > dimensions.height && dimensions.width / dimensions.height > 16 / 9) {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) return { objectFit: 'cover', height: '100%', borderRadius: '10px' };
        // a bug on Safari makes images stack without a max width, to fix that issue and ensure images can fill the whole view we set the max width to 1000
        // An image a width of 1000 taking up the full card and covering image previews is not an issue.
        else return { maxWidth: '1000px', height: '100%', objectFit: 'contain', borderRadius: '10px' };
      }
      // Styles for other aspect ratios or portrait images
      return { height: 'auto',  };
    }
    return {};
  };

  return (
    <img src={src} alt="" style={getAspectRatioStyles()} className={className} onClick={onClick} />
  );
};

export default ImageWithAspectRatio;
