const MEMBER_FILTER_LABELS = {
    JOINED_TEAM_RWB: 'JOINED TEAM RWB',
    LAST_CHECK_IN: 'LAST CHECK-IN',
    EVENT_CHECK_INS_YTD: 'EVENT CHECK-INS YTD',
    MEMBER_STATUS: 'MEMBER STATUS',
    MILITARY_BRANCH: 'MILITARY BRANCH',
    MILITARY_STATUS: 'MILITARY STATUS',
    ANCHOR_MEMBERS: 'ANCHOR MEMBERS',
    EAGLE_LEADER: 'EAGLE LEADER',
}

const JOINED_TEAM_RWB = {
    'all-time': {
      display: 'All Time',
      slug: 'all-time'
    },
    'today': {
      display: 'Today',
      slug: 'today'
    },
    'last-7-days': {
      display: 'Last 7 Days',
      slug: 'last-7-days'
    },
    'last-30-days': {
        display: 'Last 30 Days',
        slug: 'last-30-days'
      },
      'this-year': {
        display: 'This Year',
        slug: 'this-year'
      },
}

const LAST_CHECK_IN = {
    'all-time': {
      display: 'All Time',
      slug: 'all-time'
    },
    'today': {
      display: 'Today',
      slug: 'today'
    },
    'last-7-days': {
      display: 'Last 7 Days',
      slug: 'last-7-days'
    },
    'last-30-days': {
        display: 'Last 30 Days',
        slug: 'last-30-days'
      },
      'last-45-days': {
        display: 'Last 45 Days',
        slug: 'last-45-days'
      },
      'last-90-days': {
        display: 'Last 90 Days',
        slug: 'last-90-days'
      },
      'last-180-days': {
        display: 'Last 180 Days',
        slug: 'last-180-days'
      },
}
// check_in_frequency on back end
const EVENT_CHECK_INS_YTD = {
    'any': {
      display: 'Any',
      slug: 'any'
    },
    '10-or-less': {
      display: '10 or Less',
      slug: '10-or-less'
    },
    '25-or-less': {
      display: '25 or Less',
      slug: '25-or-less'
    },
    '50-or-less': {
        display: '50 or Less',
        slug: '50-or-less'
    },
    '100-or-less': {
        display: '100 or Less',
        slug: '100-or-less'
    },
}

const MEMBER_STATUSES = {
    'new': {
        display: 'New',
        value: 'new'
    },
    'active': {
        display: 'Active',
        value: 'act'
    },
    'inactive': {
        display: 'Inactive',
        value: 'ina'
    },
    'legacy': {
        display: 'Legacy',
        value: 'leg'
    },
}

export {
    MEMBER_FILTER_LABELS,
    JOINED_TEAM_RWB,
    LAST_CHECK_IN,
    EVENT_CHECK_INS_YTD,
    MEMBER_STATUSES
}
