import { IconButton } from '@material-ui/core';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DEFAULT_GROUP_MEMBER_FILTER_OPTIONS } from '../../../../shared/constants/DefaultFilters';
import { MILITARY_PROPS } from '../../../../shared/constants/RadioProps';
import { userProfile } from '../../../../shared/models/UserProfile';
import GroupFilters from './GroupFilters';
import styles from './GroupLozenge.module.css';
import Lozenge from './Lozenge';

class GroupLozenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
        overlayVisible: false,
        openGroupFilters: false,
        activeLozenge: null,
        lozengeOptions: [],
        overlayPosition: { top: 0, left: 0 },
    };
    this.wrapperRef = React.createRef();
  }

  getLozengeOptions = (slug) => {
    switch (slug) {
      case 'militaryBranch':
        return MILITARY_PROPS.branch_radio_props.map(option => ({
          label: option.label,
          action: () => this.props.selectFromDropdown(option.value, slug)
        }));
      case 'militaryStatus':
        return MILITARY_PROPS.status_radio_props.map(option => ({
          label: option.label,
          action: () => this.props.selectFromDropdown(option.value, slug)
        }));
      default:
        return [];
    }
  };

  showOverlay = (slug, event) => {
    const triggerRect = event.currentTarget.getBoundingClientRect();
    const containerRect = this.wrapperRef.current.getBoundingClientRect();
    const scrollLeftOffset = window.pageXOffset || document.documentElement.scrollLeft;
    const overlayLeft = triggerRect.left - containerRect.left + scrollLeftOffset;

    this.setState({
      overlayVisible: this.state.overlayVisible && this.state.activeLozenge === slug ? false : true,
      openGroupFilters: false,
      activeLozenge: slug,
      lozengeOptions: this.getLozengeOptions(slug),
      overlayPosition: overlayLeft,
    });
  };

  hideFilters = () => {
    this.setState({openGroupFilters: false})
  }

  calculateFilterCount() {
    let filters = this.props.groupMemberFilters;
    const count = Object.keys(filters).reduce((prev, key) => {
      if (filters[key] !== DEFAULT_GROUP_MEMBER_FILTER_OPTIONS[key] && filters[key]?.size !== 0) prev += 1;
      return prev;
    }, 0);
    return count;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        overlayVisible: false,
      });
    }
  };

  render() {
    const filtersCount = this.calculateFilterCount();
    const groupMemberFilters = this.props.groupMemberFilters;
    return (
      <div ref={this.wrapperRef} style={{ position: 'relative' }}>
          {this.state.overlayVisible && (
          <div className={styles.container} style={{ left: `${this.state.overlayPosition}px` }}>
              {this.state.lozengeOptions.map((option, index) => (
              <IconButton
                  className={styles.filterLozenges}
                  size={'small'}
                  onClick={(e) => {
                    option.action();
                    e.stopPropagation();
                    }}>
                  <div
                      className={[
                      styles.dropDownContainer,
                      (groupMemberFilters.militaryBranch.has(option.label) || groupMemberFilters.militaryStatus.has(option.label)) && styles.selected,
                      ].join(' ')}>
                      {option.label}
                  </div>
              </IconButton>
              ))}
          </div>
          )}
          <div style={{display: 'flex', paddingLeft: '20px'}}>
            <Lozenge
              slug={'filters'}
              display={'Filters'}
              onClick={() => {this.setState({openGroupFilters: true})
                              this.setState({overlayVisible: false})}}
              filter={true}
              filtersCount={filtersCount}
              selected={filtersCount !== 0}
            />
            <Lozenge
              slug={'branch'}
              display={'Branch'}
              onClick={(e) => this.showOverlay('militaryBranch', e)}
              dropDown={true}
              selected={groupMemberFilters.militaryBranch.size > 0}
            />
            <Lozenge
              slug={'military-status'}
              display={'Military Status'}
              onClick={(e) => this.showOverlay('militaryStatus', e)}
              dropDown={true}
              selected={groupMemberFilters.militaryStatus.size > 0}
            />
            {this.props.isAdminOrModerator && <Lozenge
              slug={'anchor-members'}
              display={'Anchor Members'}
              onClick={() => {
                this.props.toggleLozenge('isAnchorMember')
                this.state.overlayVisible && this.setState({overlayVisible: false})
              }}
              selected={groupMemberFilters.isAnchorMember}
            />}
            {this.props.isAdminOrModerator && <Lozenge
              slug={'eagle-leader'}
              display={'Eagle Leader'}
              onClick={() => { 
                this.props.toggleLozenge('isEagleLeader')
                this.state.overlayVisible && this.setState({overlayVisible: false})
              }}
              selected={groupMemberFilters.isEagleLeader}
            />}
        </div>
        {
          this.state.openGroupFilters && 
          <GroupFilters 
            hideFilters={this.hideFilters}
            groupMemberFilters={this.props.groupMemberFilters}
            onSubmit={this.props.onSubmit}
            isAdminOrModerator={this.props.isAdminOrModerator}
          />
        }
      </div>
    );
  }
}

export default GroupLozenge;
