import React, { useState, memo } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styles from '../components/profile/MenuButton.module.css'
import ChevronDownIcon from './svgs/ChevronDownIcon';

/**
 * @param {string} label - title displayed above options. This should either be above every option if there are multiple in one row, or omitted due to pushing inputs down on the y axis
 * @param {array} options - list of options to be displayed when the dropdown is clicked.
 * @param {string} value - initial value dispalyed for selected item
 * @param {boolean} fullWidth - determines if this is a full width dropdown input for special styling to be used
 * @param {placeholder} string - displayed text if no value passed in and no selectedItem
 * @param {function} storeValue - callback to have the parent store the select item
 * @param {error} error - error to check if drop down is correct
 */


const DropdownInputPicker = memo(
  ({ label, options, value, fullWidth, placeholder, storeValue, error }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(value);
    const [visible, setVisible] = useState(false);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget); // set element anchor position for menu items
      setVisible(true);
      event.stopPropagation();
    };

    const handleClose = (event) => {
      setAnchorEl(null);
      setVisible(false);
    };

    const selectHandler = (event, item) => {
      setSelectedItem(item);
      storeValue(item);
      event.stopPropagation();
      setAnchorEl(null);
      setVisible(false);
    };

    return (
      <div className={styles.container}   style={{ borderBottom: error ? "3px solid var(--magenta)" : "1px solid var(--grey80)"}}>
        <p className="formLabel">{label}</p>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ color: error ? "var(--magenta)" : ""}}
          className={styles.button}>
          <div className={styles.selectedValue}>
            {selectedItem || placeholder}
          </div>
          <ChevronDownIcon />
        </Button>
        <Menu
          id="simple-menu"
          className={fullWidth ? styles.menu : null}
          keepMounted
          open={visible}
          anchorEl={anchorEl}
          onClick={(e) => e.stopPropagation()}
          onClose={handleClose}>
          {options?.map((item, key) => (
            <MenuItem key={key} onClick={(event) => selectHandler(event, item)}>
              {item}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  },
);

export default DropdownInputPicker;
