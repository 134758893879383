import React from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import styles from './Carousel.module.css';
import ImageWithAspectRatio from './ImageWithAspectRatio.react';
import {
    logAccessPostPhoto,
  } from '../../../../shared/models/Analytics';

  
class Carousel extends React.Component {
  state = {
    currentImageIndex: 0,
    isModalOpen: false,
    selectedImage: null,
    feedWidth: 0,
  };

  render() {
    const { images, multiPhotoClicked, currentImageIndex, setImageIndex, feedWidth} = this.props;
    return (
      images.length > 1 ? (
        <div className={styles.carouselContainer}>
          <div className={styles.imagesContainer} style={{ height: feedWidth * 0.5625 }}>
            {images.map((image, index) => {
              let imageClass = index === currentImageIndex
                ? styles.focused
                : (index === currentImageIndex - 1 || index === currentImageIndex + 1)
                  ? styles.adjacent
                  : null;
              return imageClass && (
                <div key={`post-photo-container-${index}`} className={`${imageClass}`} style={{ height: '100%' }}>
                  <ImageWithAspectRatio 
                    src={image} 
                    className={`${styles.carouselImage}`} 
                    onClick={() => multiPhotoClicked(index)}
                  />
                </div>
              );
            })}
          </div>
          {currentImageIndex !== 0 && (
            <MdChevronLeft className={styles.prevButton} onClick={() => setImageIndex(currentImageIndex - 1)} />
          )}
          {currentImageIndex !== images.length - 1 && (
            <MdChevronRight className={styles.nextButton} onClick={() => setImageIndex(currentImageIndex + 1)} />
          )}
          <div className={styles.carouselIndicators}>
            {images.map((_, index) => (
              <div
                key={`indicator-${index}`}
                className={`${styles.indicator} ${index === currentImageIndex ? styles.active : ''}`}
                onClick={() => setImageIndex(index)}
              />
            ))}
          </div>
        </div>
      ) : null
    );
  }
}

export default Carousel;