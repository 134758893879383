const EL_LABELS = {
    startDate: 'EAGLE LEADER START DATE',
    onboardingStatus: 'ONBOARDING STATUS',
    offboardingNotification: 'OFFBOARDING NOTIFICATION',
    role: 'EAGLE LEADER ROLE',
    endDate: 'EAGLE LEADER END DATE',
}

const EL_ROLES = [
    'Deputy State Coordinator',
    'Chapter Captain',
    'Deputy Chapter Captain',
    'Volunteer Leader',
]

const EL_ERRORS = {
    futureStartDate: 'START DATE CANNOT BE FUTURE-DATED',
    futureEndDate: 'END DATE CANNOT BE FUTURE-DATED',
}

const onboardMessage = (user) => {
    return `Please select a start date and role to start the process of promoting ${user?.first_name} ${user?.last_name} to an Eagle Leader.`
}

const offboardMessage = (user) => {
    return `You are removing ${user?.first_name} ${user?.last_name} as an Eagle Leader across all Team RWB groups. Ensure they don't lead in other groups before setting an end date to initiate offboard. The offboarding process will take several minutes to complete once initiated.`
}

const getOffboardingMessage = (offboardingNotification, user) => {
    let alert = offboardingNotification;
    let message = '';
    
    const baseMessage = `${user?.first_name} ${user?.last_name} hasn’t checked in to any events in`;
    
    if (alert === 'Warning - 45 Day External') {
        message = `${baseMessage} over 45 days. An email was sent notifying them that they will be offboarded after 60 days of inactivity. Reach out to check in on them now to keep them onboard!`;
    } else if (alert === 'Alert - 30 Day Internal') {
        message = `${baseMessage} at least 30 days and will be offboarded after 60 days of inactivity. Reach out to check in on them now to keep them onboard!`;
    }
    else return null
    return { alert, message };
  };

const CLOSE_CONFIRMATION = "Would you like to save the changes you made to the Eagle Leader's Info?";

export {
    EL_LABELS,
    onboardMessage,
    offboardMessage,
    getOffboardingMessage,
    EL_ROLES,
    EL_ERRORS,
    CLOSE_CONFIRMATION,
}
