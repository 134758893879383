import { rwbApi } from "../apis/api";

const MAX_CONCURRENT_UPLOADS = 5;

function putMediaUpload(url, data) {
    return fetch(url, {
        method: 'PUT',
        body: data,
    }).then((result) => {
        return result;
    });
}

async function uploadImagesSequentially(images) {
    let results = [];
    for (let i = 0; i < images.length; i += MAX_CONCURRENT_UPLOADS) {
        const chunk = images.slice(i, i + MAX_CONCURRENT_UPLOADS);
        const promises = chunk.map((img) => putMediaUpload(img.media_upload_url, img.blobImg));
        const result = await Promise.allSettled(promises);
        results = [...results, ...result];
    }
    return results;
}

export const uploadImgs = (data, blobImgKey) => {
    const mediaInfoOnly = data.map((entry) => entry.mediaInfo);
    return rwbApi
        .getMultiMediaUploadURL(mediaInfoOnly)
        .then((result) => {
            // web utilizes the blobImg key, mobile does not
            result.forEach((imgObj, key) => imgObj.blobImg = blobImgKey ? data[key][blobImgKey] : data[key]);
            return uploadImagesSequentially(result);
        }).catch((err) => {
            console.warn('upload imgs error: ', err);
        });
}
