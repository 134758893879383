import { capitalizeFirstLetter } from "./Helpers";
import moment from 'moment';
import { isDev } from "./IsDev";

export const surveyURL = 'https://www.tfaforms.com/5069319?pv1=';

// to avoid errors we use the staging ids for the groups on dev builds and the community leader's id for Mike Erwin's id
const RUNNING_ID = isDev() ? '200744' : '683657';
const HIKING_ID = isDev() ? '200757' : '683664';
const YOGA_ID = isDev() ? '200754' : '683659';
const ERWIN_ID = isDev() ? '39018' : '35288';

export const filterELSKeys = (healthInfo) => {
    if (!healthInfo) return;
    const validELSKeys = ['genuine_relationships', 'mental_health', 'physical_health', 'sense_of_purpose'];
    return validELSKeys.reduce((acc, key) => {
        if (validELSKeys.includes(key)) {
            acc[key] = healthInfo[key];
        }
        return acc;
    }, {});
}

export const formatTitle = (title) => {
    let splitKey = title.split('_');
    for (let i = 0; i < splitKey.length; i++)
        splitKey[i] = splitKey[i] !== 'of' ? capitalizeFirstLetter(splitKey[i]) : 'of';
    return splitKey.join(' ');
}

const compareScores = (cur, comparison, creased = false) => {
    if (cur > comparison) 
    {
        if (creased) 
            return 'increased';
        else 
            return 'above';
    }
    else if (cur < comparison) 
    {
        if (creased) 
            return 'decreased';
        else 
            return 'below'
    }
    else 
    {
        if (creased) 
            return 'not changed';
        else 
            return 'the same as';
    }
}

const formatDate = (date) => {
    return moment(date).format('MM-DD-YY');
}

const untakenMessage = "Let’s check out how you’re doing with our quick Enriched Life Scale Assessment. It’s a short 18-question survey that helps assess your general well-being. Use the results to help identify the best Team RWB events, content, and communities for you.";

const pendingMessage = " Your score is being calculated. Wherever you are on your wellness journey, we’ve got your six.";

const singleScore = (score, date, average) => {
    return `You completed the ELS on [${formatDate(date)}] with a total enrichment score of [${score}], which is [${compareScores(score, average)}] the Team RWB average of [${average}]. The scores range from 0 (Not at all Enriched) to 100 (Very Enriched).${additionScoreMessages}`;
};

const multiScore = (score, date, average, previousScore) => {
    return `You completed the ELS on [${formatDate(date)}] with a total enrichment score of [${score}], which is [${compareScores(score, average)}] the Team RWB average of [${average}]. Your score has [${compareScores(score, previousScore, true)}] since your last assessment.${additionScoreMessages}`;
};

const additionScoreMessages = `

Our community is here to support you on your journey. Our events, training, and fitness groups are designed to keep you positively focused, help you build relationships, and push you to keep driving forward with new goals.

Your score today is a general guidepost to help you identify needs and tackle strategies in support of your well-being. Remember, your best days are ahead!`

export const generateEnrichmentText = (firstName, score, date, average, previousScore, pending) => {
    let message = `[Hey ${firstName}!] `;
    if (score === undefined) message += untakenMessage;
    if (pending) message += pendingMessage;
    else if ((score || score === 0) && previousScore) message += multiScore(score, date, average, previousScore);
    else if (score || score === 0) message += singleScore(score, date, average);
    return message;
}


export const breakdown = "Here's a breakdown of your score with information to support you on your journey:";

const physicalHealthMessage = (score, average) => {
    return `Defined as having excellent general physical health including consistent exercise, quality nutrition, and healthy weight maintenance. Regarding physical health, your score of [${score}], is [${compareScores(score, average)}] the Team RWB average score of [${average}].`
}

const physicalHealthRecommendations = () => {
    const improveMessage = 'Improve your score, enrich your life, and tackle new health goals:';
    const recommendations = ['Attend a {https://members.teamrwb.org/challenges monthly mission} and participate in daily train up workouts.', 'Grab a battle buddy and create or get active at a local {https://members.teamrwb.org/events event}.', 'Join an {https://members.teamrwb.org/groups activity group} and get support for your fitness goals.'];
    return {improveMessage, recommendations};
}

const mentalHealthMessage = (score, average) => {
    return `Defined as having quality sleep, mental focus, and excellent general mental health, including the ability to go about day-to-day activities even when feeling depressed, anxious, or angry. Regarding mental health, your score of [${score}], is [${compareScores(score, average)}] the Team RWB average score of [${average}].`
}

const mentalHealthRecommendations = () => {
    const improveMessage = 'Check out these suggestions to improve your mental health score and enrich your life:';
    const recommendations = [`Manage stress and increase mindfulness by participating in live guided meditation with the {https://members.teamrwb.org/groups/${YOGA_ID} yoga group}.`, `Find ways to get moving in our {https://members.teamrwb.org/groups activity groups}. {https://members.teamrwb.org/groups/${RUNNING_ID} Running}, {https://members.teamrwb.org/groups/${HIKING_ID} hiking}, and {https://members.teamrwb.org/groups/${YOGA_ID} yoga} are all associated with improved sleep, mood, and positive mental health outcomes.`, `Build relationships supporting your mental health by following members here in the app. Not sure where to start? Follow our Founder & Executive Director {https://members.teamrwb.org/profile/${ERWIN_ID} Mike Erwin}.`];
    const vgradRecommendations = ['Access free best-in-class brain and mental health care through our partners at the {https://www.veterancheckin.org/s/ Veterans Wellness Alliance}.', 'Get connected to care through our partners at the {https://www.veterancheckin.org/s/?utm_source=rwb&utm_medium=website&utm_campaign=vwa, Department of Veterans Affairs}.'];
    return {improveMessage, recommendations, vgradRecommendations};
}

const genuineRelationshipsMessage = (score, average) => {
    return `Defined as having close relationships and a broader social circle that provides you with emotional support, information, or resources. Regarding genuine relationships, your score of [${score}], is [${compareScores(score, average)}] the Team RWB average score of [${average}].`
}

const genuineRelationshipsRecommendations = () => {
    const improveMessage = 'Follow these steps to start building genuine relationships through Team RWB:';
    const recommendations = ['Use {https://members.teamrwb.org/groups groups} and {https://members.teamrwb.org/challenges mission feeds} in our online community to find, follow, and support other Eagles as they share their goals and wellness journey.', 'Train for and attend an epic multi-day experience with other team members in nature called an Eagle Expedition.', 'Attend Team RWB {https://members.teamrwb.org/events gatherings} across the country to take your support network to the next level.'];
    return {improveMessage, recommendations};
}

const senseOfPurposeMessage = (score, average) => {
    return `Defined as having individual and shared goals, positive emotion, and positive personal identity to connect with others. Regarding sense of purpose, your score of [${score}], is [${compareScores(score, average)}] the Team RWB average score of [${average}].`
};

const senseOfPurposeRecommendations = () => {
    const improveMessage = 'Improve your score and sense of purpose:';
    const recommendations = ['Wear the Eagle and carry the flag on a run. Every time you wear the shirt, you’ll know you’re part of something bigger than yourself.', 'Set a Big Fitness Goal (BFG) for yourself and share it with the community. You’ll find others doing the same and gain support and purpose in pursuing your goal.', 'Become a volunteer leader and support the mission in your community.'];
    return {improveMessage, recommendations};
}

export const heatlhMessagePicker = (title, score, average) => {
    if (title === 'genuine_relationships') return genuineRelationshipsMessage(score, average);
    else if (title === 'mental_health') return mentalHealthMessage(score, average);
    else if (title === 'physical_health') return physicalHealthMessage(score, average);
    else if (title === 'sense_of_purpose') return senseOfPurposeMessage(score, average);
}

export const recommendationPicker = (title) => {
    if (title === 'genuine_relationships') return genuineRelationshipsRecommendations();
    else if (title === 'mental_health') return mentalHealthRecommendations();
    else if (title === 'physical_health') return physicalHealthRecommendations();
    else if (title === 'sense_of_purpose') return senseOfPurposeRecommendations();
}

// maybe use elsewhere if we get the same formatting?
export const segmentText = (rawText) => {
    const regex = /\[(.*?)\]|\{(http[^\s]+) (.*?)\}/g;
    let match;
    let lastIndex = 0;
    const segments = [];
  
    while ((match = regex.exec(rawText)) !== null) {
      segments.push({ text: rawText.slice(lastIndex, match.index), isStyled: false });
  
      if (match[1]) {
        // Colored text match
        segments.push({ text: match[1], isStyled: true });
      } else if (match[2] && match[3]) {
        // Link match
        segments.push({ text: match[3], isStyled: false, isLink: true, linkURL: match[2] });
      }
      lastIndex = regex.lastIndex;
    }
  
    if (lastIndex < rawText.length) {
      segments.push({ text: rawText.slice(lastIndex), isStyled: false });
    }
  
    return segments;
};

