const MAX_POST_LENGTH = 1500; // get stream limitation/requirement

const MAX_COMMENT_LENGTH = 1500; // get stream limitation/requirement

const MAX_POST_IMAGES = 10; // our limit on maximum images per post, comment, and reply

export {
    MAX_POST_LENGTH,
    MAX_COMMENT_LENGTH,
    MAX_POST_IMAGES,
}