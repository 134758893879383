export const MY_PROFILE_TABS = [
    {
      name: 'BIO & FEED',
      key: 'bio',
    },
    {
      name: 'FITNESS',
      key: 'fitness',
    },
    {
      name: 'HEALTH',
      key: 'health',
    },
];
export const PROFILE_TAB = [
  {
    name: 'BIO & FEED',
    key: 'bio',
  },
  {
    name: 'FITNESS',
    key: 'fitness',
  }
];
export const PROFILE_TAB_ADMIN = [
  {
    name: 'BIO & FEED',
    key: 'bio',
  },
  {
    name: 'FITNESS',
    key: 'fitness',
  },
  {
    name: 'MEMBER INFO',
    key: 'member info',
  },
];