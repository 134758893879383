import moment from 'moment';

/**
 * 
 * The `EagleLeaderInfo` class encapsulates details about eagle leaders,
 * including their roles, status, and other pertinent information. It supports determining
 * the current status of a leader based on their activity and end date, and whether their
 * role is among those that can be edited.
 * 
 * @example
 * const leaderInfo = new EagleLeaderInfo({
 *   start_date: '2021-01-01',
 *   end_date: null,
 *   role: EagleLeaderInfo.ROLES.CHAPTER_CAPTAIN,
 *   active: true,
 *   offboarding_notification: false,
 *   training_completion_date: '2021-02-01'
 * });
 * console.log(leaderInfo.status); // 'ACTIVE'
 * console.log(leaderInfo.isEditable()); // true
 *
 * @static
 * @property {Object} STATUS - The enumeration of possible statuses of an Eagle Leader.
 * @static
 * @property {Object} ROLES - The enumeration of possible roles an Eagle Leader can hold.
 * @static
 * @property {Array} EDITABLE_ROLES - The list of roles that can be edited.
 * 
 * @property {Object} eagleLeaderInfo - The initialization data for the Eagle Leader.
 * @property {String} this.startDate - The start date of the Eagle Leader's onboarding process.
 * @property {String} this.endDate - The end date when the eagal leader has been offboarded. Optional.
 * @property {String} this.role - The role of the Eagle Leader ('Deputy State Coordinator','Chapter Captain','Deputy Chapter Captain','Volunteer Leader').
 * @property {Boolean} this.active - Whether or not the Eagle Leader is completely onboarded.
 * @property {String} this.offboardingNotification - Whether an offboarding notification has been sent.
 * @property {String} this.trainingCompletionDate - The date when the Eagle Leader completed training.
 */
export default class EagleLeaderInfo {
    static STATUS = {
        PENDING: 'PENDING',
        ACTIVE: 'ACTIVE',
        OFFBOARDING: 'OFFBOARDING',
        IS_NOT_EAGLE_LEADER: 'IS_NOT_EAGLE_LEADER'
    }

    static ROLES = {
        BOARD_MEMBER: "Board Member",
        EXECUTIVE_STAFF: "Executive Staff",
        STATE_COORDINATOR: "State Coordinator",
        ACTIVITY_GROUP_LEADER: "Activity Group Leader",
        ACTIVITY_GROUP_COORDINATOR: "Activity Group Coordinator",
        VOLUNTEER_EXPEDITION_LEADER: "Volunteer Expedition Leader",
        DEPUTY_STATE_COORDINATOR: "Deputy State Coordinator",
        CHAPTER_CAPTAIN: "Chapter Captain",
        DEPUTY_CHAPTER_CAPTAIN: "Deputy Chapter Captain",
        VOLUNTEER_LEADER: "Volunteer Leader",
    }

    static EDITABLE_ROLES = [
        EagleLeaderInfo.ROLES.VOLUNTEER_LEADER,
        EagleLeaderInfo.ROLES.CHAPTER_CAPTAIN,
        EagleLeaderInfo.ROLES.DEPUTY_CHAPTER_CAPTAIN,
        EagleLeaderInfo.ROLES.DEPUTY_STATE_COORDINATOR,
    ]

    constructor(eagleLeaderInfo){
        if (eagleLeaderInfo === undefined) {
            eagleLeaderInfo = null;
        }
        this.eagleLeaderInfo = eagleLeaderInfo;
    }

    /**
     * @return {String|null} The start date.
     */
    get startDate() {
        return this.eagleLeaderInfo?.start_date;
    }

    get role() {
        return this.eagleLeaderInfo?.role;
    }
    get endDate() {
        return this.eagleLeaderInfo?.end_date;
    }

    /**
     * @return {Boolean} Whether or not the user is active.
     */
    get active() {
        if (this.eagleLeaderInfo === null) {
            return false;
        }
        return this.eagleLeaderInfo.active;
    }

    get offboardingNotification() {
        return this.eagleLeaderInfo?.offboarding_notification;
    }

    get trainingCompletionDate() {
        return this.eagleLeaderInfo?.training_completion_date;
    }

    /**
     * Formats the training completion date, if it exists.
     *
     * @returns {String}
     */
    trainingCompletionDateDisplay() {
        if (this.trainingCompletionDate) {
            let m = moment(this.trainingCompletionDate);
            return m.format("MM/DD/YYYY");
        } 
        return "---";
    }

    /**
     * Gets the current status of the Eagle Leader based on if they are active
     * or have a end date.
     * 
     * @returns {EagleLeaderInfo.STATUS} The current status of the Eagle Leader. 
     *   One of ('PENDING', 'ACTIVE', or 'OFFBOARDING').
     */
    get status() {
        //There will always be a start_date if eagle_leader_info isnt null.
        if (this.eagleLeaderInfo === null) {
            return EagleLeaderInfo.STATUS.IS_NOT_EAGLE_LEADER;
        }
        if (!this.active) {
            return EagleLeaderInfo.STATUS.PENDING;
        }
        if (!this.endDate) {
            return EagleLeaderInfo.STATUS.ACTIVE;
        }
        return EagleLeaderInfo.STATUS.OFFBOARDING;
    }

    /**
     * Determines whether the role of the Eagle Leader is editable based on a
     * predefined list of editable roles.
     * 
     * @returns {Boolean} True if the role of the Eagle Leader is editable, false otherwise.
     */
    isEditable() {
        if (!this.role) return false;
        return EagleLeaderInfo.EDITABLE_ROLES.includes(this.role);
    }
}
