'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
var geocodeByAddress = (exports.geocodeByAddress = function geocodeByAddress(
  address,
) {
  var geocoder = new window.google.maps.Geocoder();
  var OK = window.google.maps.GeocoderStatus.OK;

  return new Promise(function (resolve, reject) {
    geocoder.geocode({address: address}, function (results, status) {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
});

var getLatLng = (exports.getLatLng = function getLatLng(result) {
  return new Promise(function (resolve, reject) {
    try {
      var latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      };
      resolve(latLng);
    } catch (e) {
      reject(e);
    }
  });
});

var geocodeByPlaceId = (exports.geocodeByPlaceId = function geocodeByPlaceId(
  placeId,
) {
  var geocoder = new window.google.maps.Geocoder();
  var OK = window.google.maps.GeocoderStatus.OK;

  return new Promise(function (resolve, reject) {
    geocoder.geocode({placeId: placeId}, function (results, status) {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
});
