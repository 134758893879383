import React, {Component} from 'react';
import styles from './CommentView.module.css';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import LikeIcon from '../svgs/LikeIcon';
import ReportAndDeleteOverlay from '../ReportAndDeleteOverlay';
import {howLongAgo, getHyperlinks, filterPostMedia} from '../../../../shared/utils/Helpers';
import {withRouter} from 'react-router-dom';
import FormattedPostText from './FormattedPostText';
import {userProfile} from '../../../../shared/models/UserProfile';
import {rwbApi} from '../../../../shared/apis/api';
import {
  EXECUTION_STATUS,
  logDeleteComment,
  logLikeComment,
  logViewLikeList,
  webSectionName,
} from '../../../../shared/models/Analytics';
import SitePreviewCard from '../cards/SitePreviewCard';
import CreateComment from './CreateComment';
import { CreatedPostImageDisplay } from './PostImageDisplay.react';
import ReplyIcon from '../svgs/ReplyIcon';
import { REACTION_TYPES } from '../../../../shared/utils/StreamHelpers';

class CommentView extends Component {
  constructor(props) {
    super(props);
    // own_reactions are from posts for comments, own_children are from comments for replies
    const {content, tagged, edited, own_reactions, own_like_id, reaction_counts, children_counts, own_children} = this.props.comment;
    this.reactionType = this.props.reactionType || REACTION_TYPES.COMMENT;
    this.state = {
      deleted: false,
      content,
      tagged,
      edited,
      liked: own_reactions?.like || own_children?.like || own_like_id,
      likeCount: reaction_counts?.like || children_counts?.like || 0,
      likeInProgress: false,
      replyModalVisible: false,
      replyData: {taggedUser: null},
      replies: this.props?.comment?.latest_children?.reply || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.comment?.latest_children?.reply?.length !== this.props.comment?.latest_children?.reply?.length) {
      this.setState(prevState => ({
        replies: [...this.props.comment?.latest_children?.reply] || []
      }));
    }
  }

  // only the creator of a post can delete
  canDelete = () => {
    const commenterID = this.props.comment.user?.id.toString();
    const currentUserID = userProfile.getUserProfile().id.toString();
    return commenterID === currentUserID;
  };

  baseAnalyticsObj = () => {
    const {
      eventID,
      event,
      group,
      groupRecordType,
      groupID,
      challengeID,
      challenge,
      feed_origin_name,
      feed_origin_type,
      event_record_type,
      activity_sub_type,
      postID,
      comment,
    } = this.props;
    let analyticsObj = {};
    if (event?.id) {
      analyticsObj.activity_sub_type = event.category;
      analyticsObj.event_record_type = event.is_virtual ? 'virtual' : 'event';
      analyticsObj.event_id = `${event.id}`;
    } else {
      if (eventID) {
        analyticsObj.event_id = `${eventID}`;
      }
      if (event_record_type) {
        analyticsObj.event_record_type = event_record_type;
      }
      if (activity_sub_type) {
        analyticsObj.activity_sub_type = activity_sub_type;
      }
    }
    if (group?.id) {
      analyticsObj.group_id = `${group.id}`;
      analyticsObj.group_record_type = group.type;
    } else {
      if (groupID) {
        analyticsObj.group_id = `${groupID}`;
      }
      if (groupRecordType) {
        analyticsObj.group_record_type = groupRecordType;
      }
    }
    if (challengeID) {
      analyticsObj.challenge_id = `${challengeID}`;
    } else if (challenge?.id) {
      analyticsObj.challenge_id = `${challenge.id}`;
    }
    if (feed_origin_name) {
      analyticsObj.feed_origin_name = feed_origin_name;
    }
    if (feed_origin_type) {
      analyticsObj.feed_origin_type = feed_origin_type;
    }
    if (postID) {
      analyticsObj.object_post = postID;
    }
    if (comment?.id) {
      analyticsObj.object_comment = comment?.id;
    }
    analyticsObj.section_name = webSectionName();
    return analyticsObj;
  };

  deleteComment = () => {
    if (
      window.confirm(
        `Delete Comment: Are you sure you want to delete your ${this.reactionType}?`,
      )
    ) {
      let analyticsObj = this.baseAnalyticsObj();
      analyticsObj.object_post = this.props.comment.id;
      const reactionKind = JSON.stringify({kind: this.reactionType});
      rwbApi
        .deleteReaction(
          this.props.posterID,
          this.props.postID,
          reactionKind,
          this.props.comment.id,
        )
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
          this.props.handleCommentDeletion();
          this.setState({deleted: true});
        })
        .catch(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
        })
        .finally(() => {
          logDeleteComment(analyticsObj);
        });
    }
  };

  updateComment = (data) => {
    this.setState({
      content: data.content,
      tagged: data.tagged,
      edited: true,
    });
  };

  toggleLike = () => {
    if (this.state.likeInProgress) return;
    const currentUserID = userProfile.getUserProfile().id.toString();
    const likePayload = {
      kind: 'like',
      comment_id: this.props.parentID ? this.props.parentID : this.props.comment.id,
    };
    if (this.reactionType === REACTION_TYPES.REPLY) likePayload.reply_id = this.props.comment.id;
    let analyticsObj = this.baseAnalyticsObj();
    if (this.props.offset) likePayload.offset = this.props.offset;
    if (this.state.liked) {
      this.setState({
        liked: false,
        likeCount: this.state.likeCount - 1,
        likeInProgress: true,
      });
      rwbApi
        .deleteReaction(
          currentUserID,
          this.props.postID,
          JSON.stringify(likePayload),
        )
        .catch(() => {
          alert(`Error unliking the ${this.reactionType}.`);
          this.setState({liked: true, likeCount: this.state.likeCount + 1});
        })
        .finally(() => {
          this.setState({likeInProgress: false});
        });
    } else {
      this.setState({
        liked: true,
        likeCount: this.state.likeCount + 1,
        likeInProgress: true,
      });
      rwbApi
        .postReaction(
          currentUserID,
          this.props.postID,
          JSON.stringify(likePayload),
        )
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
        })
        .catch(() => {
          alert(`Error liking the ${this.reactionType}.`);
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          this.setState({liked: false, likeCount: this.state.likeCount - 1});
        })
        .finally(() => {
          logLikeComment(analyticsObj);
          this.setState({likeInProgress: false});
        });
    }
  };

  render() {
    const {history} = this.props;
    const {user, time, graphData, id} = this.props.comment;
    const {content, tagged, edited} = this.state;
    const links = getHyperlinks(content);
    return !this.state.deleted ? (
      <div className={styles.commentContainer} style={{borderBottom: this.reactionType === REACTION_TYPES.REPLY && 0}}>
        <div className={styles.userCommentContainer}>
          {this.props.hasReplyIcon && <div style={{marginLeft: -30}}>
              <ReplyIcon />
            </div>
          }
          <div
            className={styles.userImageContainer}
            onClick={() => history.push(`/profile/${user?.id}`)}>
            <img
              className={styles.profileImage}
              src={user?.profile_photo_url || DefaultProfileImg}
              alt="User Profile Image"
            />
          </div>
          <p>
            <span
              className={`namesAndObjects ${styles.name}`}
              onClick={() => history.push(`/profile/${user?.id}`)}>
              {`${user?.first_name} ${user?.last_name}`}&nbsp;
            </span>
            • {howLongAgo(time) || ''}
            {edited && <p className={'edited'}> Edited</p>}
          </p>
          <div className={styles.reportDeleteContainer}>
            <ReportAndDeleteOverlay
              updateComment={this.updateComment}
              canDelete={this.canDelete()}
              deletePost={this.deleteComment}
              streamID={this.props.postID}
              commentID={id}
              commenter={user}
              text={content}
              type={this.reactionType}
              eventStatusPost={false}
              tagged={tagged}
              mergeNewPost={() => null}
              time={howLongAgo(time)}
              posterID={this.props.posterID}
              poster={this.props.poster}
              posterText={this.props.posterText}
              posterTagged={this.props.posterTagged}
              posterTime={this.props.posterTime}
              refreshReactions={this.props.refreshReactions}
              eventID={this.props?.event?.id}
              groupID={this.props?.group?.id || this.props?.groupID}
              challengeId={
                this.props?.challenge?.id ||
                this.props?.challengeID ||
                this.props?.challengeId
              }
              activity_sub_type={
                this.props?.event?.category || this.props.activitySubType
              }
              event_record_type={
                this.props.eventRecordType ||
                this.props?.event?.is_virtual !== null
                  ? this.props?.event?.is_virtual
                    ? 'virtual'
                    : 'event'
                  : null
              }
              group_record_type={
                this.props.groupRecordType ||
                this.props.group_record_type ||
                this.props.group?.type
              }
              feed_origin_name={this.props.feed_origin_name}
              feed_origin_type={this.props.feed_origin_type}
              graphData={graphData}
              postGraphData={this.props.postGraphData}
            />
          </div>
        </div>
        <div className={styles.commentText}>
          <FormattedPostText
            text={content}
            tagged={tagged}
            linkableUsers={false}
            history={history}
            clickable={true}
            links={links}
          />
          {links.length && graphData?.url ? (
            <SitePreviewCard
              key={links[links.length - 1]}
              link={links[links.length - 1]}
              graphData={graphData}
            />
          ) : null}
          {this.props.comment?.media && <CreatedPostImageDisplay 
            postImages={this.props?.comment?.media}
            likeAmount={this.state.likeCount}
            liked={this.state.liked}
            handleLikePressed={this.toggleLike}
          />}
          <div className={styles.likeContainer} onClick={this.handleLikePost}>
            <div
              className={styles.likeIconWrapper}
              onClick={() => this.toggleLike()}>
              <LikeIcon
                className={styles.likeIcon}
                tintColor={this.state.liked ? 'var(--magenta)' : null}
              />
            </div>
            {this.state.likeCount && this.state.likeCount > 0 ? (
              <p
                className={`namesAndObjects`}
                onClick={() => {
                  this.props.history.push({
                    pathname: `/feed/${this.props.postID}/comment/${this.props.comment.id}/like`,
                    state: {feed: this.props.comment},
                  });
                  let analyticsObj = this.baseAnalyticsObj();
                  analyticsObj.action_category = 'comment';
                  // user is the commenter, poster is who created the post
                  analyticsObj.profile_id = `${this.props.comment.user?.id}`;
                  logViewLikeList(analyticsObj);
                }}>
                {this.state.likeCount}{' '}
                {this.state.likeCount > 1 ? 'Likes' : 'Like'}
              </p>
            ) : null}
            <p className='namesAndObjects' style={{marginLeft: 10}}
              onClick={() => {this.setState({replyData: {taggedUser: this.props.comment.user}, replyModalVisible: true})}}
            >
              Reply
            </p>
          </div>
        </div>
        {this.state.replies?.map((reply, id) => {
          const filteredPhotos = filterPostMedia(reply?.data?.media, 'image');
          Object.assign(reply, reply.data); // get content, tagged, etc in the data field
          reply.time = reply.created_at;
          reply.media = filteredPhotos;
          return (
            <div key={reply.id} style={{marginLeft: 30}}>
              <CommentView 
                key={id}  hasReplyIcon={id === 0}
                refreshReactions={this.props.refreshReactions}
                handleCommentDeletion={this.props.handleCommentDeletion}
                comment={reply} reactionType={REACTION_TYPES.REPLY}
                parentID={this.props.comment.id}
                postID={this.props.postID} posterID={this.props.posterID} posterText={this.props.comment?.data?.content} poster={this.props.comment.user}
              />
            </div>
          )
        })}

        {this.state.replyModalVisible && <CreateComment
          poster={this.props.poster}
          streamID={this.props.postID}
          commentID={this.props.parentID || this.props.comment.id}
          commenter={user}
          closeModalHandler={() => {
            this.setState({replyModalVisible: false, replyData: {}});
            this.props.refreshReactions();
          }}
          time={howLongAgo(time)}
          text={content}
          tagged={tagged}
          reactionType={REACTION_TYPES.REPLY}
          replyData={this.state.replyData}
          history={history}
        />}
      </div>
    ) : null;
  }
}

export default withRouter(CommentView);
