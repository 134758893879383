import Resizer from "react-image-file-resizer";
import { uploadImgs } from '../../../shared/utils/ImgUploader';

const MINIMUM_SCALE = 1000;

// unlike react native this resizer does not change the type, it accepts jpeg, png, and webp
const resizeFile = (file, type) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      MINIMUM_SCALE,
      MINIMUM_SCALE,
      type,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
}


const blobifyImage = (file, type) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      let imgBlob = new Blob([reader.result], { type: file.type });
      let blobURL = URL.createObjectURL(imgBlob);
      const img = new Image();
      img.src = blobURL;

      img.onload = () => {
        const mediaInfo = {
          media_filename: `${new Date().toISOString()}-${file.name.replace(/ /g, '')}`, // avoid spaces in the name used for the url
          media_intent: type,
        };

        if (img.width >= MINIMUM_SCALE && img.height >= MINIMUM_SCALE) {
          resizeFile(imgBlob, file.type.split('/')[1])
            .then(resizedImg => {
              URL.revokeObjectURL(blobURL);
              resolve({ blobImg: resizedImg, mediaInfo });
            })
            .catch(err => {
              console.warn('resize error: ', err);
              reject(err);
            });
        } else {
          URL.revokeObjectURL(blobURL);
          resolve({ blobImg: imgBlob, mediaInfo });
        }
      };

      img.onerror = () => {
        URL.revokeObjectURL(blobURL);
        reject(new Error("Error loading image"));
      };
    };

    reader.onerror = () => {
      reject(new Error("Error reading file"));
    };
  });
};


// this file handles uploading and setting images
// this primarily is used to ensure any photos that should be sent to azure do, and we use their url
// use in mobile refactor?
const imageHandler = async (files, type) => {
  // old media upload (event, cover, profile photo)
  const oldSingleUpload = files instanceof File;
  if (oldSingleUpload) files = [{file: files}];
  const validTypes = ['cover', 'profile', 'event', 'post', 'comment', 'reply'];
  if (!validTypes.includes(type))
    throw new Error(
      "imageHandler expects a type of 'cover', 'profile', 'event', 'post', 'comment', or 'reply'",
    );
    const blobbedImages = [];
    const awsUrls = [];
  
    for (const file of files) {
      if (typeof file === 'string' && file.startsWith('http')) {
        awsUrls.push(file);
      } else if (file?.file) {
        try {
          const blobbedImg = await blobifyImage(file.file, type);
          blobbedImages.push(blobbedImg);
        } catch (err) {
          console.warn('blobify image error: ', err);
        }
      }
    }
  return uploadImgs(blobbedImages, 'blobImg').then((result) => {
    // the back end does return the url earlier in the process, but instead we clean the upload url and return it here from successful uploads
    if (oldSingleUpload) return result[0].value.url.split('?')[0];
    const uploadedImages = result.map(key => ({
      url: key.value.url.split('?')[0],
      kind: 'image',
    }));
    const awsUrlObjects = awsUrls.map(url => ({
      url,
      kind: 'image',
    }));

    return awsUrlObjects.concat(uploadedImages);
  });
};

export default imageHandler;
