const DEFAULT_LOCAL_OPTIONS = {
  eventDistance: '50-miles',
  eventDate: 'all',
  eventHost: 'all',
  eventGroupOption: 'my-groups',
  sortBy: 'date',
  eventCategory: 'all-activities', // activityCategory is received in displayable format
  eventFilterNavTab: 'all',
};

const DEFAULT_VIRTUAL_OPTIONS = {
  virtualSubtype: 'all',
  virtualEventDate: 'all',
  virtualEventGroupOption: 'national',
  virtualSortBy: 'date',
  virtualTime: 'all',
  virtualEventCategory: 'all-activities', // activityCategory is received in displayable format
};

// TODO, properly have the sets return actual new sets instead of the same "new set"
const DEFAULT_GROUP_MEMBER_FILTER_OPTIONS = {
  joinedTeamRWB: 'all-time',
  lastCheckIn: 'all-time',
  eventCheckInsYTD: 'any',
  memberStatus: new Set(),
  militaryBranch: new Set(),
  militaryStatus: new Set(),
  isAnchorMember: false,
  isEagleLeader: false,
}

export {DEFAULT_LOCAL_OPTIONS, DEFAULT_VIRTUAL_OPTIONS, DEFAULT_GROUP_MEMBER_FILTER_OPTIONS};
