import React from 'react';
import styles from './Attendee.module.css';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import RWBMark from '../svgs/RWBMark';
import AdminIcon from '../svgs/AdminIcon';
import {logAccessMemberProfile} from '../../../../shared/models/Analytics';
import {displayMilitaryInfo} from '../../../../shared/utils/Helpers';
import ReportAndDeleteOverlay from '../ReportAndDeleteOverlay';
import { userProfile } from '../../../../shared/models/UserProfile';
import FollowButton from '../FollowButton';
import EagleLeaderInfo from '../../../../shared/models/EagleLeaderInfo';
import CheckInControlButton from '../groups/CheckInControlButton.react';

const Attendee = ({item, onSelect, sponsorAdmin, minifiedCard, isGroupList, groupName, isGroupAdmin, privilegedIds, hideOverlay, canCheckUserIn, checkInButton}) => {

  // Something of a shim to check if the item exists or not.
  // See https://phabricator.retronyms.com/T6249
  const user_does_not_exist = (item == null) || (item.first_name == null);

  if (user_does_not_exist) {
    return (
      <div
        className={styles.container}
      >
        <div className={minifiedCard ? 'minifiedImgContainer' : styles.imgContainer}>
          <img
            src={DefaultProfileImg}
            className={minifiedCard ? 'minifiedImg' : styles.img}
            alt={'Deleted User Image'}
          />
        </div>
        <div className={styles.labelContainer}>
          <div className={styles.name}>
            <h3>
              {'Deleted Member'}
            </h3>
          </div>
        </div>
      </div>
    );
  }
  const {
    profile_photo_url,
    first_name,
    last_name,
    name,
    eagle_leader,
    preferred_chapter,
    military_branch,
    military_status,
  } = item;

  let eagleLeaderInfo = new EagleLeaderInfo(item.eagle_leader_info);
  return (
    <div
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation(); 
        onSelect(item);
        logAccessMemberProfile({ section_name: 'Member List' });
      }}
      >
      <div className={minifiedCard ? 'minifiedImgContainer' : styles.imgContainer}>
        <img
          src={!profile_photo_url ? DefaultProfileImg : profile_photo_url}
          className={minifiedCard ? 'minifiedImg' : styles.img}
          alt={'User profile'}
        />
        {sponsorAdmin && (
          <div className={minifiedCard ? 'minifiedAdminIconContainer' : styles.adminIconContainer}>
            <AdminIcon />
          </div>
        )}
      </div>
      <div className={styles.labelContainer}>
        <div className={styles.name}>
          <h3>
            {`${first_name} ${last_name}`}
          </h3>
          {eagle_leader && <RWBMark width={40} height={15} />}
        </div>
        {sponsorAdmin ? <h5>Group Leader</h5> : null}
        {eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE ? <h5>{eagleLeaderInfo.role}</h5> : null}
        {!minifiedCard && <p>{preferred_chapter?.name} </p>}
        <p>{displayMilitaryInfo(military_status, military_branch)}</p>
      </div>
      <div className={styles.buttonContainer}>
        {!hideOverlay && item?.public_profile && !canCheckUserIn && <FollowButton
          user={item}
          type={'userCard'}
        />}
        {/* CheckInControlButton.react from groups */}
        {canCheckUserIn && checkInButton}
        {/* Checks if the user is the current user, if they are we will hide the ReportAndDeleteOverlay unless they already have a eagle_leader_info so they can view their info. */}
        {!hideOverlay && (item.id !== userProfile.getUserProfile().id || eagleLeaderInfo.eagleLeaderInfo) ? <ReportAndDeleteOverlay
          type="member"
          isGroupList={isGroupList}
          eagle_leader={eagle_leader}
          groupName={groupName}
          isGroupAdmin={isGroupAdmin}
          user={item}
          posterID={item.id}
          hasPermission={privilegedIds?.has(item.id)}
        /> : (<div style={{ width: '28px' }}></div>)}
      </div>
    </div>
  );
};

export default Attendee;
