// All analytics constants, bucketed by category.
// Each value for BUCKET.events.KEY is a 2-tuple, containing [Event, Label].

export const SIGNING_UP = {
    category: 'Registration',
    events: {
        CREATE_ACCOUNT: [
            'create_account',
            'Create Account'
        ],
        VERIFY_EMAIL: [
            'verify_email',
            'Verify Email'
        ],
        PERSONAL_INFO: [
            'personal_information',
            'Personal Information'
        ],
        SOCIAL_PROFILE: [
            'social_profile',
            'Social Profile',
        ],
        MILTARY_SERVICE: [
            'military_service',
            'Military Service'
        ],
        COMPLETE_REGISTRATION: [
            'complete_registration',
            'Complete Registration'
        ],
    }
}

export const NAVIGATING_THE_APP = {
    category: 'Navigation',
    events: {
        CLICK_TO_HUB: [
            'click_to_hub',
            'Click to Hub'
        ],
        ACCESS_NOTIFICATIONS: [
            'view_notifications',
            'View Notifications',
        ],
        CLICK_INTO_GROUP: [
            'click_into_a_group',
            'Click Into a Group'
        ],
        CLICK_LINK: [
            'link_click',
            'Link Click'
        ],
        CLICK_ORIGIN_LINK: [
            'click_origin_link',
            'Click Origin Link'
        ],
        ACCESS_MEMBER_PROFILE: [
            'view_member_profile_post_or_non_post',
            'View Member Profile - Post or non Post'
        ],
        ACCESS_CHALLENGE_BADGES: [
            'view_challenge_badges',
            'View Challenge Badges'
        ],
        ACCESS_MEMBERS: [
            'view_members',
            'View Members'
        ],
    }
}

export const MANAGING_PROFILE_AND_SETTINGS = {
    category: 'Profile Management',
    events: {
        UPDATE_COVER_PHOTO: [
            'change_profile_cover_photo',
            'Change Profile Cover Photo'
        ],
        UPDATE_PROFILE_PHOTO: [
            'change_profile_photo',
            'Change Profile Photo'
        ],
        VIEW_FOLLOW_RELATIONSHIP: [
            'view_followers_or_following',
            'View Followers / Following'
        ],
        ACCESS_MY_EVENTS_AND_WORKOUT_STATS: [
            'view_my_events_and_workout_stats',
            'View My Events & Workout Stats'
        ],
        CLICK_WORKOUT: [
            'click_workout',
            'click_workout'
        ],
        ACCESS_MEMBER_PROFILE_BADGES: [
            'view_member_profile_badges',
            'View Member Profile Badges'
        ],
        CHANGE_PROFILE_SETTINGS: [
            'change_profile_settings',
            'Change Profile Settings'
        ],
        UPDATE_PROFILE: [
            'confirm_profile_changes',
            'Confirm Profile Changes'
        ],
        UPDATE_MILITARY_SERVICE: [
            'military_service',
            'Military Service'
        ],
        ACCESS_APP_SETTINGS: [
            'click_app_settings',
            'Click App Settings'
        ],
        SAVE_APP_SETTINGS: [
            'save_app_settings',
            'Save App Settings'
        ],
        UPDATE_PERSONAL_INFORMATION: [
            'personal_information',
            'Personal Information'
        ],
        UPDATE_PASSWORD: [
            'update_password',
            'Update Password'
        ],
        FORGOT_PASSWORD: [
            'forgot_password',
            'Forgot Password'
        ],
        UPDATE_PRIVACY_SETTINGS: [
            'privacy_settings',
            'Privacy Settings'
        ],
        LOGIN: [
            'login_interaction',
            'Login Interaction'
        ],
        LOGOUT: [
            'logout',
            'Logout'
        ],
        SUBMIT_REPORT: [
            'submit_report',
            'Submit Report'
        ],
        TAKE_ELS: [
            'take_els_assessment',
            'Take ELS Assessment',
        ],
        ELS_COMPLETED: [
            'els_completed',
            'ELS Completed',
        ],
        VIEW_ELS_CATEGORY: [
            'view_els_score_detail_page',
            'View ELS Score Detail Page',
        ],
        VIEW_BIO_AND_FEED_TAB: [
            'view_bio_and_feed_tab',
            'View Bio & Feed Tab'
        ],
        VIEW_FITNESS_TAB: [
            'view_fitness_tab',
            'View Fitness Tab'
        ],
        VIEW_HEALTH_TAB:[ 
            'view_health_tab',
            'View Health Tab'
        ],
    }
}

export const SEARCHING_FOR_AND_PARTICIPATING_IN_EVENTS = {
    category: 'Event Participation',
    events: {
        EVENT_SEARCH: [
            'search_for_events',
            'Search for Events'
        ],
        DETAILED_EVENT_FILTERS: [
            'detailed_event_filters',
            'Detailed Event Filters'
        ],
        ACCESS_EVENT_DETAILS: [
            'view_event_details',
            'View Event Details'
        ],
        CALENDAR: [
            'add_to_calendar',
            'Add To Calendar'
        ],
        MAP: [
            'map',
            'Map'
        ],
        ADDRESS: [
            'address',
            'Address'
        ],
        SHARE: [
            'share',
            'Share'
        ],
        CREATE_POST: [
            'event_post',
            'Event Post'
        ],
        TOGGLE_LOZENGE: [
            'filter_by_lozenge',
            'Filter by Lozenge'
        ],
        EVENT_STATUS: [
            'event_cta',
            'Event CTA'
        ],
        TOGGLE_TAB: [
            'filter_by_tab',
            'Filter by Tab'
        ],
    }
}

export const CREATING_AND_MANAGING_EVENTS = {
    category: 'Event Management',
    events: {
        CREATE_EVENT: [
            'create_an_event',
            'Create an Event'
        ],
        CANCEL_EVENT: [
            'cancel_an_event',
            'Cancel an Event'
        ],
        REMOVE_EVENT: [
            'remove_an_event',
            'Remove an Event'
        ],
        UPDATE_EVENT: [
            'edit_an_event',
            'Edit an Event'
        ],
        CHANGE_COVER_PHOTO: [
            'change_cover_photo',
            'Change Cover Photo'
        ],
    }
}

export const FEED = {
    category: 'Feed',
    events: {
        CREATE_POST: [
            'submit_a_post',
            'Submit a Post'
        ],
        EDIT_POST: [
            'submit_edited_post',
            'Submit Edited Post'
        ],
        LIKE_POST: [
            'heart_a_post',
            'Heart a Post'
        ],
        DELETE_POST: [
            'delete_post',
            'Delete Post'
        ],
        CREATE_COMMENT: [
            'submit_a_comment',
            'Submit a Comment'
        ],
        EDIT_COMMENT: [
            'submit_edited_comment',
            'Submit Edited Comment'
        ],
        LIKE_COMMENT: [
            'heart_a_comment',
            'Heart a Comment'
        ],
        DELETE_COMMENT: [
            'delete_comment',
            'Delete Comment'
        ],
        CREATE_REPLY: [
            'submit_a_reply',
            'Submit a Reply'
        ],
        EDIT_REPLY: [
            'submit_edited_reply',
            'Submit Edited Reply'
        ],
        LIKE_REPLY: [
            'heart_a_reply',
            'Heart a Reply'
        ],
        DELETE_REPLY: [
            'delete_reply',
            'Delete Reply',
        ],
        SEARCH_FOR_PEOPLE: [
            'search_for_people',
            'Search for People'
        ],
        FOLLOW_MEMBER: [
            'follow_member',
            'Follow Member'
        ],
        ACCESS_PHOTO: [
            'view_picture',
            'View picture'
        ],
        ACCESS_POST: [
            'view_post',
            'View Post'
        ],
        TAG_MEMBER: [
            'tag_a_member',
            'Tag a Member'
        ],
        VIEW_COMMENTS: [
            'view_comments',
            'View Comments'
        ],
        VIEW_LIKE_LIST: [
            'view_like_list',
            'View Like List'
        ],
        TAG_EVERYONE: [
            'tag_everyone',
            'Tag Everyone'
        ],
        INCLUDE_OPEN_GRAPH: [
            'include_open_graph',
            'Include Open Graph'
        ]
    },
}

export const GROUP = {
    category: 'Groups',
    events: {
        GROUP_SEARCH: [
            'search_for_groups',
            'Search for Groups'
        ],
        GROUP_CTA: [
            'group_cta',
            'Group CTA'
        ],
        BAN_USER: [
            'ban_user',
            'Ban User'
        ],
        REMOVE_CONTENT: [
            'remove_content',
            'Remove Content'
        ],
        PIN_POST: [
            'pin_post',
            'Pin Post'
        ],
    }
}

export const CHALLENGES = {
    category: 'Challenges',
    events: {
        CHALLENGE_CTA_FEED: [
            'challenge_cta_feed',
            'Challenge CTA - Feed'
        ],
        CHALLENGE_CTA_HUB: [
            'challenge_cta_hub',
            'Challenge CTA - Hub'
        ],
        VIEW_CHALLENGE_EVENTS: [
            'view_challenge_events',
            'View Challenge Events'
        ],
        DELETE_WORKOUT: [
            'delete_workout_log',
            'Delete Workout Log'
        ],
        RECORD_WORKOUT: [
            'record_a_workout',
            'Record a Workout'
        ],
        SHARE_WORKOUT: [
            'share_workout_log',
            'Share Workout Log'
        ],
        ACCESS_LEADERBOARD: [
            'view_leaderboard',
            'View Leaderboard'
        ],
        ACCESS_CHALLENGE_DETAILS: [
            'view_challenge_details',
            'View Challenge Details'
        ],
    }
}

export const ACTION_CATEGORIES = {
    administrative: 'administrative',
    social: 'social',
    registration: 'registration',
    app: 'app',
    event: 'event',
    filter: 'filter',
}
