import React from 'react';
import styles from './Attendee.module.css';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import RWBMark from '../svgs/RWBMark';
import ReportAndDeleteOverlay from '../ReportAndDeleteOverlay';
import { userProfile } from '../../../../shared/models/UserProfile';
import FollowButton from '../FollowButton';

/*
  This component is how an event host is displayed on both the event view and the event list. This will display the user's name, eagle leader badge (if applicable), eagle leader title (if applicable) and user's preferred chapter name
  @param {string} item - host object containing user information such as photo url, first and last name, and eagle leader information
  @param {function} onSelect - action done when pressing on the container, in this case it should navigate to the user's profile
  @param {(int|undefined)} paddingLeft - amount of padding to the left. Only use on EventDetail to avoid having the event host be too far to the right
*/

const EventHost = ({item, onSelect, paddingLeft, paddingRight}) => {
  const {id, photo_url, first_name, last_name, eagle_leader, eagle_leader_title} = item;

  return (
    <div
      className={`${styles.container}`}
      style={{paddingLeft, paddingRight}}
      onClick={onSelect}>
      <div className={styles.imgContainer} style={{height: 90, width: 90}}>
        <img
          src={!photo_url ? DefaultProfileImg : photo_url}
          className={styles.img}
          style={{height: 90, width: 90}}
          alt={'User profile'}
        />
      </div>
      <div className={styles.labelContainer}>
        <div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h3 className={styles.eventHost}>Event Host</h3>
            <div>
              <h3>{`${first_name} ${last_name}`}</h3>
              {eagle_leader && <RWBMark width={40} height={15} />}
            </div>
          </div>
        </div>
        {eagle_leader ? (
          <>
            <h5>{eagle_leader_title || 'Eagle Leader'}</h5>
          </>
        ) : null}
      </div>
      <div className={styles.buttonContainer}>
        {item?.public_profile && <FollowButton
            user={item}
            type={'userCard'}
          />}
        {id !== userProfile.getUserProfile().id && <ReportAndDeleteOverlay
            type="member"
            isGroupList={false}
            user={item}
            posterID={id}
            hasPermission={false}
          />}
      </div>
    </div>
  );
};

export default EventHost;
