import { rwbApi } from "../apis/api";
import { EL_ROLES } from "../constants/EagleLeaderManagementStrings";
import { logBanUser, logPinPost, logRemoveContent, EXECUTION_STATUS, } from "../models/Analytics";

export class GroupManagement {
    static removeContentGroupAndReport = (feedId, group_id, analyticsObj) => {
        analyticsObj.click_text = 'Remove & Report Post';
        return rwbApi
            .removeGroupPost(group_id, feedId)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                throw Error(err.message);
            })
            .finally(() => {
                logRemoveContent(analyticsObj);
            });
    };
    
    static removeUserGroupAndReport = (userId, group_id, analyticsObj) => {
        analyticsObj.click_text = 'Remove & Report User';
        analyticsObj.profile_id = `${userId}`;
        return rwbApi
            .banUser(group_id, userId)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                if (err.message = 'Group membership does not exist.') {
                    analyticsObj.execution_status = EXECUTION_STATUS.success;
                    throw Error(err.message);
                }
                else {
                    analyticsObj.execution_status = EXECUTION_STATUS.failure;
                    throw Error(err.message);
                }
            })
            .finally(() => {
                logBanUser(analyticsObj);
            });
    };
    
    static removeGroupPinnedPost = (feedID, group_id, analyticsObj) => {
        analyticsObj.click_text = 'Remove Pin';
        return rwbApi
            .unpinPost(group_id, feedID)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                throw Error(err.message);
            })
            .finally(() => {
                logPinPost(analyticsObj);
            });
    };
    
    static pinGroupPost = (feedID, group_id, analyticsObj) => {
        analyticsObj.click_text = 'Pin Post';
        return rwbApi
            .pinPost(group_id, feedID)
            .then((result) => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                throw Error(err.message);
            })
            .finally(() => {
                logPinPost(analyticsObj);
            });
    };

    // pulled from eagle_leader_info.role
    static isEditableRole = (role) => {
        if (!role) return false;
        const editableRoles = EL_ROLES.map((role) => role.toLowerCase());
        return editableRoles.includes(role?.toLowerCase());
    }
}

