import React from 'react';
import styles from './CommentView.module.css';
import ChevronBackIcon from '../svgs/ChevronBackIcon';
import {withRouter} from 'react-router-dom';
import Attendee from '../events/Attendee';
import Loading from '../Loading';
import {rwbApi} from '../../../../shared/apis/api';
import {hasReachedBottom} from '../../BrowserUtil';

const TYPES = {
  POST: 'post',
  COMMENT: 'comment',
};

const apiConfig = {
  like: {
    [TYPES.POST]: {
      fetchUser: (userId, postId, commentId, nextURL) =>
        rwbApi.getLikeUserList(userId, postId, {kind: 'like'}, nextURL),
    },
    [TYPES.COMMENT]: {
      fetchUser: (userId, postId, commentId, nextURL) =>
        rwbApi.getLikeUserList(
          userId,
          postId,
          {kind: 'like', comment_id: commentId},
          nextURL,
        ),
    },
  },
  // TODO: Comment reply or other reaction will go here
};

const LikeView = (props) => {
  const {postId, commentId, history, feed} = props;
  const [loading, setLoading] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [nextURL, setNextURL] = React.useState('');
  const [loadingMore, setLoadingMore] = React.useState(true);

  const type = postId && commentId ? TYPES.COMMENT : TYPES.POST;

  const fetchUserList = () => {
    if (!nextURL) setLoading(true);
    else setLoadingMore(true);
    apiConfig.like[type]
      .fetchUser(feed.user.id, postId, commentId, nextURL)
      .then((result) => {
        setNextURL(result.data.next_page);
        const users = result.data.reactions.map((reaction) => reaction.user);
        setUserList([...userList, ...users]);
      })
      .catch(() => {
        alert(`Error fetching ${type} likes user list`);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  const onSelect = ({id}) => history.push(`/profile/${id}`);

  React.useEffect(() => {
    fetchUserList();
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', trackScrolling);
    return () => {
      window.removeEventListener('scroll', trackScrolling);
    };
  }, [fetchUserList]);

  const trackScrolling = (event) => {
    event.preventDefault();
    const wrappedElement = document.getElementById('root');
    if (hasReachedBottom(wrappedElement) && nextURL && !loadingMore) {
      fetchUserList();
    }
  };

  return (
    <>
      {loading ? (
        <Loading
          size={100}
          color={'var(--white)'}
          loading={loading}
          right
          transparent={false}
        />
      ) : (
        <div className={styles.container}>
          <div className={styles.likeHeaderContainer}>
            <div onClick={() => history.goBack()} className={styles.backIcon}>
              <ChevronBackIcon tintColor={'var(--white)'} />
            </div>
            <div className={styles.likeHeader}>
              <div className={styles.likeHeaderTitle}>Likes</div>
            </div>
          </div>
          <div className={styles.reactionContainer}>
            {userList.map((user, idx) => (
              <Attendee
                key={user?.id ?? idx}
                item={user}
                onSelect={() => onSelect(user)}
              />
            ))}
          </div>
          {loadingMore && (
            <Loading
              size={60}
              color={'var(--grey20)'}
              loading={true}
              footerLoading
            />
          )}
        </div>
      )}
    </>
  );
};

const reactionComponent = {
  like: LikeView,
};

const CommentReactionView = (props) => {
  const {
    history,
    match: {
      params: {postId, commentId, reactionType},
    },
    location: {state},
  } = props;
  const View = reactionComponent[reactionType];
  if (!View) {
    alert('Page not found');
    history.goBack();
  }
  return (
    <View
      postId={postId}
      commentId={commentId}
      history={history}
      feed={state.feed}
    />
  );
};

export default withRouter(CommentReactionView);
