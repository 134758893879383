import React from 'react';
import styles from './CustomPicker.module.css';

/**
 * Custom Picker - an accordian-like component on web that can open up and allow users to select one option from a list
 * and close it to conserve screen space.
 * @component
 * @param {string} [title] - header above the picker when opened and closed
 * @param {boolean} isOpen - if open all the options are shown, otherwise just the selected option is displayed
 * @param {Object} items - an object containing all the options to show with display and slug keys
 * @param {string} selectedValue - the active value shown in magenta
 * @param {function} [pickHandler] - function that changes the state of `isOpen`
 * @param {boolean} [hideOpener] - determines if the title and value text should be hidden. This should be true when the picker is always open
 * @param {function} onSelect - update the selectedValue in parent on press
 */

const CustomPicker = ({
  title,
  isOpen,
  items,
  selectedValue,
  pickHandler,
  onSelect,
  hideOpener
}) => (
  <>
    {!hideOpener &&
    <div className={styles.labelContainer} onClick={pickHandler}>
      <p className={`formLabel ${styles.label}`}>{title}</p>
      <p className="bodyCopyForm">{items[selectedValue]?.display}</p>
    </div>}
    <div className={isOpen ? styles.contentContainer : styles.invisible}>
      {isOpen &&
        Object.keys(items).map((item, i) => (
          <h2
            key={i}
            className={[
              styles.h2,
              items[item].slug === selectedValue && styles.selected,
            ].join(' ')}
            onClick={() => onSelect(items[item].slug)}>
            {items[item]?.display}
          </h2>
        ))}
    </div>
  </>
);

export default CustomPicker;
