/**
 * See https://docs.google.com/spreadsheets/d/1c9bjilCXWcjyC-nTee8jJOB01BzMn_idSMRxv1V3c94/edit#gid=1787567816
 * for latest business requirements.
 *
 * Last revised: 2020-08-18
 */

import firebase from '../utils/firebase';
import {userProfile} from './UserProfile';
import {
  SIGNING_UP as SI,
  NAVIGATING_THE_APP as NA,
  MANAGING_PROFILE_AND_SETTINGS as M,
  SEARCHING_FOR_AND_PARTICIPATING_IN_EVENTS as SE,
  CREATING_AND_MANAGING_EVENTS as C,
  FEED as F,
  CHALLENGES as CH,
  ACTION_CATEGORIES,
  GROUP as G,
} from '../constants/newAnalytics';
import {version as currentVersion} from '../../package.json';
import { formatTitle } from '../utils/ELSHelper';

const formatParameters = (section_name, parameters) => {
  parameters.section_name = section_name;
  return parameters;
};

function logEvent(category, event, parameter = {}) {
  const Analytics = firebase.analytics();
  const userProf = userProfile.getUserProfile();
  if (userProf.id) {
    Analytics.setUserId(userProf.id.toString());
  }
  parameter.app_version = currentVersion;
  const meta = Object.assign(
    {
      category: category,
      label: event[1],
    },
    parameter,
  );
  // check all parameters are there
  // console.log(meta);
  Analytics.logEvent(event[0], meta);
}

// signing up

export const logCreateAccount = (params) => {
  const analyticsParams = {
    current_view: 'Signup',
    action_category: 'registration',
    action_component: 'cta',
    click_text: 'CREATE ACCOUNT',
    step_for_completion: '1',
  };
  logEvent(
    SI.category,
    SI.events.CREATE_ACCOUNT,
    formatParameters(SI.category, {...analyticsParams, ...params}),
  );
};

export const logVerifyEmail = (params) => {
  const analyticsParams = {
    current_view: 'Verify Your Email',
    action_category: 'registration',
    action_component: 'cta',
    click_text: 'NEXT',
    step_for_completion: '2',
  };
  logEvent(
    SI.category,
    SI.events.VERIFY_EMAIL,
    formatParameters(SI.category, {...analyticsParams, ...params}),
  );
};

export const logPersonalInfo = (params) => {
  const analyticsParams = {
    current_view: 'Personal Information',
    action_category: 'registration',
    action_component: 'cta',
    click_text: 'NEXT',
    step_for_completion: '3',
  };
  logEvent(
    SI.category,
    SI.events.PERSONAL_INFO,
    formatParameters(SI.category, {...analyticsParams, ...params}),
  );
};

export const logSocialProfile = (params) => {
  const analyticsParams = {
    current_view: 'Social Information',
    action_category: 'registration',
    action_component: 'cta',
    click_text: 'NEXT',
    step_for_completion: '4',
  };
  logEvent(
    SI.category,
    SI.events.SOCIAL_PROFILE,
    formatParameters(SI.category, {...analyticsParams, ...params}),
  );
};

export const logMilitaryService = (params) => {
  const analyticsParams = {
    current_view: 'Military Service',
    action_category: 'registration',
    action_component: 'cta',
    click_text: 'NEXT',
    step_for_completion: '5',
  };
  logEvent(
    SI.category,
    SI.events.MILTARY_SERVICE,
    formatParameters(SI.category, {...analyticsParams, ...params}),
  );
};

// privacy waiver
export const logCompleteRegistration = (params) => {
  const analyticsParams = {
    current_view: 'Privacy/Waiver',
    action_category: 'registration',
    action_component: 'cta',
    click_text: 'COMPLETE REGISTRATION',
    step_for_completion: '6',
  };
  logEvent(
    SI.category,
    SI.events.COMPLETE_REGISTRATION,
    formatParameters(SI.category, {...analyticsParams, ...params}),
  );
};

export const logPayForShirt = (params) => {
  const analyticsParams = {
    current_view: 'Get your red shirt!',
    action_category: 'registration',
    action_component: 'cta',
    click_text: 'PAY $5.00',
    step_for_completion: '8',
  };
  logEvent(
    SI.category,
    SI.events.PAY_FOR_SHIRT,
    formatParameters(SI.category, {...analyticsParams, ...params}),
  );
};

export const logOrderConfirmation = (params) => {
  const analyticsParams = {
    current_view: 'Order Confirmation',
    action_category: 'registration',
    action_component: 'cta',
    click_text: 'CONTINUE',
    step_for_completion: '9',
  };
  logEvent(
    SI.category,
    SI.events.ORDER_CONFIRMATION,
    formatParameters(SI.category, {...analyticsParams, ...params}),
  );
};

// Navigation the app
export const logClickToHub = (section_name) => {
  const analyticsParams = {
    action_category: 'navigation',
    action_component: 'navigate',
    section_name
  };
  logEvent(NA.category, NA.events.CLICK_TO_HUB, analyticsParams);
}

export const logJoinGroup = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'cta',
    click_text: 'Join',
  };
  logEvent(
    G.category,
    G.events.GROUP_CTA,
    formatParameters(G.category, {...analyticsParams, ...params}),
  );
};

export const logLeaveGroup = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'cta',
    click_text: 'Joined',
  };
  logEvent(
    G.category,
    G.events.GROUP_CTA,
    formatParameters(G.category, {...analyticsParams, ...params}),
  );
};

export const logClickIntoGroup = (params) => {
  const analyticsParams = {
    action_category: 'navigation',
    action_component: 'link_group',
  };
  logEvent(
    NA.category,
    NA.events.CLICK_INTO_GROUP,
    formatParameters(M.category, {...analyticsParams, ...params}),
  );
};

export const logBanUser = (params) => {
  const analyticsParams = {
    action_category: ACTION_CATEGORIES.administrative,
    action_component: 'menu',
  };
  logEvent(
    G.category,
    G.events.BAN_USER,
    {...analyticsParams, ...params},
  );
};

export const logRemoveContent = (params) => {
  const analyticsParams = {
    action_category: ACTION_CATEGORIES.administrative,
    action_component: 'menu',
  };
  logEvent(
    G.category,
    G.events.REMOVE_CONTENT,
    {...analyticsParams, ...params},
  );
};

export const logPinPost = (params) => {
  const analyticsParams = {
    action_category: ACTION_CATEGORIES.administrative,
    action_component: 'menu',
  };
  logEvent(
    G.category,
    G.events.PIN_POST,
    {...analyticsParams, ...params},
  );
};

export const logClickOriginLink = (params) => {
  const analyticParams = {
    action_category: 'social',
    action_component: 'link',
  };
  logEvent(
    NA.category,
    NA.events.CLICK_ORIGIN_LINK,
    {...analyticParams, ...params},
  );
};

export const logLinkClick = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.event,
    action_component: 'link_event',
  };
  logEvent(
    NA.category,
    NA.events.CLICK_LINK,
    {...analyticParams, ...params},
  );
};

export const logAccessMemberProfile = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'profile_picture',
  };
  logEvent(NA.category, NA.events.ACCESS_MEMBER_PROFILE, {
    ...analyticParams,
    ...params,
  });
};

export const logAccessChallengeBadges = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'link',
    current_view: 'member profile',
  };
  logEvent(NA.category, NA.events.ACCESS_CHALLENGE_BADGES, {
    ...analyticsParams,
    ...params,
  });
};

export const logAccessMembers = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'members',
  };
  logEvent(NA.category, NA.events.ACCESS_MEMBERS, {
    ...analyticsParams,
    ...params,
  });
};

// Managing Profile & Settings

export const logProfileCoverPhoto = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'icon',
  };
  logEvent(
    M.category,
    M.events.UPDATE_COVER_PHOTO,
    formatParameters(M.category, {...analyticsParams, ...params}),
  );
};

export const logProfilePhoto = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'icon',
  };
  logEvent(
    M.category,
    M.events.UPDATE_PROFILE_PHOTO,
    formatParameters(M.category, {...analyticsParams, ...params}),
  );
};

export const logViewFollowRelationship = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'members',
  };
  logEvent(
    M.category,
    M.events.VIEW_FOLLOW_RELATIONSHIP,
    {...analyticsParams, ...params},
  );
}

export const logAccessMyEventsAndWorkoutStats = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'link',
    current_view: 'member profile',
    section_name: 'My Profile',
  };
  logEvent(
    M.category,
    M.events.ACCESS_MY_EVENTS_AND_WORKOUT_STATS,
    {...analyticsParams, ...params},
  );
};

export const logClickWorkout = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'link',
    current_view: 'member profile',
    section_name: 'My Profile',
  };
  logEvent(
    M.category,
    M.events.CLICK_WORKOUT,
    {...params, ...analyticsParams},
  );
};

export const logAccessMemberProfileBadges = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'icon',
  };
  logEvent(
    M.category,
    M.events.ACCESS_MEMBER_PROFILE_BADGES,
    formatParameters(M.category, {...analyticsParams, ...params}),
  );
};

export const logChangeProfileSettings = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'cta',
    click_text: 'edit',
    section_name: 'My Profile'
  };
  logEvent(
    M.category,
    M.events.CHANGE_PROFILE_SETTINGS,
    analyticParams,
  )
}

export const logUpdateProfile = (params) => {
  const analyticsParams = {
    action_category: 'profile',
    click_text: 'Save',
  };
  logEvent(
    M.category,
    M.events.UPDATE_PROFILE,
    formatParameters(M.category, {...analyticsParams, ...params}),
  );
};

export const logAccessAppSettings = (params) => {
  const analyticsParams = {
    previous_view: 'member profile',
    current_view: 'app settings',
    action_category: 'app',
    action_component: 'icon',
  };
  logEvent(
    M.category,
    M.events.ACCESS_APP_SETTINGS,
    formatParameters(M.category, {...analyticsParams, ...params}),
  );
};

export const logSaveAppSettings = (params) => {
  let analyticParams = {
    previous_view: 'app settings',
    action_category: ACTION_CATEGORIES.app,
    action_component: 'menu',
    click_text: 'save',
  };
  logEvent(
    M.category,
    M.events.SAVE_APP_SETTINGS,
    formatParameters(M.category, {...analyticParams, ...params}),
  );
};

export const logLogin = (params) => {
  // description text specifies after success, ignoring execution_status for now
  logEvent(
    M.category,
    M.events.LOGIN,
    formatParameters(M.category, {action_category: ACTION_CATEGORIES.social}),
  );
};

export const logReport = (params) => {
  logEvent(M.category, M.events.SUBMIT_REPORT, params);
};

export const logSelectProfileTab = (clickText, tab) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'members',
    click_text: clickText,
  }
  logEvent(
    M.category,
    tab,
    formatParameters(M.category, analyticParams)
  );

}

// ELS (part of profile)
export const logTakeELS = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'members'
  }
  logEvent(
    M.category,
    M.events.TAKE_ELS,
    formatParameters(M.category, {...analyticParams, ...params})
  );
}

export const logELSCompleted = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'members'
  }
  logEvent(
    M.category,
    M.events.ELS_COMPLETED,
    formatParameters(M.category, {...analyticParams, ...params}),
  );
};

export const logViewELSCategory = (title) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create',
    click_text: formatTitle(title),
  }
  logEvent(
    M.category,
    M.events.VIEW_ELS_CATEGORY,
    formatParameters(M.category, {...analyticParams})
  );
}

// Search For & Participate in Events

// search for events
export const logEventsSearch = (params) => {
  const analytics_params = {
    previous_view: 'hub',
    current_view: 'event filters',
    action_category: ACTION_CATEGORIES.filter,
    action_component: 'cta',
  };
  logEvent(
    SE.category,
    SE.events.EVENT_SEARCH,
    formatParameters(SE.category, {...analytics_params, ...params}),
  );
};

export const logGroupsSearch = (params) => {
  const analytics_params = {
    action_category: 'filter',
    action_component: 'open_text_field',
  };
  logEvent(
    G.category,
    G.events.GROUP_SEARCH,
    formatParameters(G.category, {...analytics_params, ...params}),
  );
};

export const logAccessEventDetails = (params) => {
  const analytics_params = {
    action_category: 'event',
    action_component: 'link_event',
    current_view: 'events_hub',
  };
  logEvent(
    SE.category,
    SE.events.ACCESS_EVENT_DETAILS,
    formatParameters(SE.category, {...analytics_params, ...params}),
  );
};

export const logEventStatus = (params) => {
  const analytics_params = {
    action_category: 'event',
    action_component: 'cta',
  };
  logEvent(
    SE.category,
    SE.events.EVENT_STATUS,
    formatParameters(SE.category, {...analytics_params, ...params}),
  );
};

export const logCalendar = (params) => {
  const analytics_params = {
    action_category: 'event',
    action_component: 'icon',
  };
  logEvent(
    SE.category,
    SE.events.CALENDAR,
    formatParameters(SE.category, {...analytics_params, ...params}),
  );
};

export const logShare = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
  };
  logEvent(SE.category, SE.events.SHARE, {...analyticParams, ...params});
};

export const logToggleLozenge = (params) => {
  const analytics_params = {
    action_category: 'event',
  };
  logEvent(SE.category, SE.events.TOGGLE_LOZENGE, {
    ...analytics_params,
    ...params,
  });
};

export const logToggleTab = (params) => {
  const analytics_params = {
    action_category: 'event',
  };
  logEvent(SE.category, SE.events.TOGGLE_TAB, {...analytics_params, ...params});
};

// Creating and Managing Events
export const logCreateEvent = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'create',
  };
  logEvent(
    C.category,
    C.events.CREATE_EVENT,
    formatParameters(C.category, {...analyticsParams, ...params}),
  );
};

export const logCancelEvent = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'create',
  };
  logEvent(C.category, C.events.CANCEL_EVENT, {...analyticsParams, ...params});
};

export const logRemoveEvent = (params) => {
  const analyticsParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create',
  };
  logEvent(
    C.category,
    C.events.REMOVE_EVENT,
    formatParameters(C.category, {...analyticsParams, ...params}),
  );
};

export const logUpdateEvent = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'create',
  };
  logEvent(
    C.category,
    C.events.UPDATE_EVENT,
    {...analyticsParams, ...params},
  );
};

export const logEventCoverPhoto = (params) => {
  const analyticsParams = {
    action_category: ACTION_CATEGORIES.administrative,
    action_component: 'cta',
  };
  logEvent(
    C.category,
    C.events.CHANGE_COVER_PHOTO,
    formatParameters(C.category, {...analyticsParams, ...params}),
  );
};

// Notifications

export const logAccessNotifications = (unread_notifications) => {
  const analyticsParams = {
    action_category: 'notifications',
    action_component: 'icon',
    current_view: 'feed',
    notifications: unread_notifications,
    section_name: 'Feed',
  };
  logEvent(
    NA.category,
    NA.events.ACCESS_NOTIFICATIONS,
    formatParameters(NA.category, analyticsParams),
  );
};

// Feed

export const logFeedCreatePost = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create',
  };
  logEvent(F.category, F.events.CREATE_POST, {...analyticParams, ...params});
};

export const logEditPost = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create',
  };
  logEvent(F.category, F.events.EDIT_POST, {...analyticParams, ...params});
};

export const logLikePost = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'icon',
  };
  logEvent(F.category, F.events.LIKE_POST, {...analyticParams, ...params});
};

export const logDeletePost = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'menu',
  };
  logEvent(F.category, F.events.DELETE_POST, {...analyticParams, ...params});
};

export const logViewComments = (params) => {
  let analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'comments',
  };
  logEvent(F.category, F.events.VIEW_COMMENTS, {...analyticParams, ...params});
};

export const logFeedCreateComment = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create',
  };
  logEvent(F.category, F.events.CREATE_COMMENT, {...analyticParams, ...params});
};

export const logEditComment = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create',
  };
  logEvent(F.category, F.events.EDIT_COMMENT, {...analyticParams, ...params});
};

export const logDeleteComment = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'menu',
  };
  logEvent(
    F.category,
    F.events.DELETE_COMMENT,
    formatParameters(F.category, {...analyticParams, ...params}),
  );
};

export const logLikeComment = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create',
  };
  logEvent(F.category, F.events.LIKE_COMMENT, {...analyticParams, ...params});
};

export const logCreateReply = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'icon',
  };
  logEvent(F.category, F.events.CREATE_REPLY, {...analyticParams, ...params});
}

export const logEditReply = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create'
  };
  logEvent(F.category, F.events.EDIT_REPLY, {...analyticParams, ...params});
}

export const logLikeReply = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'icon'
  };
  logEvent(F.category, F.events.LIKE_REPLY, {...analyticParams, ...params});
}

export const logDeleteReply = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'menu'
  };
  logEvent(F.category, F.events.DELETE_REPLY, {...analyticParams, ...params});
}

export const logTagUser = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'tag',
  };
  logEvent(F.category, F.events.TAG_MEMBER, {...analyticParams, ...params});
};

export const logSearchForPeople = (params) => {
  const analytics_params = {
    action_category: 'filter',
    action_component: 'open_text_field',
  };
  logEvent(
    F.category,
    F.events.SEARCH_FOR_PEOPLE,
    formatParameters(F.category, {...analytics_params, ...params}),
  );
};

export const logFollow = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'cta',
  };
  logEvent(F.category, F.events.FOLLOW_MEMBER, {...analyticsParams, ...params});
};

export const logUnfollow = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'cta',
  };
  logEvent(F.category, F.events.FOLLOW_MEMBER, {...analyticsParams, ...params});
};

export const logAccessPostPhoto = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'picture',
  };
  logEvent(
    F.category,
    F.events.ACCESS_PHOTO,
    formatParameters(F.category, {...analyticsParams, ...params}),
  );
};

export const logAccessPost = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'post description',
  };
  logEvent(
    F.category,
    F.events.ACCESS_POST,
    formatParameters(F.category, {...analyticsParams, ...params}),
  );
};

// challenges analytics

export const logJoinChallenge = (params, joinFrom) => {
  // current view will come from the passed params and can be feed or hub
  const analyticsParams = {
    action_category: 'social',
    action_component: 'cta',
    click_text: 'Join Challenge',
  };
  logEvent(
    CH.category,
    joinFrom,
    formatParameters(CH.category, {...analyticsParams, ...params}),
  );
};

export const logLeaveChallenge = (params) => {
  const analyticsParams = {
    current_view: 'feed',
    action_category: 'social',
    action_component: 'cta',
    click_text: 'Leave Challenge',
  };
  logEvent(
    CH.category,
    CH.events.CHALLENGE_CTA_FEED,
    formatParameters(CH.category, {...analyticsParams, ...params}),
  );
};

export const logViewChallengeEvents = (params) => {
  const analyticsParams = {
    action_category: 'event',
    action_component: 'cta',
  };
  logEvent(
    CH.category,
    CH.events.VIEW_CHALLENGE_EVENTS,
    formatParameters(CH.category, {...analyticsParams, ...params}),
  );
};

export const logShareWorkout = (params) => {
  const analyticParams = {
    action_category: 'social',
    action_component: 'menu',
  };
  logEvent(CH.category, CH.events.SHARE_WORKOUT, {
    ...analyticParams,
    ...params,
  });
};

export const logDeleteWorkout = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'menu',
  };
  logEvent(CH.category, CH.events.DELETE_WORKOUT, {
    ...analyticsParams,
    ...params,
  });
};

export const logRecordWorkout = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'create',
    click_text: 'submit',
  };
  logEvent(
    CH.category,
    CH.events.RECORD_WORKOUT,
    formatParameters(CH.category, {...analyticsParams, ...params}),
  );
};

export const logAccessLeaderboard = (params) => {
  const analyticsParams = {
    action_category: 'social',
    action_component: 'text',
  };
  logEvent(
    CH.category,
    CH.events.ACCESS_LEADERBOARD,
    formatParameters(CH.category, {...analyticsParams, ...params}),
  );
};

export const logAccessChallengeDetails = (params) => {
  const analyticsParams = {
    action_category: 'challenges',
    action_component: 'link_challenge',
  };
  logEvent(
    CH.category,
    CH.events.ACCESS_CHALLENGE_DETAILS,
    formatParameters(CH.category, {...analyticsParams, ...params}),
  );
};

export const logDetailedEventFilters = (params) => {
  const analyticsParams = {
    current_view: 'event filters',
    previous_view: 'events hub',
    action_category: 'event',
    action_component: 'cta',
    click_text: 'apply',
  };
  logEvent(
    SE.category,
    SE.events.DETAILED_EVENT_FILTERS,
    formatParameters(SE.category, {...analyticsParams, ...params}),
  );
};

export const logViewLikeList = (params) => {
  const analyticParams = {
    action_component: 'link',
  };
  logEvent(
    F.category,
    F.events.VIEW_LIKE_LIST,
    formatParameters(F.category, {...analyticParams, ...params}),
  );
}

export const logTagEveryone = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create'
  }
  logEvent(
    F.category,
    F.events.TAG_EVERYONE,
    formatParameters(F.category, {...analyticParams, ...params})
  );
}

export const logIncludeOpenGraph = (params) => {
  const analyticParams = {
    action_category: ACTION_CATEGORIES.social,
    action_component: 'create'
  }
  logEvent(
    F.category,
    F.events.INCLUDE_OPEN_GRAPH,
    formatParameters(F.category, {...analyticParams, ...params})
  );
}

export const EXECUTION_STATUS = {
  success: 'success',
  failure: 'failure',
};

export const FOLLOW_LOCATION = {
  member_profile: 'member_profile',
  followers: 'followers',
  following: 'following',
  notifications_list: 'notifications_list',
};

export const webSectionName = () => {
  const url = window.location.href;
  let section;
  if (url.includes('groups')) section = 'Groups';
  else if (url.includes('challenges')) section = 'Challenges';
  else if (url.includes('events')) section = 'Events';
  else if (url.includes('profile')) section = 'Profile'; // web not setup to distinguish between profile and my profile
  else section = 'Feed';
  return section;
};

export const extractFeedOriginInfo = (foreign_id, feed_object) => {
  let type = foreign_id?.split(':')[0];
  let name;
  // the object is only sometimes what we want
  // if there is a _ we want to take that to ensure we are correct (event_post, challenge_post, etc)
  // if there is no we should be safe to use the object (attend is from id, event is from object)
  // with these two fields we are avoiding using the "origin" field
  if (type?.includes('_')) name = type?.split('_')[0];
  else if (feed_object) name = feed_object?.split(':')[0]
  else name = type;
  return {type, name};
}
