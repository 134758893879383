import React, {useState} from 'react';

/**
 * The toggleable component that displays a circle with the first character of the day
 * There can be multiple of these selected at once
 * @component
 * @param {string} day - Day of the week that's first character will be sliced off for display
 * @param {function} onPress - the onPress that will indicate the parent the component has been clicked
 * @param {boolean} selected - condition when pressed which changes the styling
 * 
 */

const DayCircle = ({day, onPress, selected}) => {
    return (
        <div onClick={onPress}
            style={{width: 40, height: 40, borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center',
                backgroundColor: selected ? 'var(--magenta)' : 'var(--grey40)', cursor: 'pointer'
            }}
        >
            <p style={{color: selected ? 'var(--white)' : 'var(--grey80)', fontWeight: 'bold'}}>{day.slice(0,1)}</p>
        </div>
    )
}

/**
    TODO: Lift selected days state up, the parent should control so it has access befor sending to server, this component may just be part of the parent
    The component that lists all the days and passes the selected days between the circles to the parent. This is used for week recurence.
    @component
    @param {array} days - array of day objects
    @param {function} onDaysSelected - the parent function called when pressing a DayCircle
*/


const DaySelectables = ({days, onDaySelected, selectedOption}) => {
    return (
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            {days.map((day) => {
                return (
                    <DayCircle key={day.display} day={day.display} selected={selectedOption.has(day.slug)}
                    onPress={() => onDaySelected(day.slug)} />
                )
            })}
        </div>
    )
}

export default DaySelectables;
