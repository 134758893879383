import React from 'react';
import {handleLinkClick} from '../../../../shared/utils/HandleLinkClick';
import styles from './HyperLink.module.css';

const HyperLink = ({link}) => {
  const openLink = (evt, url) => {
    evt.stopPropagation();
    evt.preventDefault();
    handleLinkClick(url);
  };

  return (
    <a
      key={link}
      onClick={(e) => openLink(e, link)}
      className={styles.postLink}>
      {link}
    </a>
  );
};

export default HyperLink;
